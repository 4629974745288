import React from 'react';

import './styles.css';
import { Images } from '../../config/images';

const Answer = ({ text, image, onPress, selected }) => (
    <div className="AnswerContainer">
        {image ? (
          <img
            resizeMode="cover"
            className="AnswerImage"
            src={image}
            alt="cover"
          />
        ) : false}

        <div onClick={onPress} className={`AnswerAroundText ${selected ? 'AnswerSelected' :''}`}>
          {selected ? (
            <img
              className="AnswerIcon"
              src={Images.icon.checkCircleGreen}
              alt="checkGreen"
            />
              ) : (
            <img
              className="AnswerIcon"
              src={Images.icon.circleGrey}
              alt="checkGrey"
            />
          )}
            <p className={`AnswerText ${selected ? 'AnswerSelectedText' : ''}`}>{text}</p>
          </div>
    </div>
);

export default Answer;
