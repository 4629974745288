import { Colors } from '../../../src/config/colors';

const styles = {
  closed: {
    color: Colors.status.closed,
  },
  newMessage: {
    color: Colors.status.newMessage,
  },
  active: {
    color: Colors.status.active,
  }
}

export default styles;
