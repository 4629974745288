import {
  FILTER_FETCH,
  FILTER_FETCH_SUCCESS,
  FILTER_FETCH_FAIL,
  FILTER_CHANGE_AGE,
  FILTER_CHANGE_GENDER,
  FILTER_CHANGE_DISTANCE,
  FILTER_UPDATE,
  FILTER_UPDATE_SUCCESS,
  FILTER_UPDATE_FAIL,
} from './types';
import { Api } from '../config/api';
import { get, post } from '../helpers/api';

export const fetchFilter = (token) => {
  return dispatch => {
      dispatch({
          type: FILTER_FETCH,
          payload: 'Fetching filter',
      });

      get(token, Api.requests.filter).then(response => {
          dispatch({
              type: FILTER_FETCH_SUCCESS,
              payload: response.data,
          });
      }).catch((e) => {
          console.log(e);
          dispatch({ type: FILTER_FETCH_FAIL });
      });
  };
};

export const updateFilter = (token, data) => {
  return dispatch => {
      dispatch({
          type: FILTER_UPDATE,
          payload: 'Updating filter',
      });

      post(token, Api.requests.filter, data).then(response => {
          dispatch({
              type: FILTER_UPDATE_SUCCESS,
              payload: response.data,
          });
      }).catch((e) => {
          console.log(e);
          dispatch({
              type: FILTER_UPDATE_FAIL,
              payload: 'Something went wrong.'
          });
      });
  };
};

export const ageChanged = (minAge, maxAge) => {
  return {
      type: FILTER_CHANGE_AGE,
      payload: {
          minAge,
          maxAge
      }
  };
};

export const genderChanged = gender => {
  return {
      type: FILTER_CHANGE_GENDER,
      payload: gender
  };
};

export const distanceChanged = distance => {
  return {
      type: FILTER_CHANGE_DISTANCE,
      payload: distance
  };
};
