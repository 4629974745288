import React from 'react';

import './styles.css';
import { Images } from '../../config/images';

const ButtonPrimary = ({ label, onPress, arrowRight, large }) => (
    <button onClick={onPress} className="ButtonPrimaryButton">
        <div className={`ButtonPrimaryContainer ${large ? 'ButtonPrimaryLargeContainer': ''}`}>
            <div className="ButtonPrimaryLabelContainer">
                <p className={`ButtonPrimaryLabel ${large ? 'ButtonPrimaryLargeLabel' : ''}`}>{label}</p>
            </div>

            {arrowRight ? <img src={Images.icon.arrowRightWhite} className="ButtonPrimaryIcon" alt="icon"/> : false}
        </div>
    </button>
);

export default ButtonPrimary;
