import {
  USER_SET_NOTIFICATION_TOKEN,
  USER_SET_GEO_LOCATION,
} from '../actions/types';

export const setUserNotificationToken = token => {
  return {
      type: USER_SET_NOTIFICATION_TOKEN,
      payload: token
  };
};

export const setUserGeoLocation = (latitude, longitude) => {
  return {
      type: USER_SET_GEO_LOCATION,
      payload: {
          latitude,
          longitude,
      }
  };
};
