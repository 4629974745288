import React, { Component, memo } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import './styles.css';
import PhotoCircle from '../PhotoCircle';
import Subheading from '../Subheading';
import { Images } from '../../config/images';

class PhotoCircleList extends Component {
  photos = [ ...this.props.photos ]

  shouldComponentUpdate(nextProps) {
    if (JSON.stringify(this.photos) === JSON.stringify(nextProps.photos)) {
      return false;
    } else {
      this.photos = [ ...nextProps.photos ];
      return true;
    }
  }

  render() {
    const { photos, onAdd, onDelete, onReorderingStart, onReorderingEnd, onReorderingMoved } = this.props;
    const SortableItem = memo(SortableElement(({data, index}) => {
      if (data) {
        return (
          <PhotoCircle
            photo={data.url}
            onPress={() => {
              onDelete(data.id);
            }}
            icon={Images.icon.crossGreen}
            isWhite
            key={data.id}
            index={index}
            draggable={true}
          />
        );
      }
      return(
        <PhotoCircle
          onPress={onAdd}
          icon={Images.icon.plusWhite}
          isEmpty
          key={data.id}
          index={index}
        />
      );
    }));

    const SortableList = memo(SortableContainer(({photos}) => {
      let images = new Array(6 - photos.length).fill(false);
      images = photos.concat(images);
      return (
        <div className="PhotoCircleListPhotosContainer">
          {images.map((data, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              data={data}
              disabled={data ? false : true}
              collection={data ? 'movable' : 'static'}
            />
          ))}
        </div>
      );
    }));

    const shouldCancelStart = (sender) => {
      if (sender.target !== undefined && sender.target.parentElement !== undefined) {
       if (sender.target.parentElement.type === 'submit') {
          return true
        }
      }
      return false
    };

    return (
      <div className="PhotoCircleListContainer">
        <Subheading
          heading={'Photos'}
        />
        <SortableList
          axis='xy'
          photos={photos}
          shouldCancelStart={shouldCancelStart}
          onSortStart={onReorderingStart}
          onSortEnd={onReorderingEnd}
          onSortMove={onReorderingMoved}
          pressDelay={100}
        />
      </div>
    );
  }
}

export default PhotoCircleList;
