import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setCurrentChatUser } from '../../actions/ChatActions';
import { closeNewActiveChat } from '../../actions/ChatActions';
import { closeMatch } from '../../actions/MatchesActions';
import { blurToElementWithId } from '../../helpers/blur';

import './styles.css';
import { MainPaths } from '../../config/paths';

import LevelCircles from '../../components/LevelCircles';
import ButtonPurple from '../../components/ButtonPurple';
import ButtonGrey from '../../components/ButtonGrey';

class ActiveChatModal extends Component {
  componentDidMount() {
    blurToElementWithId('active-chat-blur-view', () => {
      document.getElementById('active-chat-container').style.visibility = 'visible';
    });
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.chat.currentChatUser.user_id === 0) {
      this.props.setCurrentChatUser(this.props.chat.newActiveChatUser);
    } else if (nextProps.chat.currentChatUser.user_id !== this.props.chat.currentChatUser.user_id) {
      this.openChat();
      this.props.closeNewActiveChat()
    }
  }

  openChat = () => {
    const { history } = this.props;
    history.replace(MainPaths.chat);
  }

  render() {
    const { newActiveChatUser } = this.props.chat;
    return (
      <div className="ActiveChatModalContainer" id="active-chat-container">
            <div className="ActiveChatModalBlur" id="active-chat-blur-view"/>
            <div className="ActiveChatModalContent">
              <p className="ActiveChatModalTitle">{'Active chat'}</p>

              <div className="ActiveChatModalProfileContent">
                  <LevelCircles 
                    profilePicture={newActiveChatUser.profile_picture}
                    noBar
                  />
              </div>

              <p className="ActiveChatModalText">{`${newActiveChatUser.name} has activated the chat`}</p>

              <ButtonPurple
                  label={'Go to chat'}
                  onPress={() => {
                    this.props.setCurrentChatUser({ user_id: 0 });
                    this.props.closeMatch();
                  }}
              />
              <div className="ActiveChatModalCloseButton">
                <ButtonGrey
                    label={'Close'}
                    onPress={() => {
                      this.props.closeNewActiveChat()
                      this.props.closeMatch();
                    }}
                    small
                    noPadding
                    width={150}
                />
              </div>
            </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    persist: store.persist,
    chat: store.chat,
  };
};

const mapDispatchToProps = {
  setCurrentChatUser,
  closeNewActiveChat,
  closeMatch
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ActiveChatModal)
);
