import React, { Component } from 'react';

import './styles.css';
import { Levels } from '../../config/levels';
import LevelBar from '../LevelBar';
import ModalImage from '../ModalImage';

class ProfileWithLevel extends Component {
  state = {
    fullscreenPhoto: null,
  };
  render () {
    const { profilePicture, level, levelColor } = this.props;
    return (
        <div className="ProfileWithLevelContainer">
          <img
            className="ProfileWithLevelProfile"
            src={profilePicture}
            alt="profile"
            onClick={() => this.setState({ fullscreenPhoto: profilePicture })}
          />
          {
            this.state.fullscreenPhoto && (
              <ModalImage
                image={this.state.fullscreenPhoto}
                onClose={() => this.setState({ fullscreenPhoto: null })}
              />
            )
          }
          <LevelBar
            className="ProfileWithLevelLevelBar"
            text={`${Levels[level]}`}
            color={levelColor}
            profileBar
          />
        </div>
    );
  }
}

export default ProfileWithLevel;
