import {
  CHAT_ACTIVATE,
  CHAT_ACTIVATE_SUCCESS,
  CHAT_ACTIVATE_FAIL,
  CHAT_HISTORY_FETCH,
  CHAT_HISTORY_FETCH_SUCCESS,
  CHAT_HISTORY_FETCH_FAIL,
  CHAT_SET_CURRENT_USER,
  CHAT_DATA_FETCH,
  CHAT_DATA_FETCH_SUCCESS,
  CHAT_DATA_FETCH_FAIL,
  CHAT_CLOSE,
  CHAT_CLOSE_SUCCESS,
  CHAT_CLOSE_FAIL,
  CHAT_CLOSED_BY_PARTNER,
  CHAT_TEXT_CHANGED,
  CHAT_SEND,
  CHAT_SEND_SUCCESS,
  CHAT_SEND_FAIL,
  CHAT_RECEIVED,
  NEW_ACTIVE_CHAT_USER,
  CLOSE_NEW_ACTIVE_MODAL
} from './types';

import { Api } from '../config/api';
import { get, post } from '../helpers/api';

export const chatTextChanged = (text) => {
  return ({
    type: CHAT_TEXT_CHANGED,
    payload: text
  });
};

export const receivedMessage = (data) => {
  return ({
    type: CHAT_RECEIVED,
    payload: data
  });
};

export const setCurrentChatUser = (data) => {
  return ({
    type: CHAT_SET_CURRENT_USER,
    payload: data
  });
};

export const newActiveChatUser = (data) => {
  return ({
    type: NEW_ACTIVE_CHAT_USER,
    payload: data,
  });
};

export const closeNewActiveChat = () => {
  return {
    type: CLOSE_NEW_ACTIVE_MODAL
  }
}

export const activateChat = (token, data) => {
  return dispatch => {
    dispatch({
      type: CHAT_ACTIVATE,
      payload: 'Activating chat',
    });

    post(token, Api.requests.activateChat, data)
    .then(() => {
      dispatch({ type: CHAT_ACTIVATE_SUCCESS });
    })
    .catch((e) => {
      console.log(e);
      switch (e.response.status) {
        case 401:
          dispatch({ 
            type: CHAT_ACTIVATE_FAIL,
            payload: 'Invitation not found!' 
          });
          break;

        case 406:
          dispatch({ 
            type: CHAT_ACTIVATE_FAIL,
            payload: 'Exclusive chat already exists!' 
          });
          break;

        default:
          dispatch({ 
            type: CHAT_ACTIVATE_FAIL,
            payload: 'Something went wrong!' 
          });
          break;
      }
    });
  };
};

export const getChatHistory = (token) => {
  return dispatch => {
    dispatch({
      type: CHAT_HISTORY_FETCH,
      payload: 'Getting chat history',
    });

    get(token, Api.requests.chatHistory)
    .then(response => {      
      dispatch({ 
        type: CHAT_HISTORY_FETCH_SUCCESS,
        payload: response.data
       });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ 
        type: CHAT_HISTORY_FETCH_FAIL,
        payload: 'Something went wrong!' 
      });
    });
  };
};

export const getChatData = (token, id) => {
  return dispatch => {
    dispatch({
      type: CHAT_DATA_FETCH,
      payload: 'Getting chat data',
    });

    get(token, Api.requests.chat(id))
    .then(response => {
      dispatch({ 
        type: CHAT_DATA_FETCH_SUCCESS,
        payload: response.data
       });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ 
        type: CHAT_DATA_FETCH_FAIL,
        payload: 'Something went wrong!' 
      });
    });
  };
};

export const sendMessage = (token, id, data) => {
  const timeStamp = Date.now() / 1000;
  let message = null;
  const { text } = data;

  if (text) {
    message = { 
      text,
      photo: '',
      receiver_id: id,
      sent_timestamp: timeStamp,
    };
  }
  
  return dispatch => {
    dispatch({
      type: CHAT_SEND,
      payload: message,
    });

    post(token, Api.requests.chat(id), data)
    .then(response => {
      dispatch({ 
        type: CHAT_SEND_SUCCESS,
        payload: {
          confirmedMessage: response.data,
          sentMessage: message,
        }
       });
    })
    .catch((e) => {
        let error = 'Something went wrong!';
        let closed = false;
        if (e.response.data.message) {
            error = e.response.data.message;
        }
        if (e.response.status === 406) {
            closed = true;
        }

        dispatch({ 
            type: CHAT_SEND_FAIL,
            payload: {
                error,
                message,
                closed,
            }, 
        });
    });
  };
};

export const closeChat = (token, data) => {
  return dispatch => {
    dispatch({
      type: CHAT_CLOSE,
      payload: 'Closing chat',
    });

    post(token, Api.requests.closeChat, data)
    .then(() => {
      dispatch({ type: CHAT_CLOSE_SUCCESS });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ 
        type: CHAT_CLOSE_FAIL,
        payload: 'Something went wrong!' 
      });
    });
  };
};

export const chatClosed = () => {
    return {
        type: CHAT_CLOSED_BY_PARTNER,
    };
};
