import React from 'react';

import InspiringPeopleListItem from '../InspiringPeopleListItem';
import './styles.css';

const InspiringPeopleList = ({ persons }) => {
    const items = persons.filter(person => person !== '').map((data, index) => {
        return (
            <InspiringPeopleListItem
                key={index}
                firstChild={index === 0}
                name={data}
            />
        );
    });

    return (
        <div className="InspiringPeopleListContainer">
            {items}
        </div>
    );
};

export default InspiringPeopleList;
