import React from 'react';

import './styles.css';
import ButtonPlay from '../ButtonPlay';

const SoundBar = ({ playing, onPress, image, length }) => (
    <div className="SoundBarContainer">
        <ButtonPlay
            playing={playing}
            onPress={onPress}
        />

        <img src={image} className="SoundBarImage" alt="soundBar"/>

        <p className="SoundBarLength">{length}</p>
    </div>
);

export default SoundBar;
