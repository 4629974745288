import vmsg from 'vmsg';

import { store } from '../reducers/index';
import {
  audioStatusChanged,
  playbackSoundObjectChanged,
  recordingSoundObjectChanged,
} from '../actions/AudioActions';
import { showInfoAlert } from '../components/Alert';

const wasmURL = 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm';
const shimURL = 'https://unpkg.com/wasm-polyfill.js@0.2.0/wasm-polyfill.js';

class AudioManager {
  static instance = null;
  static createInstance() {
      const instance = new AudioManager();
      return instance;
  }
  static shared() {
      if (!AudioManager.instance) {
        AudioManager.instance = AudioManager.createInstance();
      }
      return AudioManager.instance;
  }

  recorder = new vmsg.Recorder({
    wasmURL,
    shimURL,
  });
  workerInit = false
  playbackAudioObject = null;

  playStopUrl = async (url) => {
    if (url === null) {
      return;
    }

    if (this.playbackAudioObject !== null) {
      if (this.playbackAudioObject.duration > 0 && !this.playbackAudioObject.paused) {
        this.playbackAudioObject.pause();
        store.dispatch(audioStatusChanged({ playing: false }));
        store.dispatch(playbackSoundObjectChanged(null, null));
      } else {
        this.playbackAudioObject.src = url;
        this.playbackAudioObject.play();
        store.dispatch(playbackSoundObjectChanged(this.playbackSoundObject, url));
        store.dispatch(audioStatusChanged({ playing: true }));
      }
      return;
    }

    //First source    
    this.playbackAudioObject = new Audio(url);
    this.playbackAudioObject.play();
    this.playbackAudioObject.onended = () => {
      store.dispatch(audioStatusChanged({ playing: false }));
      store.dispatch(playbackSoundObjectChanged(null, null));
    }
    store.dispatch(playbackSoundObjectChanged(this.playbackSoundObject, url));
    store.dispatch(audioStatusChanged({ playing: true })); 
  }

  startRecording = async () => {
    // check microphone state
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    if (stream.getAudioTracks()[0].muted) {
      store.dispatch(audioStatusChanged({ recording: false })); 
      store.dispatch(recordingSoundObjectChanged(null));
      showInfoAlert('Error', `Your microphone ${stream.getAudioTracks()[0].label} is muted.`, 'Ok');
      return
    }

    try {
      await this.recorder.initAudio();
      if (!this.workerInit) {
        await this.recorder.initWorker();
        this.workerInit = true;
      }
      this.recorder.startRecording();
      store.dispatch(audioStatusChanged({ recording: true })); 
    } catch (e) {
      console.error(e);
      showInfoAlert('Error', `Your microphone ${stream.getAudioTracks()[0].label} is not accessible.`, 'Ok');
      store.dispatch(recordingSoundObjectChanged(null));
    }
  }

  stopRecording = async () => {
    try {
      const blob = await this.recorder.stopRecording();
      if (!blob.size) {
        store.dispatch(audioStatusChanged({ recording: false }));
        showInfoAlert('Error', 'Audio recording failed.', 'Ok');
        return
      }
      store.dispatch(audioStatusChanged({ recording: false })); 
      store.dispatch(recordingSoundObjectChanged(null));

      const formData = new FormData();
      formData.append('text', 'voice');
      formData.append('name', 'file');
      formData.append('file', blob);
      console.log('blob', blob);
      return formData;
    } catch (e) {
      store.dispatch(audioStatusChanged({ recording: false })); 
    }
  }
}

export default AudioManager;