import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css'

export const configToasts = () => {
  toast.configure({
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false,
    toastClassName: 'notification-toast',
  })
}

const notificationToast = (text, type = 'success') => {
  switch (type) {
    case 'success':
      toast.success(text);
      break;
    case 'error':
        toast.error(text);
        break;
    default:
      toast.success(text);
  };
}

export default notificationToast;