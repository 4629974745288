import React from 'react';

import './styles.css';
import Subheading from '../Subheading';
import PhotoCircle from '../PhotoCircle';
import ProfileInput from '../ProfileInput';
import { Images } from '../../config/images';

const DreamEditor = ({ dreamImage, dreamText, onPhotoPress, onDreamTextChange }) => (
  <div className="DreamEditorContainer">
    <Subheading
      heading={'Dream'}
      centered
    />
    <div className="DreamEditorContent">
      <PhotoCircle
        icon={Images.icon.pencilWhite}
        photo={dreamImage}
        onPress={onPhotoPress}
        fileChooser
        isDream
      />
      <div className="DreamEditorInputContainer">
        <ProfileInput
          value={dreamText}
          onChangeText={onDreamTextChange}
          placeholder={'Share your dream'}
        />
      </div>
      
    </div>
  </div>
);

export default DreamEditor;
