import React from 'react';
import { Images } from '../../config/images';
import './styles.css';

const PaginationCounter = ({ amount }) => (
    <div className="PaginationCounterContainer">
        <p className="PaginationCounterAmount">{`${amount} left`}</p>

        <img
            src={Images.icon.flagPurple}
            className="PaginationCounterImage"
            alt="flag"
        />
    </div>
);

export default PaginationCounter;
