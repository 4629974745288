import React from 'react';

import './styles.css';
import ButtonText from '../ButtonText';

const FooterPreAuth = ({ text, buttonText, onPress }) => (
  <div>
    <p className="FooterPreAuthText" >{text}</p>
    <ButtonText
      text={buttonText}
      onPress={onPress}
    />
  </div>
);

export default FooterPreAuth;