import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './styles.css';
import { Images } from '../../config/images';
import { MainPaths, AuthPaths } from '../../config/paths';
import { resetPersist } from '../../actions/PersistActions';
import { unsubscribePusher, NOTIFICATION_TYPES } from '../../helpers/pusher';

class Menu extends Component {
  state = {
    open: false,
    unreadMessagesCount: 0,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pathname } = nextProps.location;
    // handle new message badge on chat icon
    if (nextProps.notification !== this.props.notification) {
      const { notifications } = nextProps.notification;
      const lastNotification = notifications[notifications.length - 1];
      if (lastNotification.type === NOTIFICATION_TYPES.NEW_MESSAGE && window.location.pathname !== MainPaths.chat) {
        const { unreadMessagesCount } = this.state;
        if (unreadMessagesCount < 99) {
          this.setState({ unreadMessagesCount: unreadMessagesCount + 1 })
        }
      }
    }
    if (pathname === MainPaths.chat) {
      this.setState({ unreadMessagesCount: 0 })
    }
  }

  logout = () => {
    const { history } = this.props;
    this.props.resetPersist();
    history.replace(AuthPaths.login);
    unsubscribePusher();
  }

  menuPressed = () => {
    this.setState({ open: !this.state.open })
  }

  closeMenu = () => {
    this.setState({ open: false })
  }

  renderMenuHeaderClosed = () => (
    <img
      className="MenuIcon"
      src={Images.icon.menuWhite}
      alt="cover"
      onClick={() => {
        this.menuPressed();
      }}
    />
  )

  renderMenuHeaderOpened = () => (
    <div className="MenuHeaderOpened">
      <p>Menu</p>
      <div className="MenuTitleLine" />
      <button
        className="MenuCloseButton"
        onClick={() => {
          this.menuPressed();
        }}
      >
        <img src={Images.icon.crossWhite} alt="close"/>
      </button>
    </div>
  )

  renderMenuHeader = () => {
    const { open } = this.state;
    return (
      <div className="MenuHeader">
        {open ? this.renderMenuHeaderOpened() : this.renderMenuHeaderClosed()}
      </div>
    );
  }

  renderMenuItem(to, imgSrc, imgAlt, pathCompare, text, badge = 0) {
    const { open } = this.state;
    const noDecorationStyle = { textDecoration: 'none' };
    return (
      <NavLink to={to} exact style={noDecorationStyle}>
        <div className="MenuItem" onClick={() => this.closeMenu()}>
          <img
            src={imgSrc}
            alt={imgAlt}
          />
          {badge ? 
            <div className="MenuItemBadge">
              <p className="MenuItemBadgeNumber">{badge}</p>
            </div>
          : null }
          {!open ? (
            <div 
              id="MenuItemHoverContainer"
              className={
                `${to === MainPaths.invitations? 'MenuItemHoverContainerFix' : ''} ${pathCompare ? 'MenuItemHoverSelected' : ''}`
              }
            >
              <p>
                {text}
              </p>
            </div>
          ) : null}
          {open ? 
            <p 
              className={`MenuText ${pathCompare ? 'MenuTextSelected' : ''}`}
            >
              {text}
            </p> 
          : null}
        </div>
      </NavLink>
    );
  }

  render() {
    const { pathname } = this.props.location;
    const { menu } = Images;
    const { open } = this.state;
    return (
      <div className={`MenuContainer ${open? 'MenuContainerOpen' : ''}`}>
        {this.renderMenuHeader()}

        <div className="MenuItemsContainer">
          {
            this.renderMenuItem(
              MainPaths.matches,
              (pathname === MainPaths.matches ? menu.matchesSelected : menu.matches),
              "matches",
              (pathname === MainPaths.matches),
              "Matches"
            )
          }
          {
            this.renderMenuItem(
              MainPaths.chat,
              (pathname.startsWith(MainPaths.chat) ? menu.chatSelected : menu.chat),
              "chat",
              (pathname.startsWith(MainPaths.chat)),
              "Chat",
              this.state.unreadMessagesCount
            )
          }
          {
            this.renderMenuItem(
              MainPaths.profile,
              (pathname.startsWith(MainPaths.profile) ? menu.profileSelected : menu.profile),
              "profile",
              (pathname.startsWith(MainPaths.profile)),
              "Profile"
            )
          }
          {
            this.renderMenuItem(
              MainPaths.invitations,
              (pathname.startsWith(MainPaths.invitations) ? menu.invitesSelected : menu.invites),
              "invites",
              (pathname.startsWith(MainPaths.invitations)),
              "Invitations"
            )
          }
          {
            this.renderMenuItem(
              MainPaths.settings,
              (pathname.startsWith(MainPaths.settings) ? menu.settingsSelected : menu.settings),
              "settings",
              (pathname.startsWith(MainPaths.settings)),
              "Settings"
            )
          }
          {
            this.renderMenuItem(
              MainPaths.subscription,
              (pathname.startsWith(MainPaths.subscription) ? menu.subscriptionSelected : menu.subscription),
              "subscription",
              (pathname.startsWith(MainPaths.subscription)),
              "Subscription"
            )
          }
        </div>

        <div className="MenuLogoutContainer">
          <img
            className={`MenuLogoutIcon ${open? 'MenuLogoutIconVisible' : ''}`}
            src={Images.menu.logout}
            alt="logout"
            onClick={() => {
              this.logout();
            }}
          />
          {!open ? (
            <div className="MenuLogoutHoverContainer">
              <p>
                {'Sign out'}
              </p>
            </div>
          ) : null}
        </div>
        {open ? 
          <p 
            className="MenuLogoutText"
            onClick={() => {
              this.logout();
            }}
          >
            {'Sign out'}
          </p> 
        : null}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      notification: store.notification,
  };
};

const mapDispatchToProps = {
  resetPersist
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Menu)
);
