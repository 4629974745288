import React, { Component } from 'react';
import { connect } from 'react-redux';
import MultiSlider, { Progress, Dot } from 'react-multi-bar-slider';

import './styles.css';

import FilterHeader from '../FilterHeader';
import GenderSelection from '../GenderSelection';
import Subheading from '../Subheading';
import { Colors } from '../../config/colors';
import { showInfoAlert } from '../../components/Alert';
import notificationToast from '../../components/NotificationToast';
import ButtonPurple from '../ButtonPurple';
import {
    fetchFilter,
    updateFilter,
    ageChanged,
    distanceChanged,
    genderChanged,
} from '../../actions/FilterActions';

class Filter extends Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.filter.updating === false && this.props.filter.updating === true) {
            if (nextProps.filter.error.length === 0) {
                notificationToast('Filter settings are updated');
            } else {
                showInfoAlert('Filter',
                nextProps.filter.error,
                'Ok');
            }
        }
    }

    componentDidMount() {
        this.props.fetchFilter(this.props.persist.token);
    }

    changeGender = gender => {
      console.log('new gender', gender);
        const newGender = [...this.props.filter.gender];
        const index = newGender.indexOf(gender);

        if (index > -1) {
            newGender.splice(index, 1);
        } else {
            newGender.push(gender);
        }

        if (newGender.length === 0) {
            const newValue = gender === 'male' ? 'female' : 'male';

            newGender.push(newValue);
        }

        this.props.genderChanged(newGender);
    };

    setFilter = () => {
        const data = {
            min_age: this.props.filter.minAge,
            max_age: this.props.filter.maxAge,
            distance: this.props.filter.distance,
            gender: this.props.filter.gender.length > 1 ? 'both' : this.props.filter.gender[0],
        };

        this.props.updateFilter(this.props.persist.token, data);
    };

    changeFilterAge = (newValue) => {
      if (newValue < 18 || newValue > 100) {
        return;
      }
      const minAge = this.props.filter.minAge;
      const maxAge = this.props.filter.maxAge
      let isMinAge = true;
      (Math.abs(maxAge - newValue) < Math.abs(minAge - newValue))
      ? isMinAge = false : isMinAge = true;

      if (isMinAge) {
        this.props.ageChanged(newValue, maxAge);
      } else {
        this.props.ageChanged(minAge, newValue);
      }
    }

    changeFilterLocation = (newValue) => {
      if (newValue < 1 || newValue > 100) {
        return;
      }
      newValue = newValue*10;
      this.props.distanceChanged(newValue);
    }

    render() {
        return (
          <div className="FilterContainer">
            <FilterHeader />

            <div className="FilterRow">

              <div className="FilterBox">
                <Subheading
                  heading={'Gender'}
                />
                <div className="FilterGenderContainer">
                  <GenderSelection
                    male={this.props.filter.gender.indexOf('male') > -1}
                    female={this.props.filter.gender.indexOf('female') > -1}
                    onPressMale={() => { this.changeGender('male'); }}
                    onPressFemale={() => { this.changeGender('female'); }}
                  />
                </div>
              </div>

              <div className="FilterBox">
                <Subheading
                  heading={'Age'}
                />
                <div className="FilterValueContainer">
                    <p className="FilterValueText">{`${this.props.filter.minAge} - ${this.props.filter.maxAge}`}</p>
                </div>

                <div className="FilterSlider">
                  <MultiSlider
                    onSlide={newValue => this.changeFilterAge(newValue)}
                    height={2}
                  >
                    <Progress color={Colors.grey.lighter} height={5} progress={this.props.filter.minAge}>
                      <Dot color={Colors.primary.default} height={16} width={16}/>
                    </Progress>

                    <Progress color={Colors.primary.default} height={5} progress={this.props.filter.maxAge}>
                      <Dot color={Colors.primary.default} height={16} width={16}/>
                    </Progress>
                  </MultiSlider>
                </div>
              </div>

              <div className="FilterBox">
                <Subheading
                  heading={'Location'}
                />
                <div className="FilterValueContainer">
                    <p className="FilterValueText">{`${this.props.filter.distance} km`}</p>
                </div>

                <div className="FilterSlider">
                  <MultiSlider
                    onSlide={newValue => this.changeFilterLocation(newValue)}
                    height={2}
                  >
                    <Progress color={Colors.primary.default} height={5} progress={this.props.filter.distance/10}>
                      <Dot color={Colors.primary.default} height={16} width={16}/>
                    </Progress>
                  </MultiSlider>
                </div>
              </div>
            </div>
            <div className="FilterSaveButton">
              <ButtonPurple
                label={'Save'}
                onPress={() => { this.setFilter(); }}
                small
              />
            </div>

          </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        persist: store.persist,
        filter: store.filter,
    };
};

const mapDispatchToProps = {
    fetchFilter,
    updateFilter,
    ageChanged,
    distanceChanged,
    genderChanged,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Filter);
