import React from 'react';

import './styles.css';

const ButtonCirclePurple = ({ icon, onPress, small, medium, altText }) => (
    <button onClick={onPress} className="ButtonCirclePurpleButton">
        <div className={`ButtonCirclePurpleContainer ${medium ? 'ButtonCirclePurpleMedium' : ''}`}>
            <img 
              className={`ButtonCirclePurpleIcon
               ${small ? 'ButtonCirclePurpleIconSmall' : ''}
               ${medium ? 'ButtonCirclePurpleIconMedium' : ''}`}
              src={icon}
              alt={altText || 'icon'}
            />
        </div>
        <p id="ButtonCirclePurpleAlt" className="ButtonCirclePurpleAltText">{altText}</p>
    </button>
);

export default ButtonCirclePurple;
