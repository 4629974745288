import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardElement, injectStripe, IdealBankElement } from 'react-stripe-elements';

import {
  subscribeRequest,
  subscribeSourceRequest,
  cancelGoPremium,
  firstNameChanged,
  lastNameChanged,
  emailChanged,
  paymentTypeChanged,
  tokenChanged,
  createPaymentIntentRequest,
} from '../../actions/SubscriptionActions';
import { blurToElementWithId } from '../../helpers/blur';
import { isValidEmail } from  '../../helpers/utils';
import { createIdealSource, createBancontactSource } from  '../../helpers/stripe';

import ButtonPurple from '../../components/ButtonPurple';
import ButtonGrey from '../../components/ButtonGrey';
import ProfileInput from '../../components/ProfileInput';
import SelectInput from '../../components/SelectInput';
import notificationToast from '../../components/NotificationToast';
import Spinner from '../../components/Spinner';
import './styles.css';

class GoPremiumModal extends Component {
  constructor(props) {
    super(props)
    this.state = { payementFormValidated: false };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    blurToElementWithId('gopremium-blur-view', () => {
      document.getElementById('gopremium-container').style.visibility = 'visible';
    });
    // init name from profile
    const { name } = this.props.profile;
    if (name) {
      let names = name.split(' ');
      if (names.length) {
        this.props.firstNameChanged(names[0]);
        names.shift();
        if (names.length) {
          this.props.lastNameChanged(names.join(' '));
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.subscription.premium === true && nextProps.subscription.type !== 'trial') {
      this.onComplete();
    }
  }

  async submit(ev) {
    const { firstName, lastName, email, paymentType } = this.props.subscription;
    if (paymentType === 'iDeal') {
      let { source } = await createIdealSource(this.props.stripe, firstName, lastName, email)
      if (source && source.id && source.redirect && source.redirect.url) {
        const authorizePaymentUrl = source.redirect.url
        this.props.subscribeSourceRequest(this.props.persist.token, authorizePaymentUrl, {
          first_name: firstName,
          last_name: lastName,
          email,
          source: source.id,
          type: 'ideal'
         })
      }
      return
    }
    if (paymentType === 'Bancontact') {
      let { source } = await createBancontactSource(this.props.stripe, firstName, lastName, email)
      if (source && source.id && source.redirect && source.redirect.url) {
        const authorizePaymentUrl = source.redirect.url
        this.props.subscribeSourceRequest(this.props.persist.token, authorizePaymentUrl, {
          first_name: firstName,
          last_name: lastName,
          email,
          source: source.id,
          type: 'bancontact'
         })
      }
      return
    }
    if (paymentType === 'Credit Card') {
      const { client_secret: clientSecret } = this.props.subscription.paymentIntent
      this.props.subscribeRequest(
        this.props.persist.token,
        {
          name: `${firstName} ${lastName}`,
          email
        },
        clientSecret,
        this.props.stripe,
      )
    }
  }

  validateForm() {
    const { firstName, lastName, email, paymentType } = this.props.subscription;
    if (!firstName || firstName === '') {
      notificationToast('Please provide your first name', 'error');
      return;
    }
    if (!lastName || lastName === '') {
      notificationToast('Please provide your last name', 'error');
      return;
    }
    if (!email || !isValidEmail(email)) {
      notificationToast('Please provide a valid email address', 'error');
      return;
    }

    if (!paymentType || !isValidEmail(email)) {
      notificationToast('Please select a payment method', 'error');
      return;
    }

    if (paymentType === 'Credit Card') {
      this.props.createPaymentIntentRequest(this.props.persist.token)
    }

    this.setState({ payementFormValidated: true })
  }

  onComplete() {
    this.props.cancelGoPremium()
    notificationToast('Thanks for your payment. Your subscription is active now!');
  }

  renderForm() {
    const { firstName, lastName, email, paymentType } = this.props.subscription;
    return (
      <div>
        <div className="GoPremiumForm">
          <p className="GoPremiumModalInputTitle">{'First name'}</p>
          <ProfileInput
            value={firstName}
            onChangeText={(text) => {
              this.props.firstNameChanged(text);
            }}
            placeholder={'First name'}
          />
          <p className="GoPremiumModalInputTitle">{'Last name'}</p>
          <ProfileInput
            value={lastName}
            onChangeText={(text) => {
              this.props.lastNameChanged(text);
            }}
            placeholder={'Last name'}
          />
          <p className="GoPremiumModalInputTitle">{'E-mail address'}</p>
          <ProfileInput
            value={email}
            onChangeText={(text) => {
              this.props.emailChanged(text);
            }}
            placeholder={'E-mail address'}
          />
          <p className="GoPremiumModalInputTitle">{'Payment method'}</p>
          <SelectInput
            value={paymentType}
            values={['iDeal', 'Credit Card', 'Bancontact']}
            onChangeText={(value) => {
              this.props.paymentTypeChanged(value);
            }}
            placeholder={'Choose payment method'}
            safariPlaceholderIndent={66}
            safariOptionsIndent={[120, 106]}
          />
        </div>

        <div className="GoPremiumButtons">
          <ButtonPurple
            label={'Go to payment'}
            onPress={() => this.validateForm()}
            small
          />
          <ButtonGrey
            label={'Cancel'}
            onPress={() => this.props.cancelGoPremium()}
            small
            noPadding
          />
        </div>
      </div>
    );
  }

  renderPurchase() {
    const { paymentType } = this.props.subscription;

    let paymentForm = null
    if (paymentType === 'Credit Card') {
      paymentForm = <CardElement />
    }
    if (paymentType === 'iDeal') {
      paymentForm = <IdealBankElement />
    }

    return (
      <div className="GoPremiumModalCheckout">
        <p className="GoPremiumModalInputTitle">
          {'Would you like to complete the purchase?'}
        </p>
        <p className="GoPremiumModalInputTitle">
          {paymentType === 'iDeal' &&
            'Please select bank below'
          }
          {paymentType === 'Credit Card' &&
            'Please enter card details below'
          }
        </p>
        { paymentForm && paymentForm }
        <div className="GoPremiumButtons">
          <ButtonPurple
            label={'Send'}
            onPress={this.submit}
            small
          />
          <ButtonGrey
            label={'Cancel'}
            onPress={() => this.props.cancelGoPremium()}
            small
            noPadding
          />
        </div>
      </div>
    );
  }

  renderSpinner = () => {
    if (this.props.subscription.loadingMessage.length > 0) {
      return <Spinner status={this.props.subscription.loadingMessage} />;
    }
  };

  canRenderPayment = ({paymentIntent, paymentType}, formValidated) => {
    if (paymentType !== 'Credit Card' && formValidated) return true;
    if (paymentType === 'Credit Card' && formValidated && paymentIntent && paymentIntent.client_secret) return true;
    return false;

  }

  render() {
    const showPayment = this.canRenderPayment(this.props.subscription, this.state.payementFormValidated)
    return (
      <div className="GoPremiumModalContainer" id="gopremium-container">
            <div className="GoPremiumModalBlur" id="gopremium-blur-view"/>
            <div className="GoPremiumModalContent">
              <div className={`GoPremiumModalBox ${showPayment ? 'GoPremiumModalBoxPayment' : ''}`}>
                <p className="GoPremiumModalTitle">{'Go premium'}</p>
                {showPayment ? this.renderPurchase() : this.renderForm()}
              </div>
            </div>
            {this.renderSpinner()}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    persist: store.persist,
    subscription: store.subscription,
    profile: store.profile,
  };
};

const mapDispatchToProps = {
  subscribeRequest,
  subscribeSourceRequest,
  cancelGoPremium,
  firstNameChanged,
  lastNameChanged,
  emailChanged,
  paymentTypeChanged,
  tokenChanged,
  createPaymentIntentRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectStripe(GoPremiumModal));
