import {
  SIGN_UP_USERNAME_CHANGED,
  SIGN_UP_PASSWORD_CHANGED,
  SIGN_UP_NAME_CHANGED,
  SIGN_UP_GENDER_CHANGED,
  SIGN_UP_ACCEPT_TC_CHANGED,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
  SIGN_UP_BIRTHDATE_CHANGED,
} from './types';
import { Api } from '../config/api';
import { guestPost } from '../helpers/api';

export const usernameChanged = text => {
  return {
      type: SIGN_UP_USERNAME_CHANGED,
      payload: text
  };
};

export const passwordChanged = text => {
  return {
      type: SIGN_UP_PASSWORD_CHANGED,
      payload: text
  };
};

export const nameChanged = text => {
  return {
      type: SIGN_UP_NAME_CHANGED,
      payload: text
  };
};

export const birthdateChanged = birthdate => {
  return {
      type: SIGN_UP_BIRTHDATE_CHANGED,
      payload: birthdate
  };
};

export const genderChanged = text => {
  return {
      type: SIGN_UP_GENDER_CHANGED,
      payload: text
  };
}

export const acceptedTermsChanged = accepted => {
  return {
      type: SIGN_UP_ACCEPT_TC_CHANGED,
      payload: accepted
  };
}

export const signUpRequest = (signUpData) => {
  return dispatch => {
      dispatch({ type: SIGN_UP });

      guestPost(Api.requests.signUp, signUpData).then(response => {
          dispatch({
              type: SIGN_UP_SUCCESS,
              payload: response.data.access_token,
          });
      }).catch(e => {            
          console.log(e);
          let payload = 'Something went wrong.';
          if (e.response.data) {
              const errors = e.response.data.errors;
              if (errors) {
                  payload = errors[Object.keys(errors)[0]][0];
              }
          }

          dispatch({
              type: SIGN_UP_FAIL,
              payload,
          });
      });
  };
};
