import React, { Component } from 'react';

import './styles.css';
import ChatHistoryCard from '../ChatHistoryCard';

export default class ChatHistoryList extends Component {
  renderChatHistoryRow = ({ item, index }) => {    
    return (
      <ChatHistoryCard
        user={item}
        onPress={() => {          
          this.props.onUserPress(item);
        }}
        key={`row-${item.invitation_id}-${index}`}
      />
    );
  }

  render() {
    const { history } = this.props;
    return (
      <div className="ChatHistoryListContainer">
        {history.map((item, index) => this.renderChatHistoryRow({ item, index }))}
      </div>
    );
  }
}
