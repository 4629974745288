import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { AdmissionRoutes } from '../../config/routes';
import { AdmissionPaths } from '../../config/paths';

class Admission extends Component {
  componentDidMount () {
    this.resetCurrentPathIfNeeded();
  }

  resetCurrentPathIfNeeded = () => {
    const { pathname } = this.props.location;
    const { history } = this.props;
    
    var exists = Object.keys(AdmissionPaths)
      .some((key) => AdmissionPaths[key] === pathname);
    if (!exists) {
      history.replace(AdmissionPaths.admissionStart);
    }
  }

  render() {
    return (
      <div>
        <AdmissionRoutes />
      </div>
    );
  }
}

export default withRouter(Admission);
