import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { activateChat, setCurrentChatUser } from '../../actions/ChatActions';
import { fetchInvitations } from '../../actions/InvitationActions';
import { closeMatch } from '../../actions/MatchesActions';
import { blurToElementWithId } from '../../helpers/blur';

import OverlayMatch from '../../components/OverlayMatch';
import { Images } from '../../config/images';
import { MainPaths } from '../../config/paths';

class OverlayMatchModal extends Component {
  componentDidMount() {
      this.props.fetchInvitations(this.props.persist.token);
      blurToElementWithId('match-blur-view', () => {
        document.getElementById('match-container').style.visibility = 'visible';
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {  
      if (nextProps.chat.activating === false && this.props.chat.activating === true) {
          this.props.setCurrentChatUser({ user_id: 0 });
      } else if (nextProps.chat.currentChatUser.user_id === 0) {
          this.props.setCurrentChatUser(this.props.matches.newMatch);
      } else if (nextProps.chat.currentChatUser.user_id !== this.props.chat.currentChatUser.user_id) {
          this.openChat();
          this.props.closeMatch();
      }
  }

  openChat = () => {
    const { history } = this.props;
    history.replace(MainPaths.chat);
  }

  shouldHideActivateButton = () => {
      const { match } = this.props.invitations;
      const { newMatch } = this.props.matches;

      for (let index = 0; index < match.length; index++) {
        const invitation = match[index];
        if (invitation.status === 'active') {
          return true;
        } else if (invitation.status === 'unavailable' && invitation.user_id === newMatch.user_id) {
          return true;
        }

      }

      return false;
  }

  render() {
    const { matches, profile } = this.props;
    const { newMatch } = matches;
    const profileImage = profile.profileImage !== null ?
      profile.profileImage : Images.placeholder.avatar;
    const matchImage = newMatch.profile_picture ?
      newMatch.profile_picture : Images.placeholder.avatar;
    return (
      <OverlayMatch
        profilePicture={profileImage}
        matchPicture={matchImage}
        matchName={newMatch.name}
        onActivatePress={() => { 
            this.props.activateChat(this.props.persist.token, { id: newMatch.invitation_id });
        }}
        onClose={() => {
              this.props.closeMatch();
        }}
        hideActivateButton={this.shouldHideActivateButton()}
      />
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      profile: store.profile,
      matches: store.matches,
      notification: store.notification,
      chat: store.chat,
      invitations: store.invitations,
  };
};

const mapDispatchToProps = {
  activateChat,
  setCurrentChatUser,
  fetchInvitations,
  closeMatch
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OverlayMatchModal)
);
