import React from 'react';

import './styles.css';

const DreamCircle = ({ photo, text }) => (
  <div className="DreamCircleContainer">
    <div className="DreamCircleAroundPhoto">
      <img src={photo} className="DreamCircleImage" alt="dreamImage"/>
      <div className="DreamCircleDashedBorder" />
      <p className="DreamCircleText">{text ? `"${text}"` : null}</p>
    </div>
  </div>
);

export default DreamCircle;
