import React from 'react';
import './styles.css'
import { Images } from '../../config/images';

const ButtonBack = ({ onPress }) => (
  <img
  className="BackButton"
    src={Images.icon.arrowLeftPurpleDark} 
    alt="backButton" 
    onClick={onPress} 
  />
);

export default ButtonBack;