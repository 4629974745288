import React from 'react';

import './styles.css';
import { Images } from '../../config/images';

const TitleChat = ({ isActive, name, age, onStopPress, profilePicture, openProfile }) => {
  const correctedName = name === undefined ? '' : name;
  const correctedAge = age === undefined ? '' : age;
  return (
    <div className="TitleChat">
      <img src={profilePicture} className="TitleChatProfilePicture" alt="profilePicture" onClick={openProfile}/>
      <p className="TitleChatName">{`${correctedName} · ${correctedAge}`}</p>
      {
        isActive ? (
          <button className="TitleChatStopButton" onClick={onStopPress}>
            <p className="TitleChatStopText">End chat</p>
            <img src={Images.icon.stopChat} alt="stop"/>
          </button>
        ) : null 
      }
      </div>
    );
};

export default TitleChat;
