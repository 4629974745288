import React, { Component } from 'react';
import { Lightbox } from 'react-modal-image';

import './styles.css';

class ModalImage extends Component {
  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    const { onPrev, onNext, index, total } = this.props;
    if (event.key === 'ArrowRight' && index < total) {
      onNext();
    }
    if (event.key === 'ArrowLeft' && index > 0) {
      onPrev();
    }
  }

  render() {
    const { image, onClose, onPrev, onNext, index, total } = this.props;
    return (
      <div>
        <Lightbox
          large={image}
          onClose={onClose}
          hideDownload
          hideZoom
        />
        <div className="ModalImageArrowsContainer">
          {image && index > 0?
          <div className="ModalImageArrowLeft ModalImageNoSelect" onClick={onPrev}>
            {'⇦'}
          </div> : null
          }
          {image && index < total?
            <div className="ModalImageArrowRight ModalImageNoSelect" onClick={onNext}>
              {'⇨'}
            </div> : null
          }
        </div>
      </div>
    );
  }
}

export default ModalImage;
