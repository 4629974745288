import React from 'react';

import './styles.css';

const MorePhotoCircle = ({ photo, openPhoto }) => (
    <div className="MorePhotoCircleContainer">
        <div className="MorePhotoCircleAroundPhoto">
        <img
          className="MorePhotoCircleImage"
          src={photo}
          alt="photoImage"
          onClick={() => openPhoto(photo)}
        />
        </div>
    </div>
);

export default MorePhotoCircle;
