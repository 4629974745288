function fit(contains) {
  return (parentWidth, parentHeight, childWidth, childHeight, scale = 1, offsetX = 0.5, offsetY = 0.5) => {
    const childRatio = childWidth / childHeight
    const parentRatio = parentWidth / parentHeight
    let width = parentWidth * scale
    let height = parentHeight * scale

    if (contains ? (childRatio > parentRatio) : (childRatio < parentRatio)) {
      height = width / childRatio
    } else {
      width = height * childRatio
    }

    return {
      width,
      height,
      offsetX: (parentWidth - width) * offsetX,
      offsetY: (parentHeight - height) * offsetY
    }
  }
};

const createFormDataFromImageFile = async (file) => {
  return new Promise((resolve) => {
    const maxWidth = 1000;
    const maxHeight = 1000;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
  
      const img = new Image();
      img.onload = () =>{
        const elem = document.createElement('canvas');
        elem.style.backgroundSize = 'cover';
        elem.width = img.width < maxWidth ? img.width : maxWidth;
        elem.height = img.height < maxHeight ? img.height : maxHeight;
        const ctx = elem.getContext('2d');

        const {
          offsetX, 
          offsetY, 
          width, 
          height
        } = fit(false)(elem.width, elem.height, img.width, img.height)
        ctx.drawImage(img, offsetX, offsetY, width, height)

        const formData = new FormData();
        formData.append('text', 'photo');
        formData.append('name', 'file');

        ctx.canvas.toBlob((blob) => {
          formData.append('file', blob);
          resolve(formData);
        }, file.type, 0.4);
      }
      img.src = reader.result;
    }
  });
}

const createFormDataChunksFromImageFile = async (file, chunckMBSize = 10) => {
  return new Promise((resolve) => {
    const maxWidth = 1000;
    const maxHeight = 1000;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
  
      const img = new Image();
      img.onload = () =>{
        const elem = document.createElement('canvas');
        elem.style.backgroundSize = 'cover';
        elem.width = img.width < maxWidth ? img.width : maxWidth;
        elem.height = img.height < maxHeight ? img.height : maxHeight;
        const ctx = elem.getContext('2d');

        const {
          offsetX, 
          offsetY, 
          width, 
          height
        } = fit(false)(elem.width, elem.height, img.width, img.height)
        ctx.drawImage(img, offsetX, offsetY, width, height)

        ctx.canvas.toBlob((blob) => {
          const size = blob.size;
          const chunckSize = chunckMBSize * 1000000;
          const total = Math.ceil(size / chunckSize);
          let formDataChunks = [];
          for (let index = 0; index < total; index++) {
            const formData = new FormData();
            formData.append('text', 'photo');
            formData.append('name', 'file');

            const chunk = blob.slice(index * chunckSize, index * chunckSize + chunckSize, blob.type);
            formData.append('dzuuid', 'hfejkwfjkewhbfjkhbewjkfjk');
            formData.append('dzchunkindex', index);
            formData.append('dztotalfilesize', size);
            formData.append('dzchunksize', chunk.size);
            formData.append('dztotalchunkcount', total);
            formData.append('dzchunkbyteoffset', index * chunckSize);
            
            formData.append('file', chunk);
            formDataChunks.push(formData)
          }
          resolve(formDataChunks);

        }, file.type, 0.4);
      }
      img.src = reader.result;
    }
  });
}

export { createFormDataFromImageFile, createFormDataChunksFromImageFile };