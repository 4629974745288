import {
  RESET_REDUCERS,
  PROFILE_FETCH_SUCCESS,
  PROFILE_UPDATE,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_CANCEL,
  PROFILE_UPLOAD_PHOTO,
  PROFILE_UPLOAD_PHOTO_SUCCESS,
  PROFILE_UPLOAD_PHOTO_FAIL,
  PROFILE_DELETE_PHOTO_SUCCESS,
  PROFILE_UPLOAD_DREAM_PHOTO,
  PROFILE_UPLOAD_DREAM_PHOTO_SUCCESS,
  PROFILE_UPLOAD_DREAM_PHOTO_FAIL,
  PROFILE_UPLOAD_VOICE,
  PROFILE_UPLOAD_VOICE_SUCCESS,
  PROFILE_UPLOAD_VOICE_FAIL,
  PROFILE_DREAM_CHANGED,
  PROFILE_NAME_CHANGED,
  PROFILE_CITY_CHANGED,
  PROFILE_COUNTRY_CHANGED,
  PROFILE_BIRTHDAY_CHANGED,
  PROFILE_GENDER_CHANGED,
  PROFILE_ADD_MENTOR,
  PROFILE_MENTOR_CHANGED,
  PROFILE_MENTOR_REMOVED,
  PROFILE_MENTOR_SUBMIT,
  PROFILE_MENTOR_SUBMIT_SUCCESS,
  PROFILE_MENTOR_SUBMIT_FAIL,
  PROFILE_LEVEL_CHANGED,
  PROFILE_REORDERING_PHOTOS,
  PROFILE_PHOTOS_ORDER_CHANGED,
  PROFILE_ADMISSION_COMPLETED
} from '../actions/types';

const INITIAL_STATE = {
  userId: 0,
  updateTimestamp: 0,
  fetchTimestamp: 0,
  name: null,
  gender: null,
  profileImage: null,
  dream: null,
  dreamImage: null,
  voice: {},
  city: null,
  country: null,
  birthday: null,
  mentors: [''],
  photos: [],
  level: 0,
  admissionRequired: false,
  loadingMessage: '',
  error: '',
  updatingProfile: false,
  reorderingPhotos: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case RESET_REDUCERS:
          return {
              ...INITIAL_STATE,
          };

      case PROFILE_FETCH_SUCCESS:
          return {
              ...state,
              fetchTimestamp: Date.now() / 1000 | 0,
              userId: action.payload.id,
              name: action.payload.name,
              gender: action.payload.gender,
              profileImage: action.payload.profile_image,
              dream: action.payload.dream.text,
              dreamImage: action.payload.dream.image,
              voice: action.payload.voice,
              city: action.payload.city,
              country: action.payload.country,
              mentors: action.payload.mentors.length ? action.payload.mentors : INITIAL_STATE.mentors,
              photos: action.payload.photos,
              level: action.payload.level,
              birthday: action.payload.birthday,
              admissionRequired: action.payload.admission_required,
          };

      case PROFILE_UPDATE:
          return {
              ...state,
              updatingProfile: true,
          };

      case PROFILE_UPDATE_SUCCESS:
          return {
              ...state,
              updateTimestamp: Date.now() / 1000 | 0,
              updatingProfile: false,
              error: '',
          };

      case PROFILE_UPDATE_FAIL:
          return {
              ...state,
              updatingProfile: false,
              error: action.payload
          };

      case PROFILE_UPDATE_CANCEL:
          return {
              ...state,
              updateTimestamp: Date.now() / 1000 | 0,
          };

      case PROFILE_UPLOAD_PHOTO:
          return {
              ...state,
              loadingMessage: action.payload,
          };

      case PROFILE_UPLOAD_PHOTO_SUCCESS: {
          const photos = state.photos.slice();
          photos.push(action.payload);
          return {
              ...state,
              loadingMessage: '',
              photos,
              profileImage: photos.length ? photos[0].url : INITIAL_STATE.profileImage
          };
      }

      case PROFILE_UPLOAD_PHOTO_FAIL:
          return {
              ...state,
              loadingMessage: '',
          };

      case PROFILE_DELETE_PHOTO_SUCCESS: {
          const deletedPhotoId = action.payload;
          let removed = false;
          const photos = state.photos.slice().filter((photo) => {
              if (removed) {
                  return true;
              }
              if (photo.id === deletedPhotoId) {
                  removed = true;
                  return false;
              }
              return true;
          });
          return {
              ...state,
              photos,
              profileImage: photos.length ? photos[0].url : INITIAL_STATE.profileImage
          };
      }

      case PROFILE_UPLOAD_DREAM_PHOTO:
          return {
              ...state,
              loadingMessage: action.payload,
          };

      case PROFILE_UPLOAD_DREAM_PHOTO_SUCCESS:
          return {
              ...state,
              dreamImage: action.payload,
              loadingMessage: ''
          };

      case PROFILE_UPLOAD_DREAM_PHOTO_FAIL:
          return {
              ...state,
              loadingMessage: '',
          };

      case PROFILE_UPLOAD_VOICE:
          return {
              ...state,
              loadingMessage: action.payload,
          };

      case PROFILE_UPLOAD_VOICE_SUCCESS:
          return {
              ...state,
              voice: action.payload,
              loadingMessage: ''
          };

      case PROFILE_UPLOAD_VOICE_FAIL:
          return {
              ...state,
              loadingMessage: '',
          };

      case PROFILE_DREAM_CHANGED:
          return {
              ...state,
              dream: action.payload
          };

      case PROFILE_NAME_CHANGED:
          return {
              ...state,
              name: action.payload
          };

      case PROFILE_CITY_CHANGED:
          return {
              ...state,
              city: action.payload
          };

      case PROFILE_COUNTRY_CHANGED:
          return {
              ...state,
              country: action.payload
          };

      case PROFILE_BIRTHDAY_CHANGED:
          return {
              ...state,
              birthday: action.payload
          };

      case PROFILE_GENDER_CHANGED:
          return {
              ...state,
              gender: action.payload
          };

      case PROFILE_ADD_MENTOR:
          return {
              ...state,
              mentors: [
                  ...state.mentors,
                  '',
              ]
          };

      case PROFILE_MENTOR_CHANGED:
          return {
              ...state,
              mentors: action.payload
          };

      case PROFILE_MENTOR_REMOVED:
          return {
              ...state,
              mentors: action.payload.length? action.payload : INITIAL_STATE.mentors
          };

      case PROFILE_MENTOR_SUBMIT:
          return {
              ...state
          };

      case PROFILE_MENTOR_SUBMIT_SUCCESS: {
          const mentors = action.payload.map(m => m.name);

          return {
              ...state,
              mentors
          };
      }

      case PROFILE_MENTOR_SUBMIT_FAIL:
          return {
              ...state
          };

      case PROFILE_LEVEL_CHANGED:
          return {
              ...state,
              level: action.payload
          };

      case PROFILE_REORDERING_PHOTOS:
          return {
              ...state,
              reorderingPhotos: action.payload
          };

      case PROFILE_PHOTOS_ORDER_CHANGED: {
          return {
              ...state,
              photos: action.payload,
              profileImage: action.payload.length ? action.payload[0].url : INITIAL_STATE.profileImage
          };
        }

      case PROFILE_ADMISSION_COMPLETED:
          return {
            ...state,
            admissionRequired: false
          };

      default:
          return state;
  }
};
