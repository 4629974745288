export const reverseToReadableDate = (date = '') => {
  return (date.length >= 10) ? `${date.substr(8, 2)}-${date.substr(5, 2)}-${date.substr(0, 4)}` : '';
};

export const readableDateToReverse = (date = '') => {
  return (date.length >= 10) ? `${date.substr(6, 4)}-${date.substr(3, 2)}-${date.substr(0, 2)}` : '';
};

export const dateToReadableDate = date => {
  return date ? `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}` : '';
};

export const dateToReadableDateSlash = date => {
  return date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : '';
};

export const dateToReadableDateTime = date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();


  if (hours < 10) {
      hours = `0${hours}`;
  }

  if (minutes < 10) {
      minutes = `0${minutes}`;
  }

  const checkDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  const checkTime = `${hours}:${minutes}`;

  return `${checkDate} at ${checkTime}`;
};

export const dateToDateString = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();


  if (dd < 10) {
      dd = `0${dd}`;
  }

  if (mm < 10) {
      mm = `0${mm}`;
  }

  return `${yyyy}-${mm}-${dd}`;
};

export const dateStringToDate = (date = '') => {
  const year = Number.parseInt(date.substr(0, 4), 10);
  const month = Number.parseInt(date.substr(5, 2), 10);
  const day = Number.parseInt(date.substr(8, 2), 10);

  const dateObject = new Date(year, month - 1, day, 12);

  return (date.length >= 10) ? dateObject : null;
};

export const reverseDateStringToDate = (date = '') => {
  const year = Number.parseInt(date.substr(6, 4), 10);
  const month = Number.parseInt(date.substr(3, 2), 10);
  const day = Number.parseInt(date.substr(0, 2), 10);

  return (date.length >= 10) ? new Date(year, month - 1, day) : null;
};

export const readableDateDifference = (date1, date2) => {
  const timeDiff = Math.abs(date1.getTime() - date2.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  const bigger = date1.getTime() > date2.getTime() ? '+' : '-';

  let difference = `${bigger}${diffDays} days`;
  difference = bigger !== 1 ? difference : `${bigger}${diffDays} day`;

  return difference;
};

export const newDateBySubstractYear = (year) => {
  let date = new Date();            
  date.setFullYear(date.getFullYear() - year);
  return date;
}

export const stringToDate = (date, format, delimiter) => {
  const formatLowerCase = format.toLowerCase();
  const formatItems = formatLowerCase.split(delimiter);
  const dateItems = date.split(delimiter);
  const monthIndex = formatItems.indexOf("mm");
  const dayIndex = formatItems.indexOf("dd");
  const yearIndex = formatItems.indexOf("yyyy");
  let month = parseInt(dateItems[monthIndex]);
  month -= 1;
  const formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
  return formatedDate;
}
