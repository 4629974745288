
import { settings } from '../config/settings';

const createSource = async(stripe, type, firstName, lastName, email) => {
  return await stripe.createSource({
    type,
    'amount': settings.premium_price,
    'currency': 'eur',
    owner: {
      name: `${firstName} ${lastName}`,
      email,
    },
    redirect: {
      return_url: `${window.location.origin}${window.location.pathname}`
    }
  });
};

export const createIdealSource = async(stripe, firstName, lastName, email) => {
  return await createSource(stripe, 'ideal', firstName, lastName, email)
};

export const createBancontactSource = async(stripe, firstName, lastName, email) => {
  return await createSource(stripe, 'bancontact', firstName, lastName, email)
};


export const handleCardSetup = async(stripeJs, clientSecret, fullName, email) => {
  return new Promise((resolve, reject) => {
    stripeJs.handleCardSetup(clientSecret, {
      payment_method_data: {
        billing_details: {
          name: fullName,
          email
        }
      }
    })
    .then(({setupIntent, error}) => {
      if (setupIntent && setupIntent.payment_method) {
        resolve({setupIntent})
      } else {
        if (error) {
          reject(error)
        } else {
          reject(new Error('FATAL: Card setup error'))
        }
      }
    })
    .catch(e => {
      reject(e)
    })
  });
}

export const handleCardPayment = async(stripeJs, paymentIntent) => {
  return new Promise((resolve, reject) => {
    stripeJs.handleCardPayment(paymentIntent)
    .then(({paymentIntent, error}) => {
      if (paymentIntent) {
        resolve({paymentIntent})
      } else {
        if (error) {
          reject(error)
        } else {
          reject(new Error('FATAL: Card payment auth error'))
        }
      }
    })
    .catch(e => {
      reject(e)
    })
  });
}
