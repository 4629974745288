import {
  AUDIO_STATUS_CHANGED,
  AUDIO_PLAYBACK_OBJECT_CHANGED,
  AUDIO_RECORDING_OBJECT_CHANGED,
} from './types';

export const audioStatusChanged = (status) => {
  return {
    type: AUDIO_STATUS_CHANGED,
    payload: status,
  };
};

export const playbackSoundObjectChanged = (playbackSoundObject, playbackSourceUrl) => {
  return {
    type: AUDIO_PLAYBACK_OBJECT_CHANGED,
    payload: { playbackSoundObject, playbackSourceUrl }
  };
};

export const recordingSoundObjectChanged = (recordingSoundObject) => {
  return {
    type: AUDIO_RECORDING_OBJECT_CHANGED,
    payload: recordingSoundObject
  };
};
