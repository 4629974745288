import {
  NEW_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from './types';


export const newNotification = notification => {
  return {
      type: NEW_NOTIFICATION,
      payload: notification
  };
};

export const removeNotification = notification => {
  return {
      type: REMOVE_NOTIFICATION,
      payload: notification
  };
};
