import React from 'react';

import './styles.css';

const TabSingle = ({ title, onPress, active, leftBar, rightBar }) => {
    return (
        <button className="TabSingleButton" onClick={onPress}>
            <div className="TabSingleContainer">
                <div className={`${leftBar? 'TabSingleVerticalBar TabSingleVerticalLeft' : ''}`}/>

                <p className={`TabSingleTitle ${active ? 'TabSingleTitleActive' : ''}`}>
                    {title}
                </p>

                <div className={`${rightBar? 'TabSingleVerticalBar TabSingleVerticalRight' : ''}`}/>
            </div>
        </button>
    );
};

export default TabSingle;
