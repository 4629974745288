import React from 'react';

import './styles.css';
import ButtonPurple from '../ButtonPurple';
import ButtonGrey from '../ButtonGrey';

const OverlayMatch = ({ hideActivateButton = false, profilePicture, matchPicture, matchName = '', onActivatePress, onClose }) => {
    let matchText = `You and ${matchName} are a match!`;
    if (hideActivateButton) {
        matchText = `${matchText}\nAn exclusive chat already exists.`;
    }

    return (
        <div className="ObverlayMatchContainer" id="match-container">
            <div className="OverlayMatchBlur" id="match-blur-view"/>
            <div className="ObverlayMatchContent">
              <p className="ObverlayMatchTitle">{'Match'}</p>

              <div className="ObverlayMatchCircles">
                  <img src={matchPicture} className="ObverlayMatchPerson ObverlayMatchMatchPerson" alt="matchPicture"/>
                  <img src={profilePicture} className="ObverlayMatchPerson ObverlayMatchFirstPerson" alt="profilePicture"/>
              </div>

              <p className="ObverlayMatchMatchText">{matchText}</p>

              {
                  hideActivateButton ? (
                      false
                  ) : (
                      <ButtonPurple
                          label={'Chat exclusive'}
                          onPress={onActivatePress}
                      />
                  )
              }
              <div className="ObverlayMatchLaterButton">
                <ButtonGrey
                    label={'Close'}
                    onPress={onClose}
                    small
                    noPadding
                    width={150}
                />
              </div>
            </div>
      </div>
    );
};

export default OverlayMatch;
