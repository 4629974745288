import {
  RESET_REDUCERS,
  SIGN_UP_USERNAME_CHANGED,
  SIGN_UP_PASSWORD_CHANGED,
  SIGN_UP_NAME_CHANGED,
  SIGN_UP_GENDER_CHANGED,
  SIGN_UP_ACCEPT_TC_CHANGED,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
  SIGN_UP_BIRTHDATE_CHANGED,
} from '../actions/types';

const INITIAL_STATE = {
  loadingMessage: '',
  username: '',
  password: '',
  name: '',
  gender: '',
  acceptedTerms: false,
  error: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case RESET_REDUCERS:
          return {
              ...INITIAL_STATE,
          };

      case SIGN_UP_USERNAME_CHANGED:
          return {
              ...state,
              username: action.payload
          };

      case SIGN_UP_PASSWORD_CHANGED:
          return {
              ...state,
              password: action.payload
          };

      case SIGN_UP_NAME_CHANGED:
          return {
              ...state,
              name: action.payload
          };

      case SIGN_UP_BIRTHDATE_CHANGED:
        return {
              ...state,
              birthdate: action.payload
          };

      case SIGN_UP_GENDER_CHANGED:
          return {
              ...state,
              gender: action.payload
          };

      case SIGN_UP_ACCEPT_TC_CHANGED:
          return {
              ...state,
              acceptedTerms: action.payload
          };

      case SIGN_UP:
          return {
              ...state,
              loadingMessage: 'Trying to sign up'
          };

      case SIGN_UP_SUCCESS:
          return {
              ...state,
              loadingMessage: '',
              error: '',
          };

      case SIGN_UP_FAIL:
          return {
              ...state,
              loadingMessage: '',
              error: action.payload,
          };

      default:
          return state;
  }
};
