import React from 'react';

import './styles.css';
import { Images } from '../../config/images';
import AnimatedProgressBar from '../AnimatedProgressBar';

const ChatInput = ({ text, isRecording = false, onCameraPress, onMicrophonePress, onChangeText, onSend }) => {
  const duration = 60000
  let timer = null

  const startCounter = () => {
    timer = setTimeout(() => {
      onMicrophonePress();
    }, duration)
  }
  return (
      <div className="ChatInputContainer">
        {
            isRecording ? (
              <div className="ChatInputRecorderAnimation">
                <AnimatedProgressBar
                  duration={duration}
                  onLoad={() => {
                    startCounter();
                  }}
                />
              </div>
            ) : (
                <div className="ChatInputInputWrapper">
                    <input
                        type="text"
                        className="ChatInputInput"
                        placeholder={'Type a message...'}
                        value={text}
                        onChange={(event) => onChangeText(event.target.value)}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            onSend()
                          }
                        }}
                    />
                    <button className="ChatInputButton" onClick={onCameraPress}>
                        <img 
                            src={Images.icon.cameraGreen}
                            className="ChatInputInputIcon"
                            alt="camera"
                        />
                    </button>
                </div>
            )
        }

        <button 
          className="ChatInputButton ChatInputLastButton" 
          onClick={() => {
            clearTimeout(timer);
            onMicrophonePress();
        }}>
            <img 
                src={isRecording ? Images.icon.stopGreen : Images.icon.microphoneGreen}
                className="ChatInputInputIcon"
                alt="record"
            />
        </button>
      </div>
    );
  }

export default ChatInput;
