import React from 'react';

import './styles.css';

const LevelBar = ({ text, color, onPress, profileBar }) => (
    <button className={`LevelBarButton ${profileBar ? 'LevelBarProfile' : ''}`} onClick={onPress}>
        <div className={`LevelBarContainer ${profileBar ? 'LevelBarContainerProfile' : ''}`} style={color}>
            <p className="LevelBarText">{text}</p>
        </div>
    </button>
);

export default LevelBar;
