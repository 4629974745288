import React from 'react';

import './styles.css';
import ButtonPurple from '../ButtonPurple';
import ButtonGrey from '../ButtonGrey';

const ProfileFooter = ({ onFilterPress, onSavePress, onCancelPress }) => (
  <div className="ProfileFooterContainer">

    <div className="ProfileFooterSaveCancelContainer">
      <ButtonPurple
        label={'Save'}
        onPress={onSavePress}
        small
      />

      <div className="ProfileFooterCancelButton">
        <ButtonGrey
          label={'Cancel'}
          onPress={onCancelPress}
          small
          noPadding
        />
      </div>
    </div>
  </div>
);

export default ProfileFooter;
