import React from 'react';

import './styles.css';
import Answer from '../Answer';

const Answers = ({ answers, givenAnswer, onPress }) => {
  return answers.map((data, index) => {
    const answerPressed = () => { 
      onPress(data.id);
    };

    return (
      <div className="AnswersContainer" key={index}>
        <Answer
          key={index}
          text={data.answer}
          onPress={answerPressed}
          image={data.image}
          selected={data.id === givenAnswer}
        />
      </div>
    );
  });
};

export default Answers;
