import React from 'react';
import './styles.css';

const Subheading = ({ heading, centered, large, required }) => (
    <div className={`SubheadingContainer ${centered ? 'SubheadingTitleCenter' : ''}`}>
      <p className={`SubheadingTitle
      ${centered ? 'SubheadingTitleCenter' : ''} 
      ${large ? 'SubheadingTitleLarge' : ''}`} 
      >
        {heading}
      </p>
      {required ? <p className="SubheadingTitleRequired">*</p> : null}
    </div>
);

export default Subheading;
