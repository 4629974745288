import React from 'react';

import './styles.css';

const ButtonGrey = ({ label, onPress, border, iconRight, large, small, noPadding, width }) => (
  <div className={`ButtonGreyOuterContainer ${noPadding ? 'ButtonGreyOuterContainerNoPadding' : ''}`}>
    <button 
      onClick={onPress}
      className={
        `ButtonGreyContainer
         ${border ? 'ButtonGreyBorder' : ''}
         ${large ? 'ButtonGreyLargeContainer' : ''} 
         ${small ? 'ButtonGreySmallContainer' : ''}`
      }
      style={{ width }}
    >
      <p className={`ButtonGreyLabel ${large ? 'ButtonGreyLargeLabel' : ''}`}>
          {label}
      </p>

      {iconRight ? <img src={iconRight} className="ButtonGreyIcon" alt="iconRight" /> : false}
    </button>
  </div>
);

export default ButtonGrey;
