export const AuthPaths = {
  login: '/',
  signUp: '/signup',
  lostPassword: '/lost-password'
};

export const AdmissionPaths = {
  admissionStart: '/',
  admissionAbout: '/admission-about',
  admissionQuestion: '/admission-question',
  admissionResult: '/admission-result'
};

export const MainPaths = {
  matches: '/',
  profile: '/profile',
  profileEdit: '/profile/edit',
  chat: '/chat',
  chatHistory: '/chat/history',
  invitations: '/invitations',
  settings: '/settings',
  subscription: '/subscription',
  matchProfile: '/matchesprofile/:id'
};