import React, { Component } from 'react';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import './styles.css';
import ChatBox from '../../components/ChatBox';

export default class ChatList extends Component {
  constructor(props) {
    super(props);
    this.list = undefined;
  }

  resize = () => {
    this.initCellCache();
    this.scrollToEnd();
  }

  initCellCache = () => {
    this.cache = new CellMeasurerCache({
      defaultHeight: 65,
      minHeight: 65,
      fixedWidth: true
    });
  }

  UNSAFE_componentWillMount() {
    this.initCellCache();
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  scrollToEnd = () => {
    const { messages } = this.props.chatData;
    if (this.list && messages && messages.length !== 0) {
      this.list.scrollToRow(messages.length)
    }
  }

  componentDidUpdate() {
    this.scrollToEnd();
  }

  rowRenderer = ({ index, isScrolling, key, parent, style, playing }) => {
    const { chatData, partner, onPressPartnerPP, playbackSourceUrl, onVoicePlayStopPress, openPhoto } = this.props;
    const item = chatData.messages[index];
    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
        isScrolling={isScrolling}
      > 
        <div  style={{
          ...style
        }}>
          <ChatBox
            message={item}
            partnerProfilePic={partner.profile_picture}
            onPressPartnerPP={onPressPartnerPP}
            isFromSelf={partner.user_id === item.receiver_id}
            playing={playing}
            playbackSourceUrl={playbackSourceUrl}
            onVoicePlayStopPress={() => {
              onVoicePlayStopPress(item.voice.url);
            }}
            key={item.id ? `${item.id}` : `sending-${item.sent_timestamp}`}
            openPhoto={openPhoto}
          />
        </div>
      </CellMeasurer>
    );
  }

  render() { 
    const { chatData, playing } = this.props;
    return (
      <div className="ChatListContainer">
        <AutoSizer>
        {
          ({ width, height }) => (
          <List
            ref={(ref) => {
              this.list = ref;
            }}
            width={width}
            height={height}
            rowCount={chatData.messages ? chatData.messages.length : 0}
            rowHeight={this.cache.rowHeight}
            rowRenderer={(props) => this.rowRenderer({...props, playing})}
          />
          )}
          </AutoSizer>
      </div>
    );
  }
}
