import {
  NEW_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from '../actions/types';


const INITIAL_STATE = {
  notifications: [],
  notificationHandler: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case NEW_NOTIFICATION:
          return {
              ...state,
              notifications: [...state.notifications, action.payload],
          };

      case REMOVE_NOTIFICATION:
          return {
              ...state,
              notifications: state.notifications.filter(
                  notification => notification.notificationId !== action.payload.notificationId
              ),
          };
          
      default:
          return state;
  }
};
