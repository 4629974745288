import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { sendFavorite } from '../../actions/MatchProfileActions';
import { reportUserChanged } from '../../actions/ReportActions';
import { matchProfileRequest } from '../../actions/MatchProfileActions';
import AudioManager from '../../helpers/audio';

import './styles.css';
import { Images } from '../../config/images';
import { Colors } from '../../config/colors';
import Header from '../../components/Header';
import ProfileWithLevel from '../../components/ProfileWithLevel';
import Subheading from '../../components/Subheading';
import DreamCircle from '../../components/DreamCircle';
import ProfileMorePhotos from '../../components/ProfileMorePhotos';
import ProfileImage from '../../components/ProfileImage';
import SoundBar from '../../components/SoundBar';
import HeadingWithIcon from '../../components/HeadingWithIcon';
import InspiringPeopleList from '../../components/InspiringPeopleList';
import BackButton from '../../components/BackButton';
import ButtonCirclePurple from '../../components/ButtonCirclePurple';

class MatchProfile extends Component {
  componentDidMount() {
    const { matchProfile } = this.props;
    const { token } = this.props.persist;
    const { history } = this.props;
    const id = history.location.pathname.split("/").pop();

    if ((matchProfile === undefined || matchProfile.id === null) && id !== null) {
      this.props.matchProfileRequest(token, id);
    }
  }

  renderSoundBar = () => {   
    if ('url' in this.props.matchProfile.voice) {
      let { playing } = this.props.audio.status;   
      return (
        <SoundBar
          playing={playing}
          onPress={() => {
              AudioManager.shared().playStopUrl(this.props.matchProfile.voice.url);
          }}
          image={this.props.matchProfile.voice.waveform}
          length={this.props.matchProfile.voice.duration}
        />
      );
    }
  };

  renderLike = () => {
    const { matchProfile } = this.props;
    return (
      <div className="MatchProfileLike">
        <ButtonCirclePurple
          icon={Images.icon.hearthWhite}
          medium
          onPress={() => {
            this.props.sendFavorite(this.props.persist.token, matchProfile.id);
          }}
          altText={`Do you like ${matchProfile.name}?\nSend an invite`}
        />
      </div>
    )
  }

  backHandle = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const { profile } = this.props;
    const { matchProfile } = this.props;
    const levelColor = { backgroundColor: Colors.level[profile.level] };
    return (
      <div className="MenuPage MatchProfileContainer">
        <img className="MatchProfileCorner"
          src={Images.background.cornerCircle}
          alt="cornerCircle"
        />

        <Header shadow/>

        <div className="MatchProfileImage">
          <ProfileWithLevel 
            levelColor={levelColor}
            level={profile.level}
            profilePicture={profile.profileImage}
          />
        </div>

        <div className="MatchProfileEditBackButton">
          <BackButton 
            onPress={() => this.backHandle()}
          />
        </div>
        {matchProfile.id ? 
        <div className="MatchProfileContent">
          <div className="MatchProfileContentRow">
            <div className="MatchProfileBoxLeft">
              <Subheading
                heading={`${matchProfile.name}'s Dream`}
                centered
                large
              />
              <div className="MatchProfileDreamCircle">
                <DreamCircle 
                  photo={matchProfile.dreamImage}
                  text={matchProfile.dream}
                />
              </div>
              <div className="MatchProfileMorePictures">
                <ProfileMorePhotos 
                  photos={matchProfile.photos}
                />
              </div>
            </div>

            <div className="MatchProfileBoxRight">
              { !matchProfile.liked ? this.renderLike() : null }

              <div className="MatchProfileProfileImage">
                <ProfileImage 
                  profilePicture={matchProfile.profileImage}
                />
              </div>
              
              <div className="MatchProfileNameAge">
                <Subheading
                  heading={`${matchProfile.name} · ${matchProfile.age}`}
                  centered
                />
              </div>

              <div className="MatchProfileSoundBar">
              { this.renderSoundBar() }
              </div>

              <div className="MatchProfileHeadingWithIcon">
                <HeadingWithIcon
                  heading={matchProfile.city !== null ? `${matchProfile.city},` : ''}
                  icon={Images.icon.housePurple}
                  subtitle={matchProfile.country || ''}
                />
              </div>

              <div className="MatchProfileHeadingWithIcon">
                <HeadingWithIcon
                  heading={'They inspire me:'}
                  icon={Images.icon.lampBubblePurple}
                />
              </div>

              <div className="MatchProfileMentors">
                <InspiringPeopleList
                  persons={matchProfile.mentors}
                />
              </div>
            </div>
          </div>
        </div>
        : null }
      </div>

    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      profile: store.profile,
      matchProfile: store.matchProfile,
      audio: store.audio,
  };
};

const mapDispatchToProps = {
  sendFavorite,
  reportUserChanged,
  matchProfileRequest
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MatchProfile)
);
