import React, { Component } from 'react';

import './styles.css';
import InvitationTabs from '../InvitationTabs';
import InvitationCard from '../InvitationCard';

export default class InvitationsList extends Component {
  currentListData = () => {
    let data;

    switch (this.props.activeTabIndex) {
      case 1:
        data = this.props.invitations.incoming;
        break;

      case 2:
        data = this.props.invitations.sent;
        break;

      case 3:
        data = this.props.invitations.match;
        break;
    
      default:
        break;
    }
        
    return data;
  }

  renderRow = ({ item }) => (
    <InvitationCard
      invitation={item}
      onPress={() => {
        this.props.invitationPressed(item);
      }}
      key={`row-${item.invitation_id}`}
    />
  ) 

  render() {
    return (
      <div className="InvitationsListContainer">
        <InvitationTabs
          active={this.props.activeTabIndex}
          className="InvitationsListTabs"
          onTabPress={this.props.tabChanged}
        />
        {this.currentListData().map(item => this.renderRow({ item }))}
      </div>
    );
  }
}
