import React from 'react';
import './styles.css';

const FilterHeader = () => (
  <div className="FilterHeader">
    <p className="FilterHeaderText" >{'Filter'}</p>
  </div>
);

export default FilterHeader;
