import {
  RESET_REDUCERS,
  FILTER_FETCH,
  FILTER_FETCH_SUCCESS,
  FILTER_FETCH_FAIL,
  FILTER_CHANGE_AGE,
  FILTER_CHANGE_GENDER,
  FILTER_CHANGE_DISTANCE,
  FILTER_UPDATE,
  FILTER_UPDATE_SUCCESS,
  FILTER_UPDATE_FAIL,
} from '../actions/types';

const INITIAL_STATE = {
  loadingMessage: '',
  gender: [],
  minAge: 18,
  maxAge: 100,
  distance: 400,
  updating: false,
  error: '',
};

export default (state = INITIAL_STATE, action) => {
      switch (action.type) {
          case RESET_REDUCERS:
              return {
                  ...INITIAL_STATE,
              };

          case FILTER_FETCH:
              return {
                  ...state,
                  loadingMessage: action.payload,
              };

          case FILTER_FETCH_SUCCESS:
              return {
                  ...state,
                  loadingMessage: action.payload,
                  gender: action.payload.gender,
                  minAge: action.payload.min_age,
                  maxAge: action.payload.max_age,
                  distance: action.payload.distance,
              };

          case FILTER_FETCH_FAIL:
              return {
                  ...state,
                  ...INITIAL_STATE,
              };

          case FILTER_CHANGE_AGE:
              return {
                  ...state,
                  minAge: action.payload.minAge,
                  maxAge: action.payload.maxAge,
              };

          case FILTER_CHANGE_GENDER:
              return {
                  ...state,
                  gender: action.payload,
              };

          case FILTER_CHANGE_DISTANCE:
              return {
                  ...state,
                  distance: action.payload,
              };

          case FILTER_UPDATE:
              return {
                  ...state,
                  updating: true,
              };

          case FILTER_UPDATE_SUCCESS:
              return {
                  ...state,
                  updating: false,
                  error: '',
              };

          case FILTER_UPDATE_FAIL: {
              return {
                  ...state,
                  updating: false,
                  error: action.payload,
              };
          }

      default:
          return state;
  }
};
