import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AudioManager from '../../helpers/audio';

import { fetchProfileRequest } from '../../actions/ProfileActions';
import { loadMatchesRequest } from '../../actions/MatchesActions';
import { matchProfileRequest } from '../../actions/MatchProfileActions';

import './styles.css';
import { Images } from '../../config/images';
import { Colors } from '../../config/colors';
import { MainPaths } from '../../config/paths';
import { showAnswerAlert } from '../../components/Alert';
import { fetchSubscriptionRequest } from '../../actions/SubscriptionActions';
import Header from '../../components/Header';
import ProfileWithLevel from '../../components/ProfileWithLevel';
import TitleBar from '../../components/TitleBar';
import MatchesList from '../../components/MatchesList';
import Spinner from '../../components/Spinner';

class Matches extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.matchProfile.id !== this.props.matchProfile.id &&
      nextProps.matchProfile.id > 0) {
      this.openMatcheProfile(nextProps.matchProfile.id);
    } else if (nextProps.filter.updating === false && this.props.filter.updating === true) {
      if (nextProps.filter.error.length === 0) {
        //Filters have changed successfully
        this.props.loadMatchesRequest(this.props.persist.token);
      }
    }
    if (nextProps.subscription.premium !== null && nextProps.subscription !== this.props.subscription) {
      this.checkSubscription(nextProps.subscription);
    }
  }

  componentDidMount() {
    const { token } = this.props.persist;
    const { subscription } = this.props;

    this.props.fetchProfileRequest(token);
    const completeProfile = this.checkProfileRequiredFields();
    if (completeProfile === false) {
        showAnswerAlert('Required informations',
            'In order to provide you matches we need the following informations: name, city, country, birthday, voice and at least one photo. ' +
            'Please go to edit profile and provide the missing informations.',
            'Go to Edit Profile',
            'Not now',
            () => { 
                this.openProfileEdit();
            }
        );
    } else {
        this.props.loadMatchesRequest(token);
    }

    if (subscription.premium === null) {
      this.props.fetchSubscriptionRequest(this.props.persist.token);
    } else {
      this.checkSubscription(subscription);
    }
  }

  checkProfileRequiredFields = () => {
    const { name, city, country, birthday, photos, voice } = this.props.profile;

    if (!name || !city || !country || !birthday || !photos.length || !voice.url) {
        return false;
    }

    return true;
  }

  openProfileEdit= () => {
    const { history } = this.props;
    history.push(MainPaths.profileEdit);
  }

  openMatcheProfile = (id) => {
    const { history } = this.props;
    history.push(MainPaths.matchProfile.replace(":id", id));
  }

  checkSubscription = (subscription) => {
    const { history } = this.props;
    if (!subscription.premium) {
      showAnswerAlert('Subscription',
        'Please subscribe to Premium for more matches.',
        "View options",
        "Cancel",
        () => { 
          history.push(MainPaths.subscription);
        }
      );
    }
  }

  renderSpinner = () => {
    if (this.props.matches.loadingMessage.length > 0) {
      return <Spinner status={this.props.matches.loadingMessage} />;
    }
  };

  render() {
    const { profile } = this.props;
    const levelColor = { backgroundColor: Colors.level[profile.level] };
    const profileImage = this.props.profile.profileImage !== null ?
        this.props.profile.profileImage : Images.placeholder.avatar;
    const { playing } = this.props.audio.status;
    const playbackSourceUrl = this.props.audio.playbackSourceUrl;

    return (
      <div>
        <div className="MenuPage MatchesContainer">
          <img className="MatchesCorner"
            src={Images.background.cornerCircle}
            alt="cornerCircle"
          />

          <Header shadow/>

          <div className="MatchesProfileImage">
            <ProfileWithLevel 
              levelColor={levelColor}
              level={profile.level}
              profilePicture={profileImage}
            />
          </div>

          <TitleBar title={'Matches'} smallLeftMargin/>

          <MatchesList
            matches={this.props.matches.matches}
            matchPressed={(id) => {
                this.props.matchProfileRequest(this.props.persist.token, id);
            }}
            reloadMatchesPressed={() => {
                this.props.loadMatchesRequest(this.props.persist.token);
            }}
            playing={playing}
            playbackSourceUrl={playbackSourceUrl}
            onVoicePlayStopPress={(voiceUrl) => {
                AudioManager.shared().playStopUrl(voiceUrl);
            }}
          />

          {this.renderSpinner()}
          
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      matches: store.matches,
      matchProfile: store.matchProfile,
      profile: store.profile,
      filter: store.filter,
      audio: store.audio,
      subscription: store.subscription,
  };
};

const mapDispatchToProps = {
  loadMatchesRequest,
  matchProfileRequest,
  fetchProfileRequest,
  fetchSubscriptionRequest,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Matches)
);
