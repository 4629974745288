import React from 'react';
import DatePicker from 'react-datepicker';

import GenderSelection from '../GenderSelection';
import LoginInput from '../LoginInput';
import ButtonPurple from '../ButtonPurple';
import Checkbox from '../Checkbox';
import { dateStringToDate, newDateBySubstractYear, stringToDate } from '../../helpers/date';
import { Images } from '../../config/images';
import './styles.css';
import styles from './styles';

const SignUpForm = ({
  gender, genderChanged,
  name, nameChangeText,
  email, emailChangeText,
  password, passwordChangeText,
  acceptedTerms, acceptedTermsChanged,
  birthdate, onBirthdateChanged,
  onPress, error }) => (

  <div>
      { error ? (
          <p className="SignUpSentence SignUpError">{error}</p>
      ) : (
          <p className="SignUpSentence">{'Sign up by filling in the form below'}</p>
      )}

      <div className="SignUpInputs">
        <GenderSelection
          male={gender === 'male'}
          female={gender === 'female'}
          onPressMale={() => {
              genderChanged('male');
          }}
          onPressFemale={() => {
              genderChanged('female');
          }}
        />

        <LoginInput
          style={styles.inputStyle}
          value={name}
          onChangeText={nameChangeText}
          placeholder={'Name'}
        />

        <div className="SignUpFormInputContainer">
          <img
            className="SignUpFormDateIcon"
            src={Images.icon.calendarGreen}
            alt="Calendar"
          />
          <div className="SignUpFormDateContainer">
            <DatePicker
              className="SignUpFormDatePicker"
              selected={birthdate !== null ? dateStringToDate(birthdate) : null}
              onChange={onBirthdateChanged}
              onChangeRaw={(event) => {
               const dateString = event.target.value
               const date = stringToDate(dateString, 'dd-MM-yyyy', '-')
               if (date) {
                onBirthdateChanged(date)
               }
              }}
              dateFormat={'dd-MM-yyyy'}
              placeholderText={'Birthdate'}
              maxDate={newDateBySubstractYear(18)}
              dateFormatCalendar="MMMM"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
            />
          </div>
        </div>

        <LoginInput
          style={styles.inputStyle}
          value={email}
          onChangeText={emailChangeText}
          placeholder={'Email'}
        />

        <LoginInput
          style={styles.inputStyle}
          value={password}
          onChangeText={passwordChangeText}
          placeholder={'Password'}
          secureTextEntry
        />

        <div className="SignUpCheckboxContainer">
          <Checkbox
              state={acceptedTerms}
              onStateChange={() => {
                  acceptedTermsChanged(!acceptedTerms);
              }}
          />
          <div className="SignUpFormTermContainer">
              <p
                className="SignUpFormText"
              >
                {'I agree to the '}
              </p>
              <p
                className="SignUpFormText SignUpFormTextUnderline"
                onClick={() =>
                  window.open('https://attraction.dating/terms-conditions/', '_blank')
                }
              >
                {'Terms and Conditions.'}
              </p>
          </div>
        </div>
      </div>

      <ButtonPurple
        style={styles.signUpButtonStyle}
        label={'Sign Up'}
        onPress={onPress}
        disabled={!acceptedTerms}
      />
  </div>
);

export default SignUpForm;
