import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { admissionCompleted } from '../../actions/ProfileActions';

import './styles.css'
import Header from '../../components/Header';
import LevelCircles from '../../components/LevelCircles';
import LevelExplanation from '../../components/LevelExplanation';
import ButtonPurple from '../../components/ButtonPurple'; 

import { Images } from '../../config/images';
import { Colors } from '../../config/colors';
import { LevelExplanations } from '../../config/levels';
import { Levels } from '../../config/levels';
import { MainPaths } from '../../config/paths';

class AdmissionResult extends Component {
  goToProfileEdit = () => {
    this.props.admissionCompleted();
    const { history } = this.props;
    history.replace(MainPaths.profileEdit);
  }

  render() {
    const { profile } = this.props;
    const levelColor = { backgroundColor: Colors.level[profile.level] };
    const explanation = LevelExplanations[profile.level];

    return (
      <div className="AdmissionResultContainer">
        <img className="AdmissionResultCorner"
          src={Images.background.cornerCircle}
          alt="cornerCircle"
        />
        <Header text={'Consciousness level check'}/>

        <p className="AdmissionResultLevelText">
          {`Wow, ${profile.name}! Based on your answers, you're on energy level ${profile.level}!`}
        </p>

        <div className="AdmissionResultDetails">
          <LevelCircles
            levelColor={levelColor}
            level={profile.level}
            profilePicture={profile.profileImage}
          />
          <LevelExplanation
            title={`Level ${profile.level} - ${Levels[profile.level]}`}
            text={explanation}
          />
        </div>

        <div className="AdmissionResultButton">
          <ButtonPurple
            label={'Fill up your profile'}
            onPress={() => { 
              this.goToProfileEdit(); 
            }}
            arrowRight
            large
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    persist: store.persist,
    profile: store.profile
  };
};

const mapDispatchToProps = {
  admissionCompleted
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdmissionResult)
);
