import React from 'react';

import ButtonRed from '../ButtonRed';
// import ButtonPrimary from '../ButtonPrimary';

import { dateToReadableDateSlash, dateStringToDate } from '../../helpers/date';

const SubscriptionStatusBar= ({text, children}) => {
  return (
    <div className="SubscriptionActive">
      <div className="SubscriptionActiveStatus">
        <div className="SubscriptionActiveCircle"/>
        <p className="SubscriptionActiveText">
          {text}
        </p>
      </div>
      <div className="SubscriptionActiveCancel">
        {children}
      </div>
    </div>
  );
}

// Used for active recurring subscription status
const SubscriptionActiveRecurringStatusBar = ({ end, onCancel }) => {
  let endFormated = ''
  if (end) {
    endFormated = dateToReadableDateSlash(dateStringToDate(end))
  }
  return (
    <SubscriptionStatusBar
      text={`Premium Subscription is active until: ${endFormated}`}>
      <ButtonRed
        label={'Cancel'}
        onPress={() => {onCancel(endFormated)}}
        small
      />
    </SubscriptionStatusBar>
  )
}

// Used for active recurring subscription status when is is still on trial
const SubscriptionActiveTrialRecurringStatusBar = ({ end, onCancel }) => {
  let endFormated = ''
  if (end) {
    endFormated = dateToReadableDateSlash(dateStringToDate(end))
  }
  return (
    <SubscriptionStatusBar
      text={`Your are still in trial and will be billed on: ${endFormated}`}>
      <ButtonRed
        label={'Cancel'}
        onPress={() => {onCancel(endFormated)}}
        small
      />
    </SubscriptionStatusBar>
  )
}

// Used for active non-recurring subscription
const SubscriptionSingleStatusBar = ({ end }) => {
  let endFormated = ''
  if (end) {
    endFormated = dateToReadableDateSlash(dateStringToDate(end))
  }
  return (
    <SubscriptionStatusBar
      text={`Premium Subscription is active until: ${endFormated}`}>
    </SubscriptionStatusBar>
  )
}

// Used for pending subscription status
const SubscriptionPendingStatusBar = () => {
  return (
    <SubscriptionStatusBar
      text='Please wait, your transaction is being processed'>
    </SubscriptionStatusBar>
  )
}

// Used for trial subscription status
const SubscriptionTrialStatusBar = ({end}) => {
  let endFormated = ''
  if (end) {
    endFormated = dateToReadableDateSlash(dateStringToDate(end))
  }
  return (
    <SubscriptionStatusBar
      text={`Your trial ends on: ${endFormated}`}>
    </SubscriptionStatusBar>
  )
}

// Used for trial subscription status
const SubscriptionGraceStatusBar = ({end, onResume}) => {
  let endFormated = ''
  if (end) {
    endFormated = dateToReadableDateSlash(dateStringToDate(end))
  }
  return (
    <SubscriptionStatusBar
      text={`Your subscription will end on: ${endFormated}`}>
      {/* <ButtonPrimary
        label={'Resume'}
        onPress={() => {onResume()}}
        small
      /> */}
    </SubscriptionStatusBar>
  )
}

export  {
  SubscriptionActiveRecurringStatusBar,
  SubscriptionPendingStatusBar,
  SubscriptionSingleStatusBar,
  SubscriptionTrialStatusBar,
  SubscriptionGraceStatusBar,
  SubscriptionActiveTrialRecurringStatusBar
};
