import React, { Component } from 'react';

import styles from './styles';
import './styles.css';
import { dateToReadableDateTime } from '../../helpers/date';

export default class ChatHistoryCard extends Component {
  renderStatus = () => {
    const { timestamp, status } = this.props.user;
    let text; let style;

    switch (status) {
      case 'closed':
        text = 'Closed';
        style = styles.closed;
        break;
      
      case 'new_message':
        text = 'New message';
        style = styles.newMessage;
        break;

      case 'active':
        text = 'Active';
        style = styles.active;
        break;
    
      default:
        break;
    }

    return (
      <div className="ChatHistoryCardContentRow">
        <p className="ChatHistoryCardStatus" style={style}>{text}</p>
        <p className="ChatHistoryCardDate">{dateToReadableDateTime(new Date(timestamp * 1000))}</p>
      </div>
    );
   }

  render() {
    const { user, onPress } = this.props;
    
    return (
      <button className="ChatHistoryCardButton" onClick={onPress}>
        <div className="ChatHistoryCardContainer">
          <img src={user.profile_picture} className="ChatHistoryCardProfilePicture" alt="profilePicture" />

          <div className="ChatHistoryCardContent">
            <p className="ChatHistoryCardUserInfo">{`${user.name} · ${user.age}`}</p>
            {this.renderStatus()}
          </div>
        </div>
      </button>
    );
  }
}
