import React, { Component } from 'react';

import './styles.css';

class PhotoCircle extends Component {
  photo = this.props.photo;
  shouldComponentUpdate(nextProps) {
    if (this.photo === nextProps.photo) {
      return false;
    } else {
      this.photo = nextProps.photo;
      return true;
    }
  }
  render() {
    const { photo, onPress, icon, isEmpty, isWhite, fileChooser, isDream, index } = this.props    
    return (
    <div className="PhotoCircleContainer" id={`photo-circle-${index+1}`}>
        {index === 0 ? <p className="PhotoCircleRequired">*</p> : null}
        {!isEmpty ? (
          <div className="PhotoCircleAroundPhoto">
            <img 
              src={photo}
              className="PhotoCircleImage"
              alt="photoImage"
              draggable="false"
              id={`img-circle-${index+1}`}
            />
            {fileChooser ? (
              <div className={`PhotoCircleButton ${isWhite ? 'PhotoCircleButtonWhite' : ''} ${isDream ? 'PhotoCircleDream':''}`}>
                <img className="PhotoCircleButtonImage" src={icon} alt="icon"/>
                <input
                  className="PhotoCircleInput"
                  type="file" 
                  accept="image/jpeg, image/png"
                  onChange={(event) => onPress(event.target.files[0])}
                />
              </div>
            ) : (
              <button 
              className={`PhotoCircleButton ${isWhite ? 'PhotoCircleButtonWhite' : ''}`}
                onClick={onPress}
              >
                <img className="PhotoCircleButtonImage" src={icon} alt="icon"/>
              </button>
            )}
          </div>
        ) : (
          <div className="PhotoCircleAroundPhoto">
            <div className="PhotoCircleDashedBorder" />
            <div className={`PhotoCircleButton ${isWhite ? 'PhotoCircleButtonWhite' : ''}`}>
              <img className="PhotoCircleButtonImage" src={icon} alt="icon"/>
              <input
                className="PhotoCircleInput"
                type="file" 
                accept="image/jpeg, image/png"
                onChange={(event) => onPress(event.target.files[0])}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PhotoCircle;
