import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { matchProfileRequest } from '../../actions/MatchProfileActions';
import { newMatch } from '../../actions/MatchesActions';
import { fetchInvitations, changeInvitationTab } from '../../actions/InvitationActions';
import { setCurrentChatUser } from '../../actions/ChatActions';

import './styles.css';
import { Images } from '../../config/images';
import { Colors } from '../../config/colors';
import { MainPaths } from '../../config/paths';
import Header from '../../components/Header';
import ProfileWithLevel from '../../components/ProfileWithLevel';
import TitleBar from '../../components/TitleBar';
import InvitationsList from '../../components/InvitationsList';

class Invitations extends Component {
  componentDidMount() {
    this.props.fetchInvitations(this.props.persist.token);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.matchProfile.id !== this.props.matchProfile.id &&
      nextProps.matchProfile.id > 0) {
      this.openMatcheProfile(nextProps.matchProfile.id);
    }
  }

  openMatcheProfile = (id) => {
    const { history } = this.props;
    history.push(MainPaths.matchProfile.replace(":id", id));
  }

  invitationPressed = (invitation) => {
    switch (invitation.status) {
        case 'available':
        case 'new_message':
            this.props.newMatch(invitation);
            break;

        case 'active':
        case 'pending':
        case 'unavailable':
            this.props.matchProfileRequest(this.props.persist.token, invitation.user_id);
            break;
    
        default:
            break;
    }
  }

  render() {
    const { invitations } = this.props;
    const { profile } = this.props;
    const profileImage = this.props.profile.profileImage !== null ?
        this.props.profile.profileImage : Images.placeholder.avatar;
    const levelColor = { backgroundColor: Colors.level[profile.level] };

    return (
      <div className="MenuPage InvitationsContainer">
        <img className="InvitationsCorner"
          src={Images.background.cornerCircle}
          alt="cornerCircle"
        />
        <Header shadow/>
        <div className="InvitationsProfileImage">
            <ProfileWithLevel 
              levelColor={levelColor}
              level={profile.level}
              profilePicture={profileImage}
            />
        </div>

        <TitleBar title={'Invitations'}/>

        <InvitationsList 
          invitations={invitations}
          invitationPressed={this.invitationPressed}
          tabChanged={(tabIndex) => this.props.changeInvitationTab(tabIndex)}
          activeTabIndex={invitations.activeTabIndex}
        />

      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      profile: store.profile,
      invitations: store.invitations,
      matchProfile: store.matchProfile,
      chat: store.chat
  };
};

const mapDispatchToProps = {
  newMatch,
  matchProfileRequest,
  fetchInvitations,
  changeInvitationTab,
  setCurrentChatUser
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Invitations)
);
