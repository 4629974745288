import React from 'react';

import './styles.css';

const Checkbox = ({ title = null, state, onStateChange }) => (
    <div
      className="CheckBoxContainer"
      onClick={() => { onStateChange((state !== true)); }}
    >
      <div className="CheckboxAroundState">
          <div className={`CheckboxState ${state ? 'CheckboxActive' : ''}`}/>
      </div>
      {
          title ? (
              <p className="CheckboxText">{title}</p>
          ) : (
              null
          )
      }
    </div>
);

export default Checkbox;
