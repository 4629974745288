import React from 'react';

import './styles.css';

const LevelExplanation = ({ title, text }) => (
    <div className="LevelExplanationContainer">
        <p className="LevelExplanationTitle">{title}</p>
        <p className="LevelExplanationText">{text}</p>
    </div>
);

export default LevelExplanation;
