import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { settings } from '../../config/settings';

import { Images } from '../../config/images';
import { fetchSubscriptionRequest, subscriptionCancel, showGoPremium, updateTimerChanged } from '../../actions/SubscriptionActions';
import { showAnswerAlert } from '../../components/Alert';

import Header from '../../components/Header';
import TitleBar from '../../components/TitleBar';
import SubscriptionCard from '../../components/SubscriptionCard';
import subscriptionStatusTypes from '../../config/subscriptionStatusTypes';
import {
  SubscriptionActiveRecurringStatusBar,
  SubscriptionSingleStatusBar,
  SubscriptionTrialStatusBar,
  SubscriptionPendingStatusBar,
  SubscriptionGraceStatusBar,
  SubscriptionActiveTrialRecurringStatusBar
} from '../../components/SubscriptionStatusBar'

import './styles.css';

class Subscription extends Component {
  componentDidMount() {
    this.props.fetchSubscriptionRequest(this.props.persist.token);
  }

  subscriptionStatusRefresher(nextProps) {
    const { type } = nextProps.subscription;

    if (type === subscriptionStatusTypes.pending && !nextProps.subscription.subscriptionUpdateTimer) {
      const timer = setInterval(() => this.props.fetchSubscriptionRequest(this.props.persist.token), 5000 )
      this.props.updateTimerChanged(timer)
    }

    if (type !== subscriptionStatusTypes.pending && nextProps.subscription.subscriptionUpdateTimer) {
      clearInterval(this.props.subscription.subscriptionUpdateTimer)
      this.props.updateTimerChanged(null)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
   this.subscriptionStatusRefresher(nextProps)
  }

  onCancelSubscription(end) {
    showAnswerAlert('Cancel subscription',
      `Do you want to cancel your subscription? Your premium options will still be valid until: ${end}`,
      'Yes',
      'No',
      () => {
        this.props.subscriptionCancel(this.props.persist.token)
      }
    );
  }

  render () {
    const { type, premium, end } = this.props.subscription;
    const premiumPrice = settings.premium_price / 100
    let status = ''
    if (premium) {
      status = type
    } else if (premium === false) {
      status = subscriptionStatusTypes.inactive
    }

    return (
      <div className="MenuPage SubscriptionContainer">
        <img className="SubscriptionCorner"
          src={Images.background.cornerCircle}
          alt="cornerCircle"
        />
        <Header shadow/>

        <TitleBar title={'Your subscription'}/>

        {type === subscriptionStatusTypes.premium &&
          <SubscriptionActiveRecurringStatusBar
            onCancel={(endFormated) => this.onCancelSubscription(endFormated)}
            end={end}/>
        }
        {(type === subscriptionStatusTypes.premium_trial) &&
          <SubscriptionActiveTrialRecurringStatusBar
            onCancel={(endFormated) => this.onCancelSubscription(endFormated)}
            end={end}/>
        }
        {type === subscriptionStatusTypes.premium_single &&
          <SubscriptionSingleStatusBar
            end={end}/>
        }
        {type === subscriptionStatusTypes.trial &&
          <SubscriptionTrialStatusBar
            end={end}/>
        }
        {(type === subscriptionStatusTypes.pending || type === subscriptionStatusTypes.processing) &&
          <SubscriptionPendingStatusBar />
        }
        {type === subscriptionStatusTypes.grace &&
          <SubscriptionGraceStatusBar
            end={end}
            onResume={() => {}}/>
        }

        <div className="SubscriptionContent">
          <p className="SubscriptionDescription">{'Choose the subscription for more options'}</p>
          <div className="SubscriptionContentCards">
            <SubscriptionCard
              title={'PREMIUM'}
              price={premiumPrice}
              interval={'Monthly'}
              descriptions={['Daily matches', 'Single-focused attention chats']}
              status={status}
              onGoPremium={() => this.props.showGoPremium()}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      subscription: store.subscription,
  };
};

const mapDispatchToProps = {
  fetchSubscriptionRequest,
  subscriptionCancel,
  showGoPremium,
  updateTimerChanged
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Subscription)
);
