import {
  AUTH_USERNAME_CHANGED,
  AUTH_PASSWORD_CHANGED,
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
  LOST_PASSWORD_EMAIL_CHANGED,
  LOST_PASSWORD_SUBMIT,
  LOST_PASSWORD_SUBMIT_SUCCESS,
  LOST_PASSWORD_SUBMIT_FAIL
} from './types';
import { Api } from '../config/api';
import { guestPost } from '../helpers/api';

export const loginUser = (username, password) => {
  return dispatch => {
      dispatch({ type: AUTH_LOGIN });

      guestPost(Api.requests.login, {
          grant_type: Api.grantType,
          client_id: Api.clientId,
          client_secret: Api.clientSecret,
          username,
          password
      }).then(response => {
          dispatch({
              type: AUTH_LOGIN_SUCCESS,
              payload: response.data.access_token,
          });
      }).catch(e => {
          if (e.response && e.response.data && e.response.data.message) {
              dispatch({
                  type: AUTH_LOGIN_FAIL,
                  payload: e.response.data.message,
              });
          } else {
              dispatch({
                  type: AUTH_LOGIN_FAIL,
                  payload: 'Something went wrong.'
               });
          }
      });
  };
};

export const usernameChanged = (text) => {
  return {
      type: AUTH_USERNAME_CHANGED,
      payload: text
  };
};

export const passwordChanged = (text) => {
  return {
      type: AUTH_PASSWORD_CHANGED,
      payload: text
  };
};

// LOST PASSWORD

export const lostPasswordEmailChanged = (text) => {
  return {
      type: LOST_PASSWORD_EMAIL_CHANGED,
      payload: text
  };
};

export const submitLostPassword = (email) => {
  return dispatch => {
      dispatch({ type: LOST_PASSWORD_SUBMIT });

      guestPost(Api.requests.lostPassword, {
          grant_type: Api.grantType,
          client_id: Api.clientId,
          client_secret: Api.clientSecret,
          email
      }).then(() => {
          dispatch({ type: LOST_PASSWORD_SUBMIT_SUCCESS });
      }).catch(e => {
          dispatch({
              type: LOST_PASSWORD_SUBMIT_FAIL,
              payload: 'Invalid e-mail address.',
          });
      });
  };
};
