import React from 'react';

import './styles.css';
import { Images } from '../../config/images';
import { isSafari, Capitalize } from '../../helpers/utils';

const ProfileSelectInput = (
    {
        value, values, onChangeText, placeholder, required, onDelete = undefined, safariPlaceholderIndent, safariOptionsIndent
    }) => (
    <div className="ProfileSelectInputContainer">
        <div className="ProfileSelectInputInnerContainer">
            <select
              value ={value ? value: ''}
              // fix for safari option center
              style={isSafari() ? ( value ? { textIndent: safariOptionsIndent[values.indexOf(value)] } : { textIndent: safariPlaceholderIndent }) : null}
              className={`ProfileSelectInputSelector ${!value ? 'ProfileSelectInputPlaceholder' : ''}`}
              onChange={(event) => {
                const value = event.target.value;
                if (value !== placeholder) {
                  onChangeText(event.target.value)
                }
              }}
            >
              <option value={null} key={placeholder} hidden>{placeholder}</option>
              {values.map((value, index) => <option value={value} key={index}>{Capitalize(value)}</option>)}
            </select>
            <div className="ProfileSelectInputTriangle"></div>
            {
                onDelete !== undefined ? (
                    <button onClick={onDelete} className="ProfileSelectInputDeleteButton">
                        <img src={Images.icon.trashBinPurple} className="ProfileSelectInputDeleteIcon" alt="Delete" />
                    </button>
                ) : (
                    null
                )
            }
        </div>
        {required ? <p className="ProfileSelectInputRequired">*</p> : null}
    </div>
);

export default ProfileSelectInput;
