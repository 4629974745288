import React, { Component } from 'react';

import './styles.css';
import styles from './styles';
import ChatBoxText from '../ChatBoxText';
import ChatBoxPhoto from '../ChatBoxPhoto';
import ChatBoxVoice from '../ChatBoxVoice';

class ChatBox extends Component {
    render() {
        const { message, partnerProfilePic, onPressPartnerPP, isFromSelf, playing, playbackSourceUrl, onVoicePlayStopPress, openPhoto } = this.props;
        const chatBoxAlign = (isFromSelf) ? { justifyContent: 'flex-end', alignSelf: 'flex-end' } : null;
        const chatBoxColor = (isFromSelf) ? styles.ColorSelf : styles.ColorOther;
        const profileImage = (isFromSelf) ? (
            false
         ) : (
            <button className="ChatBoxProfileImageButton" onClick={onPressPartnerPP}>
                <img className="ChatBoxProfileImage" src={partnerProfilePic} alt="partnerProfile"/>
            </button>
         );
         
         if (message.photo && message.photo.length !== 0) {
            return (
                <ChatBoxPhoto 
                    style={chatBoxAlign}
                    photo={message.photo}
                    profileImage={profileImage}
                    openPhoto={openPhoto}
                />
            );
        } else if (message.voice && message.voice.url) {
            let isPlaying = playing;
            if (playbackSourceUrl !== message.voice.url) {
                isPlaying = false;
            }
            return (
                <ChatBoxVoice 
                    style={chatBoxAlign}
                    boxStyle={chatBoxColor}
                    voice={message.voice}
                    isPlaying={isPlaying}
                    timestamp={message.timestamp}
                    profileImage={profileImage}
                    onPlayStopPress={onVoicePlayStopPress}
                    isFromSelf={isFromSelf}
                />
            );
        }
    
        return (
            <ChatBoxText
                style={chatBoxAlign}
                boxStyle={chatBoxColor}
                text={message.text}
                timestamp={message.timestamp}
                profileImage={profileImage}
                isFromSelf={isFromSelf}
            />
        );
    }
}

export default ChatBox;
