export const Colors = {
  black: {
      default: '#030303',
      absolute: '#000000',
      transparent: 'rgba(0,0,0,0.5)',
  },

  white: {
      default: '#F6FBF8',
      absolute: '#FFFFFF',
      transparent: 'rgba(255, 255, 255, 0.9)'
  },

  grey: {
      ultralight: '#F3F8FD',
      lighter: '#EEEEEE',
      light: '#CCCCCC',
      medium: '#979797',
      dark: '#707070',
      transparent: 'rgba(0, 0, 0, 0.07)',
  },

  primary: {
      default: '#0FD2AD',
      alternative: '#50E3C2',
  },

  secondary: {
      default: '#261B4B',
      alternative: '#6A4DD1',
  },

  tertiary: {
      default: '#F701F1',
      alternative: '#FB0C57',
  },

  level: {
      1: '#F701F1',
      2: '#F506B4',
      3: '#F40C7A',
      4: '#F31144',
      5: '#F11C17',
      6: '#F0561C',
      7: '#EF8D22',
      8: '#EDC127',
      9: '#E7EC2C',
      10: '#B8EB32',
      11: '#8BE937',
      12: '#62E83C',
      13: '#41E645',
      14: '#46E572',
      15: '#4BE49B',
      16: '#50E3C2',
  },

  status: {
      selected: '#15D76D',
      success: '#0FD2AD',
      danger: '#FB0C57',
      warning: '#6A4DD1',
      info: '#0E75FB',
      available: '#15D76D',
      unavailable: '#FB0C57',
      active: '#6A4DD1',
      pending: '#CCCCCC',
      newMessage: '#15D76D',
      closed: '#CCCCCC'
  }
};
