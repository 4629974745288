import { Colors } from '../../config/colors';

const styles = {
  textSelf: {
    color: Colors.white.default,
  },
  textOther: {
    color: Colors.grey.dark,
  }
}

export default styles;
