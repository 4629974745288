import React, { Component } from 'react';
import { connect } from 'react-redux';

import { matchProfileRequest } from '../../actions/MatchProfileActions';
import { closeInvitation } from '../../actions/InvitationActions';
import { blurToElementWithId } from '../../helpers/blur';

import './styles.css';
import { Images } from '../../config/images';
import LevelCircles from '../../components/LevelCircles';
import ButtonPurple from '../../components/ButtonPurple';
import ButtonGrey from '../../components/ButtonGrey';

class InvitationModal extends Component {
  componentDidMount() {
    blurToElementWithId('invitation-blur-view', () => {
      document.getElementById('invitation-container').style.visibility = 'visible';
    });
  }

  render() {
    const { newInvitation } = this.props.invitations;
    
    return (
      <div className="InvitationModalContainer" id="invitation-container">
            <div className="InvitationModalBlur" id="invitation-blur-view"/>
            <div className="InvitationModalContent">
              <p className="InvitationModalTitle">{'Invitation'}</p>

              <div className="InvitationModalProfileContent">
                  <img src={Images.icon.heartPurple} className="InvitationModalHeart" alt="heart"/>
                  <LevelCircles 
                    profilePicture={newInvitation.profile_picture}
                    noBar
                  />
              </div>

              <p className="InvitationModalText">{`${newInvitation.name} likes your energy`}</p>

              <ButtonPurple
                  label={'Go to profile'}
                  onPress={() => {
                    this.props.closeInvitation();
                    this.props.matchProfileRequest(this.props.persist.token, newInvitation.user_id);
                  }}
              />
              <div className="InvitationModalCloseButton">
                <ButtonGrey
                    label={'Close'}
                    onPress={() => this.props.closeInvitation()}
                    small
                    noPadding
                    width={150}
                />
              </div>
            </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    persist: store.persist,
    invitations: store.invitations,
  };
};

const mapDispatchToProps = {
  matchProfileRequest,
  closeInvitation
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationModal);
