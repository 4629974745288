import {
  ADMISSION_FETCH,
  ADMISSION_FETCH_SUCCESS,
  ADMISSION_FETCH_FAIL,
  ADMISSION_ANSWER_CHANGED,
  ADMISSION_STEP_CHANGED,
  ADMISSION_SUBMIT,
  ADMISSION_SUBMIT_SUCCESS,
  ADMISSION_SUBMIT_FAIL,
  PROFILE_LEVEL_CHANGED,
} from './types';

import { Api } from '../config/api';
import { get, post } from '../helpers/api';

export const changeAnswerForCurrentStep = (answerId) => {
  return {
    type: ADMISSION_ANSWER_CHANGED,
    payload: answerId
  };
};

export const nextStep = () => {
  return {
    type: ADMISSION_STEP_CHANGED,
    payload: 1
  };
};

export const previousStep = () => {
  return {
    type: ADMISSION_STEP_CHANGED,
    payload: -1
  };
};

export const fetchAdmission = (token) => {
  return dispatch => {
    dispatch({ type: ADMISSION_FETCH });

    get(token, Api.requests.admission)
    .then(response => {          
      dispatch({
        type: ADMISSION_FETCH_SUCCESS,
        payload: response.data,
      });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: ADMISSION_FETCH_FAIL });
    });
  };
};

export const submitAdmission = (token, admission) => {
  return dispatch => {
    dispatch({ type: ADMISSION_SUBMIT });

    post(token, Api.requests.admission, admission)
    .then(response => {
      dispatch({ type: ADMISSION_SUBMIT_SUCCESS });
      dispatch({
        type: PROFILE_LEVEL_CHANGED,
        payload: response.data.level
      });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: ADMISSION_SUBMIT_FAIL });
    });
  };
};
