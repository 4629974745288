const styles = {
  inputStyle: {
    marginTop: 15
  },
  signUpButtonStyle: {
    marginTop: 10
  }
};

export default styles;
