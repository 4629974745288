import {
  MATCHES_LOADING,
  MATCHES_LOADING_SUCCESS,
  MATCHES_LOADING_FAIL,
  NEW_MATCH,
  REMOVE_MATCH,
  CLOSE_MATCH
} from './types';
import { Api } from '../config/api';
import { get } from '../helpers/api';

export const removeMatchWithId = (userId) => {
  return {
      type: REMOVE_MATCH,
      payload: userId
  }
}

export const loadMatchesRequest = (token, offset = 0) => {
  return dispatch => {
      dispatch({
          type: MATCHES_LOADING,
          payload: 'Searching for matches',
      });

      get(token, Api.requests.matches(offset)).then(response => {
          dispatch({
              type: MATCHES_LOADING_SUCCESS,
              payload: response.data,
          });
      }).catch((e) => {
          console.log(e);
          dispatch({ type: MATCHES_LOADING_FAIL });
      });
  };
};

export const newMatch = match => {
  return {
      type: NEW_MATCH,
      payload: match
  };
};

export const closeMatch = () => {
  return {
    type: CLOSE_MATCH
  }
}
