import {
  RESET_REDUCERS,
  USER_SET_NOTIFICATION_TOKEN,
  USER_SET_GEO_LOCATION,
} from '../actions/types';

const INITIAL_STATE = {
  notification_token: '',
  location: {
      latitude: null,
      longitude: null,
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case RESET_REDUCERS:
          return {
              ...INITIAL_STATE,
          };

      case USER_SET_NOTIFICATION_TOKEN:
          return {
              ...state,
              notification_token: action.payload
          };

      case USER_SET_GEO_LOCATION:
          return {
              ...state,
              location: {
                  latitude: action.payload.latitude,
                  longitude: action.payload.longitude,
              },
          };

      default:
          return state;
  }
};
