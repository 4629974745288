import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './reducers';
import { BrowserRouter } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';

import { configToasts } from './components/NotificationToast';

import Start from './containers/Start';
import { settings } from './config/settings';

import './App.css';

class App extends Component {
  componentDidMount () {
    configToasts();
  }
  
  render() {
    return (
      <StripeProvider apiKey={settings.stripe_key}>
        <Provider store={store} >
          <PersistGate persistor={persistor}>
            <BrowserRouter>
                <div className="App">
                  <Start />
                </div>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </StripeProvider>
    );
  }
}

export default App;
