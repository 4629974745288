import React from 'react';

import './styles.css';

const PaginationNumbers = ({ current }) => {
    const numbers = [];

    for (var number = 1; number <= current; number++) {
        numbers.push(number);
    }

    return numbers.map((data, index) => {
        const lastItem = index === numbers.length - 1;

        return (
            <div className="PaginationNumbersContainer" key={index}>
                <p className={`PaginationNumbersNumber ${lastItem ? 'PaginationNumbersLastNumber' : ''}`}>{data}</p>
            </div>
        );
    });
};

export default PaginationNumbers;
