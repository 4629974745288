export const RESET_REDUCERS = 'reset_reducers';

export const USER_SET_NOTIFICATION_TOKEN = 'user_set_notification_token';
export const USER_SET_GEO_LOCATION = 'user_set_geo_location';

export const PERSIST_REGISTRATION = 'persist_registration';
export const PERSIST_SET_REGISTRATIONS = 'persist_set_registrations';

export const PERSIST_FETCH_TOKEN = 'fetch_token';
export const PERSIST_USER_ID = 'user_id';
export const SET_DIRECT_LINK = 'direct_link';

export const AUTH_USERNAME_CHANGED = 'auth_username_changed';
export const AUTH_PASSWORD_CHANGED = 'auth_password_changed';
export const AUTH_LOGIN = 'auth_login';
export const AUTH_LOGIN_SUCCESS = 'auth_login_success';
export const AUTH_LOGIN_FAIL = 'auth_login_fail';

export const LOST_PASSWORD_EMAIL_CHANGED = 'lost-password-email-changed';
export const LOST_PASSWORD_SUBMIT = 'lost-password-submit';
export const LOST_PASSWORD_SUBMIT_SUCCESS = 'lost-password-submit-success';
export const LOST_PASSWORD_SUBMIT_FAIL = 'lost-password-submit-fail';

export const SIGN_UP_USERNAME_CHANGED = 'sign_up_username_changed';
export const SIGN_UP_PASSWORD_CHANGED = 'sign_up_password_changed';
export const SIGN_UP_NAME_CHANGED = 'sign_up_name_changed';
export const SIGN_UP_GENDER_CHANGED = 'sign_up_gender_changed';
export const SIGN_UP_ACCEPT_TC_CHANGED = 'sign_up_accept_tc_changed';
export const SIGN_UP_BIRTHDATE_CHANGED = 'sign_up_birthdate_changed';
export const SIGN_UP = 'sign_up';
export const SIGN_UP_SUCCESS = 'sign_up_success';
export const SIGN_UP_FAIL = 'sign_up_fail';

export const ADMISSION_FETCH = 'admission_fetch';
export const ADMISSION_FETCH_SUCCESS = 'admission_fetch_success';
export const ADMISSION_FETCH_FAIL = 'admission_fetch_fail';
export const ADMISSION_ANSWER_CHANGED = 'admission_answer_changed';
export const ADMISSION_STEP_CHANGED = 'admission_step_changed';
export const ADMISSION_SUBMIT = 'admission_submit';
export const ADMISSION_SUBMIT_SUCCESS = 'admission_submit_success';
export const ADMISSION_SUBMIT_FAIL = 'admission_submit_fail';

export const MATCHES_LOADING = 'matches_loading';
export const MATCHES_LOADING_SUCCESS = 'matches_loading_success';
export const MATCHES_LOADING_FAIL = 'matches_loading_fail';
export const NEW_MATCH = 'new_match';
export const REMOVE_MATCH = 'remove_match';
export const CLOSE_MATCH = 'close_match';

export const MATCH_PROFILE_FETCH = 'match_profile_fetch';
export const MATCH_PROFILE_FETCH_SUCCESS = 'match_profile_fetch_success';
export const MATCH_PROFILE_FETCH_FAIL = 'match_profile_fetch_fail';
export const MATCH_PROFILE_FAVORITE = 'match_profile_favorite';
export const MATCH_PROFILE_FAVORITE_SUCCESS = 'match_profile_favorite_success';
export const MATCH_PROFILE_FAVORITE_FAIL = 'match_profile_favorite_fail';
export const MATCH_PROFILE_CLEAR = 'match_profile_clear';

export const INVITATIONS_FETCH = 'invitations_fetch';
export const INVITATIONS_FETCH_SUCCESS = 'invitations_fetch_success';
export const INVITATIONS_FETCH_FAIL = 'invitations_fetch_fail';
export const NEW_INVITATION = 'new_invitation';
export const INVITATIONS_TAB_CHANGED = 'invitations_tab_changed';
export const CLOSE_INVITATION = 'close_invitation';

export const NEW_NOTIFICATION = 'new_notification';
export const REMOVE_NOTIFICATION = 'remove_notification';

export const CHAT_ACTIVATE = 'chat_activate';
export const CHAT_ACTIVATE_SUCCESS = 'chat_activate_success';
export const CHAT_ACTIVATE_FAIL = 'chat_activate_fail';
export const CHAT_HISTORY_FETCH = 'chat_history_fetch';
export const CHAT_HISTORY_FETCH_SUCCESS = 'chat_history_fetch_success';
export const CHAT_HISTORY_FETCH_FAIL = 'chat_history_fetch_fail';
export const CHAT_SET_CURRENT_USER = 'chat_set_current_user';
export const CHAT_DATA_FETCH = 'chat_data_fetch';
export const CHAT_DATA_FETCH_SUCCESS = 'chat_data_fetch_success';
export const CHAT_DATA_FETCH_FAIL = 'chat_data_fetch_fail';
export const CHAT_CLOSE = 'chat_close';
export const CHAT_CLOSE_SUCCESS = 'chat_close_success';
export const CHAT_CLOSE_FAIL = 'chat_close_fail';
export const CHAT_CLOSED_BY_PARTNER = 'chat_closed_by_partner';
export const CHAT_TEXT_CHANGED = 'chat_text_changed';
export const CHAT_SEND = 'chat_send';
export const CHAT_SEND_SUCCESS = 'chat_send_success';
export const CHAT_SEND_FAIL = 'chat_send_fail';
export const CHAT_RECEIVED = 'chat_received';
export const NEW_ACTIVE_CHAT_USER = 'new_active_chat_user';
export const CLOSE_NEW_ACTIVE_MODAL = 'close_new_active_modal';

export const FILTER_FETCH = 'filter_fetch';
export const FILTER_FETCH_SUCCESS = 'filter_fetch_success';
export const FILTER_FETCH_FAIL = 'filter_fetch_fail';
export const FILTER_CHANGE_AGE = 'filter_change_age';
export const FILTER_CHANGE_GENDER = 'filter_change_gender';
export const FILTER_CHANGE_DISTANCE = 'filter_change_distance';
export const FILTER_UPDATE = 'filter_update';
export const FILTER_UPDATE_SUCCESS = 'filter_update_success';
export const FILTER_UPDATE_FAIL = 'filter_update_fail';

export const PROFILE_FETCH = 'profile_fetch';
export const PROFILE_FETCH_SUCCESS = 'profile_fetch_success';
export const PROFILE_FETCH_FAIL = 'profile_fetch_fail';
export const PROFILE_UPDATE = 'profile_update';
export const PROFILE_UPDATE_SUCCESS = 'profile_update_success';
export const PROFILE_UPDATE_CANCEL = 'profile_update_cancel';
export const PROFILE_UPDATE_FAIL = 'profile_update_fail';
export const PROFILE_UPLOAD_PHOTO = 'profile_upload_photo';
export const PROFILE_UPLOAD_PHOTO_SUCCESS = 'profile_upload_photo_success';
export const PROFILE_UPLOAD_PHOTO_FAIL = 'profile_upload_photo_fail';
export const PROFILE_DELETE_PHOTO = 'profile_delete_photo';
export const PROFILE_DELETE_PHOTO_SUCCESS = 'profile_delete_photo_success';
export const PROFILE_DELETE_PHOTO_FAIL = 'profile_delete_photo_fail';
export const PROFILE_UPLOAD_DREAM_PHOTO = 'profile_upload_dream_photo';
export const PROFILE_UPLOAD_DREAM_PHOTO_SUCCESS = 'profile_upload_dream_photo_success';
export const PROFILE_UPLOAD_DREAM_PHOTO_FAIL = 'profile_upload_dream_photo_fail';
export const PROFILE_UPLOAD_VOICE = 'profile_upload_voice';
export const PROFILE_UPLOAD_VOICE_SUCCESS = 'profile_upload_voice_success';
export const PROFILE_UPLOAD_VOICE_FAIL = 'profile_upload_voice_fail';
export const PROFILE_DREAM_CHANGED = 'profile_dream_changed';
export const PROFILE_NAME_CHANGED = 'profile_name_changed';
export const PROFILE_CITY_CHANGED = 'profile_city_changed';
export const PROFILE_COUNTRY_CHANGED = 'profile_country_changed';
export const PROFILE_BIRTHDAY_CHANGED = 'profile_birthday_changed';
export const PROFILE_GENDER_CHANGED = 'profile_gender_changed';
export const PROFILE_ADD_MENTOR = 'profile_add_mentor';
export const PROFILE_MENTOR_CHANGED = 'profile_mentor_changed';
export const PROFILE_MENTOR_REMOVED = 'profile_mentor_removed';
export const PROFILE_MENTOR_SUBMIT = 'profile_mentor_submit';
export const PROFILE_MENTOR_SUBMIT_SUCCESS = 'profile_mentor_submit_success';
export const PROFILE_MENTOR_SUBMIT_FAIL = 'profile_mentor_submit_fail';
export const PROFILE_LEVEL_CHANGED = 'profile_level_changed';
export const PROFILE_REORDERING_PHOTOS = 'profile_reordering_photos';
export const PROFILE_PHOTOS_ORDER_CHANGED = 'profile_photos_order_changed';
export const PROFILE_ADMISSION_COMPLETED ='profile_admission_completed';

export const REPORT_EDITING_CHANGED = 'report_editing_changed';
export const REPORT_TEXT_CHANGED = 'report_text_changed';
export const REPORT_USER_CHANGED = 'report_user_changed';
export const REPORT_ABUSING_CONTENT_CHANGED = 'report_abusing_content_changed';
export const REPORT_INAPPROPRIATE_CHAT_CHANGED = 'report_inappropriate_chat_changed';
export const REPORT_SUBMIT = 'report_submit';
export const REPORT_SUBMIT_SUCCESS = 'report_submit_success';
export const REPORT_SUBMIT_FAIL = 'report_submit_fail';

export const AUDIO_STATUS_CHANGED = 'audio_status_changed';
export const AUDIO_PLAYBACK_OBJECT_CHANGED = 'audio_playback_object_changed';
export const AUDIO_RECORDING_OBJECT_CHANGED = 'audio_recording_object_changed';

export const SUBSCRIPTION_FETCH = 'subscription_fetch';
export const SUBSCRIPTION_FETCH_SUCCESS = 'subscription_fetch_success';
export const SUBSCRIPTION_FETCH_FAIL = 'subscription_fatch_fail';
export const SUBSCRIPTION_SUBSCRIBE = 'subscription_subscribe';
export const SUBSCRIPTION_CREATE_INTENT = 'subscription_create_intent';
export const SUBSCRIPTION_INTENT_CREATED = 'subscription_intent_created';
export const SUBSCRIPTION_PAYMENT_ACTION_PENDING = 'subscription_payment_action_pending';
export const SUBSCRIPTION_PAYMENT_ACTION_SOLVED = 'subscription_payment_action_success';
export const SUBSCRIPTION_SUBSCRIBE_SUCCESS = 'subscription_subscribe_success';
export const SUBSCRIPTION_SUBSCRIBE_FAIL = 'subscription_subscribe_fail';
export const SUBSCRIPTION_CANCEL = 'subscription_cancel';
export const SUBSCRIPTION_CANCEL_SUCCESS = 'subscription_cancel_success';
export const SUBSCRIPTION_CANCEL_FAIL = 'subscription_cancel_fail';
export const SUBSCRIPTION_GO_PREMIUM_PRESS = 'subscription_go_premium_pressed';
export const SUBSCRIPTION_GO_PREMIUM_CANCEL = 'subscription_go_premium_cancel';
export const SUBSCRIPTION_FIRST_NAME_CHANGED = 'subscription_first_name_changed';
export const SUBSCRIPTION_LAST_NAME_CHANGED = 'subscription_last_name_changed';
export const SUBSCRIPTION_EMAIL_CHANGED = 'subscription_email_changed';
export const SUBSCRIPTION_PAYMENT_TYPE_CHANGED = 'subscription_payment_type_changed';
export const SUBSCRIPTION_TOKEN_CHANGED = 'subscription_token_changed';
export const SUBSCRIPTION_UPDATER_TIMER_CHANGE = 'subscription_updater_timer_changed';
