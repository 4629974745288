import React from 'react';

import './styles.css';
import { Images } from '../../config/images';
import { Levels } from '../../config/levels';
import LevelBar from '../LevelBar';

const LevelCircles = ({ profilePicture, level, levelColor, noBar }) => (
        <div className={noBar ? 'LevelCirclesContainerNoBar' : 'LevelCirclesContainer'}>
          <img
            className="LevelCirclesBackground"
            src={Images.circles.third}
            alt="circles"
          />
          <img
            className="LevelCirclesProfile"
            src={profilePicture}
            alt="profile"
          />
          { noBar? null :
            <LevelBar
              text={`LVL ${level} - ${Levels[level]}`}
              color={levelColor}
            />
          }
        </div>
        
);

export default LevelCircles;
