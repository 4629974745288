import { Colors } from '../../../src/config/colors';

const styles = {
  available: {
    backgroundColor: Colors.status.available,
  },
  unavailable: {
      backgroundColor: Colors.status.unavailable,
  },
  active: {
      backgroundColor: Colors.status.active
  },
  pending: {
      backgroundColor: Colors.status.pending
  },
  newMessage: {
      backgroundColor: Colors.status.newMessage
  }
}

export default styles;
