import {
  RESET_REDUCERS,
  MATCHES_LOADING,
  MATCHES_LOADING_SUCCESS,
  MATCHES_LOADING_FAIL,
  NEW_MATCH,
  REMOVE_MATCH,
  CLOSE_MATCH
} from '../actions/types';

const INITIAL_STATE = {
  loadingMessage: '',
  matches: [],
  offset: 0,
  newMatch: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case RESET_REDUCERS:
          return {
              ...INITIAL_STATE,
          };

      case MATCHES_LOADING:
          return {
              ...state,
              loadingMessage: action.payload,
          };

      case MATCHES_LOADING_SUCCESS:
          return {
              ...state,
              loadingMessage: '',
              matches: action.payload,
              offset: (state.offset + 1)
          };

      case MATCHES_LOADING_FAIL:
          return {
              ...state,
              loadingMessage: '',
              matches: [],
          };

      case NEW_MATCH:
          return {
              ...state,
              newMatch: action.payload
          };

      case CLOSE_MATCH:
          return {
              ...state,
              newMatch: null
          };

      case REMOVE_MATCH: {
          const userId = action.payload;
          const newMatches = state.matches.slice().filter((match) => {
              return match.id !== userId;
          });
          return {
              ...state,
              matches: newMatches
          };
      }

      default:
          return state;
  }
};
