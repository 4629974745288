import React from 'react';

import './styles.css';

const ChatBoxPhoto = ({ photo, profileImage, style, openPhoto }) => {  
  return (
    <div className="ChatBoxPhotoContainer" style={style}>
      {profileImage}
      <img
        className="ChatBoxPhotoChatPhoto"
        src={photo}
        alt="messageImage"
        onClick={() =>
          openPhoto(photo)
        }
      />
    </div>
  );
};

export default ChatBoxPhoto;
