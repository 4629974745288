import {
  RESET_REDUCERS,
  REPORT_USER_CHANGED,
  REPORT_EDITING_CHANGED,
  REPORT_TEXT_CHANGED,
  REPORT_ABUSING_CONTENT_CHANGED,
  REPORT_INAPPROPRIATE_CHAT_CHANGED,
  REPORT_SUBMIT,
  REPORT_SUBMIT_SUCCESS,
  REPORT_SUBMIT_FAIL,
} from '../actions/types';

const INITIAL_STATE = {
  userId: '',
  abusiveContent: false,
  inappropriateChat: false,
  remarks: '',
  editing: false,
  submitting: false,
  loadingMessage: '',
  error: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_REDUCERS:
      return {
          ...INITIAL_STATE,
      };

    case REPORT_USER_CHANGED:
      return {
        ...state,
        userId: action.payload,
      };

    case REPORT_EDITING_CHANGED:
      return {
        ...state,
        editing: action.payload,
      };

    case REPORT_TEXT_CHANGED:
      return {
        ...state,
        remarks: action.payload,
      };

    case REPORT_ABUSING_CONTENT_CHANGED:
      return {
        ...state,
        abusiveContent: action.payload,
      };

    case REPORT_INAPPROPRIATE_CHAT_CHANGED:
      return {
        ...state,
        inappropriateChat: action.payload,
      };

    case REPORT_SUBMIT:
      return {
        ...state,
        submitting: true,
        loadingMessage: action.payload,
      };

    case REPORT_SUBMIT_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case REPORT_SUBMIT_FAIL:
      return {
        ...state,
        loadingMessage: '',
        submitting: false,
        error: action.payload,
      };  

  default:
      return state;
  }
};
