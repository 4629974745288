import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import promise from 'redux-promise-middleware';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';


import PersistReducer from './PersistReducer';
import AuthReducer from './AuthReducer';
import ProfileReducer from './ProfileReducer';
import FilterReducer from './FilterReducer';
import UserReducer from './UserReducer';
import SignUpReducer from './SignUpReducer';
import AdmissionReducer from './AdmissionReducer';
import MatchesReducer from './MatchesReducer';
import MatchProfileReducer from './MatchProfileReducer';
import InvitationReducer from './InvitationReducer';
import NotificationReducer from './NotificationReducer';
import ChatReducer from './ChatReducer';
import AudioReducer from './AudioReducer';
import ReportReducer from './ReportReducer';
import SubscriptionReducer from './SubscriptionReducer';


const config = {
    key: 'root',
    storage,
    whitelist: ['persist']
};

const reducers = combineReducers({
    persist: PersistReducer,
    auth: AuthReducer,
    profile: ProfileReducer,
    filter: FilterReducer,
    user: UserReducer,
    signUp: SignUpReducer,
    admission: AdmissionReducer,
    matches: MatchesReducer,
    matchProfile: MatchProfileReducer,
    invitations: InvitationReducer,
    notification: NotificationReducer,
    chat: ChatReducer,
    audio: AudioReducer,
    report: ReportReducer,
    subscription: SubscriptionReducer,
});

const persistedReducer = persistReducer(config, reducers);

const middlewares = [promise, ReduxThunk];

const store = createStore(
    persistedReducer,
    {},
    compose(
        applyMiddleware(...middlewares)
    )
);

const persistor = persistStore(store);

export { store, persistor };