import React from 'react';

import './styles.css';
import TabSingle from '../TabSingle';

const InvitationTabs = ({ active, onTabPress }) => (
    <div className="InvitationTabsContainer">
        <TabSingle
            title={'Incoming'}
            active={active === 1}
            textAlign={'left'}
            onPress={() => { onTabPress(1); }}
        />

        <TabSingle
            title={'Sent'}
            active={active === 2}
            leftBar
            rightBar
            onPress={() => { onTabPress(2); }}
        />

        <TabSingle
            title={'Matches'}
            active={active === 3}
            textAlign={'right'}
            onPress={() => { onTabPress(3); }}
        />
    </div>
);

export default InvitationTabs;
