import React from 'react';
import './styles.css'

const ButtonText = ({ onPress, text }) => (
  <button className="ButtonText" onClick={onPress}>
    {text}    
  </button>
);

export default ButtonText;
