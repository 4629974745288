import {
  RESET_REDUCERS,
  CHAT_ACTIVATE,
  CHAT_ACTIVATE_SUCCESS,
  CHAT_ACTIVATE_FAIL,
  CHAT_HISTORY_FETCH,
  CHAT_HISTORY_FETCH_SUCCESS,
  CHAT_HISTORY_FETCH_FAIL,
  CHAT_SET_CURRENT_USER,
  CHAT_DATA_FETCH,
  CHAT_DATA_FETCH_SUCCESS,
  CHAT_DATA_FETCH_FAIL,
  CHAT_CLOSE,
  CHAT_CLOSE_SUCCESS,
  CHAT_CLOSE_FAIL,
  CHAT_CLOSED_BY_PARTNER,
  CHAT_TEXT_CHANGED,
  CHAT_SEND,
  CHAT_SEND_SUCCESS,
  CHAT_SEND_FAIL,
  CHAT_RECEIVED,
  NEW_ACTIVE_CHAT_USER,
  CLOSE_NEW_ACTIVE_MODAL
} from '../actions/types';

const INITIAL_STATE = {
  error: '',
  loadingMessage: '',
  activating: false,
  activated: false,
  history: [],
  fetchingHistory: false,
  currentChatUser: {},
  currentChatData: {},
  fetchingChatData: false,
  closingChat: false,
  pendingMessage: '',
  newActiveChatUser: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_REDUCERS:
      return {
        ...INITIAL_STATE,
      };

    case CHAT_ACTIVATE:
      return {
        ...state,
        activating: true,
        activated: false,
        error: '',
        loadingMessage: action.payload,
      };

    case CHAT_ACTIVATE_SUCCESS:
      return {
        ...state,
        activating: false,
        activated: true,
        loadingMessage: '',
      };

    case CHAT_ACTIVATE_FAIL:
      return {
        ...state,
        activating: false,
        activated: false,
        error: action.payload,
        loadingMessage: '',
      };

    case CHAT_HISTORY_FETCH:
      return {
        ...state,
        error: '',
        fetchingHistory: true,
        loadingMessage: action.payload,
      };

    case CHAT_HISTORY_FETCH_SUCCESS:
      return {
        ...state,
        error: '',
        history: action.payload,
        fetchingHistory: false,
        loadingMessage: '',
      };

    case CHAT_HISTORY_FETCH_FAIL:
      return {
        ...state,
        error: action.payload,
        fetchingHistory: false,
        loadingMessage: '',
      };

    case CHAT_SET_CURRENT_USER:
      return {
        ...state,
        currentChatUser: action.payload,
        currentChatData: {},
      };

    case CHAT_DATA_FETCH:
      return {
        ...state,
        error: '',
        fetchingChatData: true,
        loadingMessage: action.payload,
      };

    case CHAT_DATA_FETCH_SUCCESS:
      return {
        ...state,
        error: '',
        fetchingChatData: false,
        currentChatData: action.payload,
        loadingMessage: '',
      };

    case CHAT_DATA_FETCH_FAIL:
      return {
        ...state,
        error: action.payload,
        fetchingChatData: false,
        loadingMessage: '',
      };

    case CHAT_CLOSE:
      return {
        ...state,
        error: '',
        closingChat: true,
        loadingMessage: action.payload,
      };

    case CHAT_CLOSE_SUCCESS:
      return {
        ...state,
        error: '',
        closingChat: false,
        currentChatUser: {},
        currentChatData: {},
        loadingMessage: '',
      };

    case CHAT_CLOSE_FAIL:
      return {
        ...state,
        error: action.payload,
        closingChat: false,
        loadingMessage: '',
      };

    case CHAT_CLOSED_BY_PARTNER: {
      const newChatData = { ...state.currentChatData, status: 'closed' };
      return {
        ...state,
        currentChatData: newChatData,
      };
    }


    case CHAT_TEXT_CHANGED:
      return {
        ...state,
        pendingMessage: action.payload,
      };

    case CHAT_SEND: {
      const message = action.payload;
      if (message) {
        const { messages } = state.currentChatData;
        messages.push(message);
        const newChatData = { ...state.currentChatData, messages };

        return {
          ...state,
          error: '',
          pendingMessage: '',
          currentChatData: newChatData,
        };
      }

      return {
        ...state,
        pendingMessage: '',
      };
    }

    case CHAT_SEND_SUCCESS: {
      const { confirmedMessage, sentMessage } = action.payload;
      let { messages } = state.currentChatData;
      if (sentMessage) {
        messages = messages.filter((message) => {
          return message.sent_timestamp !== sentMessage.sent_timestamp;
        });
      }
      messages.push(confirmedMessage);
      const newChatData = { ...state.currentChatData, messages };

      return {
        ...state,
        error: '',
        currentChatData: newChatData,
      };
    }
      

    case CHAT_SEND_FAIL: {
        const { error, message, closed } = action.payload;
        let { status } = state.currentChatData;
        const { messages } = state.currentChatData;
        const index = messages.indexOf(message);

        if (index) {
            messages.splice(index, 1);
        }
        if (status === 'active' && closed) {
            status = 'closed';
        }

        const newChatData = { ...state.currentChatData, messages, status };

        return {
            ...state,
            error,
            loadingMessage: '',
            currentChatData: newChatData,
        };
    }

    
    case CHAT_RECEIVED: {
      const message = action.payload;
      const { messages } = state.currentChatData;
      const sameMessages = messages.filter((m) => {
        return m.id === message.id;
      });

      //Message already in list, just do nothing.
      if (sameMessages.length !== 0) {
        return state;
      }

      //Add new message.
      messages.push(message);
      const newChatData = { ...state.currentChatData, messages };

      return {
        ...state,
        currentChatData: newChatData,
      };
    }

    case NEW_ACTIVE_CHAT_USER: 
      return {
        ...state,
        newActiveChatUser: action.payload,
      };

    case CLOSE_NEW_ACTIVE_MODAL:
      return {
          ...state,
          newActiveChatUser: null
      };

    default:
      return state;
  }
};
