import React from 'react';

import './styles.css';
import Subheading from '../Subheading';
import ProfileInput from '../ProfileInput';
import ButtonGrey from '../ButtonGrey';
import { Images } from '../../config/images';

const MentorsEditor = ({ mentors, onMentorChange, onMentorAdd, onMentorDelete, style }) => {
  const renderMentors = () => {
    return mentors.map((data, key) => {
      return (
        <ProfileInput
          key={key}
          value={data}
          onChangeText={(value) => {
            onMentorChange(key, value);
          }}
          onDelete={() => { 
            onMentorDelete(key);
          }}
          placeholder={'Mentor'}
          deletable
        />
      );
    });
};
  
  return (
    <div className="MentorsEditorContainer">
      <div className="MentorsEditorHeading">
        <Subheading
          heading={'Inspiring people'}
        />
      </div>
      

      {renderMentors()}

      <div className="MentorsEditorAddButton">
        <ButtonGrey
          label={'Enter another one'}
          onPress={onMentorAdd}
          iconRight={Images.icon.plusPurple}
        />
      </div>
    </div>
  );
};

export default MentorsEditor;
