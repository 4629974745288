import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './styles.css';

export const showInfoAlert = (title, text, buttonTitle) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="AlertContainer">
          <h1 className="AlertTitle">{title}</h1>
          <p className="AlertText">{text}</p>
          <button onClick={onClose} className="AlertButton">{buttonTitle}</button>
        </div>
      )
    }
  })
}

export const showAlertWithCompletion = (title, text, buttonTitle, callback) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="AlertContainer">
          <h1 className="AlertTitle">{title}</h1>
          <p className="AlertText">{text}</p>
          <button 
            onClick={() => {
              onClose();
              callback();
            }}
            className="AlertButton">{buttonTitle}</button>
        </div>
      )
    }
  })
}

export const showAnswerAlert = (title, text, confirmTitle, cancelTitle, callbackConfirm, callbackCancel) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="AlertContainer">
          <h1 className="AlertTitle">{title}</h1>
          <p className="AlertText">{text}</p>
          <div>
            <button 
              onClick={() => {
                onClose();
                if (callbackConfirm !== undefined) {
                  callbackConfirm();
                }
              }}
              className="AlertButton">{confirmTitle}
            </button>
            <button 
              onClick={() => {
                onClose();
                if (callbackCancel !== undefined) {
                  callbackCancel();
                }
              }}
              className="AlertButton AlertButtonCancel">{cancelTitle}
            </button>
          </div>
        </div>
      )
    }
  })
}