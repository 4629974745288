const subscriptionStatusTypes = {
  inactive: 'inactive',
  trial: 'trial',
  grace: 'grace',
  premium: 'premium',
  premium_trial: 'premium_trial',
  premium_single: 'premium_single',
  pending: 'pending',
  processing: 'processing'
};

export default subscriptionStatusTypes;
