import React from 'react';

import './styles.css';
import PhotoCircleList from '../../components/PhotoCircleList';
import DreamEditor from '../../components/DreamEditor';
import VoiceMessageRecorder from '../../components/VoiceMessageRecorder';
import PersonalInformationEditor from '../../components/PersonalInformationEditor';
import MentorsEditor from '../../components/MentorsEditor';

const ProfileInfoEdit = ({ photos, onAdd, onDelete, onReorderingStart, onReorderingEnd, onReorderingMoved,
                           dreamImage, dreamText, onPhotoPress, onDreamTextChange,
                           isPlaying, isRecording, onPlayPressed, onRecordPressed, voice,
                           profile, onNameChanged, onCityChanged, onCountryChanged, onBirthdateChanged,
                           onGenderChanged,
                           mentors, onMentorAdd, onMentorChange, onMentorDelete }) => (
  <div className="ProfileInfoEditContainer">
    <div className="ProfileInfoEditRow ProfileInfoEditRowFirst">
      <div className="ProfileInfoEditBox">
        <PhotoCircleList
          photos={photos}
          onAdd={onAdd}
          onDelete={onDelete}
          onReorderingStart={onReorderingStart}
          onReorderingEnd={onReorderingEnd}
          onReorderingMoved={onReorderingMoved}
        />
      </div>

      <div className="ProfileInfoEditBox">
        <DreamEditor
          dreamImage={dreamImage}
          dreamText={dreamText}
          onPhotoPress={onPhotoPress}
          onDreamTextChange={onDreamTextChange}
        />
      </div>
    </div>

    <div className="ProfileInfoEditRow">
      <div className="ProfileInfoEditBox ProfileInfoEditBoxSecond">
        <VoiceMessageRecorder
          isPlaying={isPlaying}
          isRecording={isRecording}
          onPlayPressed={onPlayPressed}
          onRecordPressed={onRecordPressed}
          voice={voice}
        />
      </div>

      <div className="ProfileInfoEditBox ProfileInfoEditBoxSecond">
        <PersonalInformationEditor
          profile={profile}
          onNameChanged={onNameChanged}
          onCityChanged={onCityChanged}
          onCountryChanged={onCountryChanged}
          onBirthdateChanged={onBirthdateChanged}
          onGenderChanged={onGenderChanged}
        />
      </div>

      <div className="ProfileInfoEditBox ProfileInfoEditBoxSecond ProfileInfoEditBoxFlexHeigth">
        <MentorsEditor
          mentors={mentors}
          onMentorAdd={onMentorAdd}
          onMentorChange={onMentorChange}
          onMentorDelete={onMentorDelete}
        />
      </div>
    </div>
  </div>
);

export default ProfileInfoEdit;
