import React, { Component } from 'react';

import './styles.css';
import styles from './styles';
import { dateToReadableDateTime } from '../../helpers/date';

export default class InvitationCard extends Component {
    renderStatus = () => {
        const { timestamp, status } = this.props.invitation;
        let style;
        let text;

        switch (status) {
            case 'available':
                style = styles.available;
                text = 'Available';
                break;

            case 'unavailable':
                style = styles.unavailable;
                text = 'In Conversation';
                break;

            case 'active':
                style = styles.active;
                text = 'Active';
                break;

            case 'pending':
                style = styles.pending;
                text = 'Pending';
                break;

            case 'new_message':
                style = styles.newMessage;
                text = 'New message';
                break;
        
            default:
                break;
        }

        return (
            <div className="InvitationCardContentRow">
                <div className="InvitationCardStatusWrapper">
                    <div className="InvitationCardCircle" style={style} />
                    <p className="InvitationCardStatus">{text}</p>
                </div>
                <p className="InvitationCardDate">{dateToReadableDateTime(new Date(timestamp * 1000))}</p>
            </div>
        );
    }

    render() {
        const { invitation, onPress } = this.props;        
        return (
            <button onClick={onPress} className="InvitationCardButton">
                <div className="InvitationCardContainer">
                    <img
                      src={invitation.profile_picture}
                      className="InvitationCardProfilePicture"
                      alt="profilePicture"
                    />

                    <div className="InvitationCardContent">
                        <p className="InvitationCardUserInfo">{`${invitation.name} · ${invitation.age}`}</p>
                        {this.renderStatus()}
                    </div>
                </div>
            </button>
        );
    }
}
