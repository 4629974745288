import React from 'react';

import './styles.css';
import QuestionImage from '../../components/QuestionImage';

const Question = ({ text, image }) => (
  <div className="QuestionContainer">
    <p className="QuestionText">{text}</p>
    {image ? 
      <QuestionImage
        image={image}
      /> : null}
  </div>
);

export default Question;
