import React from 'react';

import './styles.css';
import { Images } from '../../config/images';
import Checkbox from '../Checkbox';

const GenderSelection = ({ male, female, onPressMale, onPressFemale }) => (
    <div className="GenderSelectionContainer">
        <button onClick={onPressMale} className="GenderSelectionButton">
          <img 
            src={Images.gender.malePurple} 
            className="GenderSelectionImage"
            alt="male"
          />
          <p className="GenderSelectionText">Male</p>
          <Checkbox
            state={male}
            onStateChange={() => {
                onPressMale();
            }}
          />
        </button>

        <button onClick={onPressFemale} className="GenderSelectionButton">
          <img 
            src={Images.gender.femalePurple} 
            className="GenderSelectionImage" 
            alt="female"
          />
          <p className="GenderSelectionText">Female</p>
          <Checkbox
            state={female}
            onStateChange={() => {
                onPressFemale();
            }}
          />
        </button>
    </div>
);

export default GenderSelection;
