import React from 'react';

import './styles.css';
import ButtonPlay from '../ButtonPlay';
import { dateToReadableDateTime } from '../../helpers/date';

const ChatBoxVoice = ({ voice, timestamp, isPlaying, isFromSelf, profileImage, onPlayStopPress, boxStyle, style }) => {  
  return (
    <div style={style} className="ChatBoxVoiceContainer">
      {profileImage}
        <div className={`ChatBoxVoiceWrapper ${!isFromSelf ? 'ChatBoxVoiceWrapperPartner' : ''}`} style={boxStyle}>
            <div className="ChatBoxVoiceVoiceContainer">
              <ButtonPlay
                playing={isPlaying}
                onPress={onPlayStopPress}
                alternative
              />
              <img
                className="ChatBoxVoiceWaveform"
                src={voice.waveform}
                alt="voiceWave"
              />
            </div>

            <p className="ChatBoxVoiceTextDate">
              {dateToReadableDateTime(new Date(timestamp * 1000))}
            </p>
        </div>
    </div>
  );
};

export default ChatBoxVoice;
