import React from 'react';
import { Images } from '../../config/images';

import './styles.css';

const Header = ({ text, shadow }) => (
  <div className={`HeaderContainer ${shadow ? 'HeaderDropShadow' : ''}`}>
    <div className={`HeaderImage ${text ? 'HeaderImageWithTitle' : 'HeaderImageNoTitle'}`}>
      <img
        src={Images.logo.headerLogo}
        alt="logo"
      />
      <p className="HeaderImageTitle">Attraction</p>
    </div>
    
    {text ? <p className="HeaderTitle">{text}</p> : null}
  </div>
);

export default Header;