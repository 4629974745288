import {
  RESET_REDUCERS,
  INVITATIONS_FETCH,
  INVITATIONS_FETCH_SUCCESS,
  INVITATIONS_FETCH_FAIL,
  NEW_INVITATION,
  INVITATIONS_TAB_CHANGED,
  CLOSE_INVITATION
} from '../actions/types';

const INITIAL_STATE = {
    incoming: [],
    sent: [],
    match: [],
    newInvitation: null,
    activeTabIndex: 1,
    error: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_REDUCERS:
            return {
                ...INITIAL_STATE,
            };

        case INVITATIONS_FETCH:
            return {
                ...state,
                error: null,
            };

        case INVITATIONS_FETCH_SUCCESS:
            return {
                ...state,
                incoming: action.payload.incoming,
                sent: action.payload.sent,
                match: action.payload.match,
                error: null,
            };

        case INVITATIONS_FETCH_FAIL:
            return {
                ...state,
                ...INITIAL_STATE,
                error: 'Failed to get invitations.',
            };

        case NEW_INVITATION:
            return {
                ...state,
                newInvitation: action.payload,
            };

        case CLOSE_INVITATION:
            return {
                ...state,
                newInvitation: null
            };

        case INVITATIONS_TAB_CHANGED: 
            return {
              ...state,
              activeTabIndex: action.payload
            }

    default:
        return state;
  }
};
