import React, { Component } from 'react';

import './styles.css';
import MorePhotoCircle from '../MorePhotoCircle';
import Subheading from '../Subheading';
import ModalImage from '../ModalImage';

class ProfileMorePhotos extends Component {
  state = {
    fullscreenPhoto: null,
    index: null
  };

  renderPhotos = (photos) => {
    return photos.map((data, index) => {
      return (
        <MorePhotoCircle
          photo={data.url ? data.url : data}
          key={index}
          openPhoto={(photo) => this.setState({ fullscreenPhoto: photo, index })}
        />
      );
    });
  };

  handlePrev() {
    const { photos } = this.props;
    const prevIndex = this.state.index - 1
    const photo = photos[prevIndex].url ? photos[prevIndex].url : photos[prevIndex]
    this.setState({ fullscreenPhoto: photo, index: prevIndex })
  }

  handleNext() {
    const { photos } = this.props;
    const nextIndex = this.state.index + 1
    const photo = photos[nextIndex].url ? photos[nextIndex].url : photos[nextIndex]
    this.setState({ fullscreenPhoto: photo, index: nextIndex })
  }

  render() {
    const { photos } = this.props;
    return (
      <div className="ProfileMorePhotosContainer">
        <Subheading
          heading={'More photos'}
          centered
        />
        <div className="ProfileMorePhotosListContainer">
          {this.renderPhotos(photos)}
        </div>
        {this.state.fullscreenPhoto ?
          <ModalImage
            image={this.state.fullscreenPhoto}
            onClose={() => this.setState({ fullscreenPhoto: null, index: null })}
            onPrev={() => this.handlePrev()}
            onNext={() => this.handleNext()}
            index={this.state.index}
            total={photos.length - 1}
          /> : null}
      </div>
    );
  }
};

export default ProfileMorePhotos;
