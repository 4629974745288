import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    fetchProfileRequest,
    updateProfileRequest,
    updateProfileCancel,
    dreamChanged,
    nameChanged,
    cityChanged,
    countryChanged,
    birthDayChanged,
    genderChanged,
    addMentor,
    mentorChanged,
    removeMentor,
    updateMentorsRequest,
    uploadProfilePhotoChunks,
    deleteProfilePhoto,
    changeProfilePhotosOrder,
    uploadDreamPhotoChunks,
    uploadVoice
} from '../../actions/ProfileActions';

import './styles.css';
import Header from '../../components/Header';
import TitleBar from '../../components/TitleBar';
import BackButton from '../../components/BackButton';
import ProfileWithLevel from '../../components/ProfileWithLevel';
import ProfileInfoEdit from '../../components/ProfileInfoEdit';
import ProfileFooter from '../../components/ProfileFooter';
import Filter from '../../components/Filter';

import { Images } from '../../config/images';
import { Colors } from '../../config/colors';
import { createFormDataChunksFromImageFile } from '../../helpers/image';
import { dateToDateString } from '../../helpers/date';
import { MainPaths } from '../../config/paths';
import AudioManager from '../../helpers/audio';
import { showInfoAlert } from '../../components/Alert';
import { Api } from '../../config/api';
import { isSafari, waitForSecondElement, shakeElement } from '../../helpers/utils';

class ProfileEdit extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profile.updatingProfile === true && nextProps.profile.updatingProfile === false) {
      if (nextProps.profile.error !== '') {
        showInfoAlert('Error', nextProps.profile.error, 'Ok');
      }
    }
  }

  componentDidMount() {
    // block user on screen until form is ready
    this.block = this.props.history.block((nextLocation)=> {
      const { profile } = this.props;
      const { photos, voice, city, country, name } = profile;
      if (!name || name === '') {
        showInfoAlert('Error', 'Please enter your name. Name is required.', 'Ok');
        return false;
      }
      if (!photos || photos.length === 0) {
        showInfoAlert('Error', 'Please upload a profile picture. Profile picture is required.', 'Ok');
        return false;
      }
      if (!voice || voice.length === 0) {
        showInfoAlert('Error', 'Please upload a voice message. Voice message is required.', 'Ok');
        return false;
      }
      if (!city || city === '') {
        showInfoAlert('Error', 'Please enter your city. City is required.', 'Ok');
        return false;
      }
      if (!country || country === '') {
        showInfoAlert('Error', 'Please enter your country. Country is required.', 'Ok');
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    AudioManager.shared().stopRecording();
    this.block();
  }

  openProfile = () => {
    const { history } = this.props;
    history.push(MainPaths.profile);
  }

  uploadPhoto = async (file)  => {
    const formDataChunks = await createFormDataChunksFromImageFile(file);
    if (formDataChunks.length) {
      this.props.uploadProfilePhotoChunks(this.props.persist.token, formDataChunks)
    }
  };

  deletePhoto = (id) => {
    this.props.deleteProfilePhoto(this.props.persist.token, id);
  };

  changePhotosOrder = (oldIndex, newIndex) => {
    const { profile, persist } = this.props;

    let tmp = profile.photos[newIndex]
    profile.photos[newIndex] = profile.photos[oldIndex]
    profile.photos[oldIndex] = tmp

    const newOrder = new Array(profile.photos.length);
    for (let index = 0; index < profile.photos.length; index++) {
        newOrder[index] = profile.photos[index].id;
    }

    this.props.changeProfilePhotosOrder(persist.token, { photos: newOrder }, profile.photos);
  }

  photoReorderStarted = (index) => {
    // fix image flickering on safari
    if (isSafari()) {
      //reference of old (to be moved) element
      const oldElement = document.getElementById(`photo-circle-${index+1}`);

      if (oldElement) {
        //delay the hidden status until the clone element is generated
        oldElement.style.visibility = 'visible'
        oldElement.style.opacity = '';
        const observerOld = new MutationObserver(function(){
          if(oldElement.style.visibility !== 'visibile'){
              if (oldElement) {
                oldElement.style.visibility = 'visible'
                oldElement.style.opacity = '';
              }
          }
        });
        observerOld.observe(oldElement, { attributes: true, childList: true });

        //callback for clone element ready
        waitForSecondElement(`#photo-circle-${index+1}`).then((element) => {
          //hide clone element, until image is loaded
          element.style.visibility = 'hidden';
          const img = element.querySelector(`#img-circle-${index+1}`);
          const loaded = () => {
            observerOld.disconnect();
            element.style.visibility = 'visible';
            shakeElement(element);
          }

          if (img.complete) {
            loaded()
          } else {
            img.addEventListener('load', loaded)
            img.addEventListener('error', loaded)
          }
        });
      }
    } else {
      waitForSecondElement(`#photo-circle-${index+1}`).then((element) => {
        shakeElement(element);
      });
    }
  }

  photoReorderingMoved = () => {
  }

  uploadDreamPhoto = async (file) => {
    const formDataChunks = await createFormDataChunksFromImageFile(file);
    if (formDataChunks.length) {
      this.props.uploadDreamPhotoChunks(this.props.persist.token, formDataChunks)
    }
  };

  updateProfile = () => {
    const { profile } = this.props;
    const { token } = this.props.persist;
    const data = {
        name: profile.name,
        dream: profile.dream,
        dob: profile.birthday,
        city: profile.city,
        country: profile.country,
        gender: profile.gender,
    };
    const mentors = { name: profile.mentors.filter(mentor => mentor !== '') }

    this.props.updateMentorsRequest(token, mentors);
    this.props.updateProfileRequest(token, data);
  };

  refreshProfile = () => {
    if (this.props.persist.token && this.props.persist.token.length) {
      this.props.fetchProfileRequest(this.props.persist.token);
    }
  }

  startStopRecording = async () => {
    const { recording } = this.props.audio.status;
    if (recording) {
        const formData = await AudioManager.shared().stopRecording();
        this.props.uploadVoice(this.props.persist.token, formData);
    } else {
        AudioManager.shared().startRecording();
    }
  }

  render() {
    const { profile } = this.props;
    const levelColor = { backgroundColor: Colors.level[profile.level] };
    const { recording } = this.props.audio.status;
    let { playing } = this.props.audio.status;
    if (profile.voice.url !== this.props.audio.playbackSourceUrl) {
      playing = false;
    }

    return (
      <div className="MenuPage ProfileEditContainer">
        <img className="ProfileEditCorner"
          src={Images.background.cornerCircle}
          alt="cornerCircle"
        />
        <Header shadow/>
        <div className="ProfileEditProfileImage">
            <ProfileWithLevel
              levelColor={levelColor}
              level={profile.level}
              profilePicture={
                profile.photos.length > 0 ?
                  profile.photos[0].url
                  :
                  Api.endpoints.profilePlaceholder
              }
            />
        </div>

        <TitleBar title={'My Profile'}>
          <div className="ProfileEditSubtitle">
            <p className="ProfileEditSubtitleRed ProfileEditSubtitleText">*</p>
            <p className="ProfileEditSubtitleText">{'Required fields'}</p>
          </div>
        </TitleBar>

        <div className="ProfileEditBackButton">
          <BackButton
            onPress={() => this.openProfile()}
          />
        </div>

        <ProfileInfoEdit
          // photos section
          photos={this.props.profile.photos}
          onAdd={(file) => {
              this.uploadPhoto(file);
          }}
          onDelete={(key) => {
              this.deletePhoto(key);
          }}
          onReorderingStart={({ index }) => this.photoReorderStarted(index)}
          onReorderingMoved={() => this.photoReorderingMoved()}
          onReorderingEnd={({ oldIndex, newIndex }) => {
              this.changePhotosOrder(oldIndex, newIndex);
          }}
          // dream editor
          dreamImage={profile.dreamImage}
          dreamText={profile.dream}
          onPhotoPress={(file) => {
              this.uploadDreamPhoto(file);
          }}
          onDreamTextChange={(text) => {
              this.props.dreamChanged(text);
          }}
          // voice section
          isPlaying={playing}
          isRecording={recording}
          onPlayPressed={() => {
              AudioManager.shared().playStopUrl(profile.voice.url);
          }}
          onRecordPressed={() => {
              this.startStopRecording();
          }}
          voice={profile.voice.url}
          // personal information editor
          profile={profile}
          onNameChanged={(text) => {
              this.props.nameChanged(text);
          }}
          onCityChanged={(text) => {
              this.props.cityChanged(text);
          }}
          onCountryChanged={(text) => {
              this.props.countryChanged(text);
          }}
          onBirthdateChanged={(date) => {
              this.props.birthDayChanged(date? dateToDateString(date) : null);
          }}
          onGenderChanged={(gender) => {
              this.props.genderChanged(gender);
          }}
          // inspiring section
          mentors={profile.mentors}
          onMentorAdd={() => this.props.addMentor()}
          onMentorChange={(key, value) => {
              this.props.mentorChanged(profile.mentors, key, value);
          }}
          onMentorDelete={(key) => {
              this.props.removeMentor(profile.mentors, key);
          }}
        />

        <ProfileFooter
          onSavePress={() => {
            this.updateProfile();
          }}
          onCancelPress={() => {
            this.props.updateProfileCancel();
            this.refreshProfile();
          }}
        />

        <Filter />

      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      profile: store.profile,
      audio: store.audio,
  };
};

const mapDispatchToProps = {
  fetchProfileRequest,
  updateProfileRequest,
  updateProfileCancel,
  dreamChanged,
  nameChanged,
  cityChanged,
  countryChanged,
  birthDayChanged,
  genderChanged,
  addMentor,
  mentorChanged,
  removeMentor,
  updateMentorsRequest,
  uploadProfilePhotoChunks,
  deleteProfilePhoto,
  changeProfilePhotosOrder,
  uploadDreamPhotoChunks,
  uploadVoice,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProfileEdit)
);
