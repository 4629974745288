import React from 'react';

import './styles.css';
import PaginationNumbers from '../PaginationNumbers';
import PaginationCounter from '../PaginationCounter';

const Pagination = ({ current, total }) => (
  <div className="PaginationContainer">
    <div className="PaginationNumbersContainer">
      <PaginationNumbers
        current={current}
      />
    </div>
    
    <PaginationCounter
      amount={total - current}
    />
  </div>
);

export default Pagination;
