import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchAdmission } from '../../actions/AdmissionActions';

import './styles.css';
import { AdmissionPaths } from '../../config/paths';
import { showAlertWithCompletion } from '../../components/Alert';
import Title from '../../components/Title';
import ButtonPurple from '../../components/ButtonPurple';
import ButtonWhite from '../../components/ButtonWhite';

class AdmissionStart extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.admission.questions.length === 0 && nextProps.admission.error !== null) {
      showAlertWithCompletion(
        'Error',
         nextProps.admission.error,
         'Retry',
         () => this.props.fetchAdmission(this.props.persist.token)
      );
    }
  }

  componentDidMount() {        
    this.props.fetchAdmission(this.props.persist.token);
  }

  admissionQuestionHandler = () => {
    const { history } = this.props;
    history.push(AdmissionPaths.admissionQuestion);
  }

  admissionExplanationHandler = () => {
    const { history } = this.props;
    history.push(AdmissionPaths.admissionAbout);
  }

  render() {
    return (
      <div className="AdmissionStartContainer">
        <div className="AdmissionStartBackground" />
        <div className="AdmissionStartFormContainer">
          <Title title={`Hey ${this.props.profile.name}!`} /> 
          <div className="AdmissionStartSentence">
            Thank you for signing up!<br/>
            On what energy frequency are you vibrating?
          </div>
          <div className="AdmissionStartButtons">
            <ButtonPurple
              // style={styles.button}
              label={'Start test'}
              onPress={this.admissionQuestionHandler}
              large
            />
            <ButtonWhite
              // style={styles.button}
              label={'What\'s that?'}
              onPress={this.admissionExplanationHandler}
              large
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      profile: store.profile,
      admission: store.admission
  };
};

const mapDispatchToProps = {
  fetchAdmission
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdmissionStart)
);
