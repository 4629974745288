import {
  RESET_REDUCERS,
  AUTH_USERNAME_CHANGED,
  AUTH_PASSWORD_CHANGED,
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
  LOST_PASSWORD_EMAIL_CHANGED,
  LOST_PASSWORD_SUBMIT,
  LOST_PASSWORD_SUBMIT_SUCCESS,
  LOST_PASSWORD_SUBMIT_FAIL
} from '../actions/types';

const INITIAL_STATE = {
  loadingMessage: '',
  name: '',
  username: '',
  password: '',
  error: '',

  lostPasswordEmail: '',
  lostPasswordError: '',
  lostPasswordSubmitting: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case RESET_REDUCERS:
          return {
              ...INITIAL_STATE,
          };

      case AUTH_USERNAME_CHANGED:
          return {
              ...state,
              username: action.payload
          };

      case AUTH_PASSWORD_CHANGED:
          return {
              ...state,
              password: action.payload
          };

      case AUTH_LOGIN:
          return {
              ...state,
              loadingMessage: 'Authenticating credentials',
          };

      case AUTH_LOGIN_SUCCESS:
          return {
              ...state,
              loadingMessage: '',
              error: '',
          };

      case AUTH_LOGIN_FAIL: 
          return {
              ...state,
              loadingMessage: '',
              error: action.payload,
          };

      case LOST_PASSWORD_EMAIL_CHANGED:
          return {
              ...state,
              lostPasswordEmail: action.payload
          };
      
      case LOST_PASSWORD_SUBMIT:
          return {
              ...state,
              lostPasswordSubmitting: true,
          };

      case LOST_PASSWORD_SUBMIT_SUCCESS:
          return {
              ...state,
              lostPasswordEmail: '',
              lostPasswordSubmitting: false,
              lostPasswordError: '',
          };

      case LOST_PASSWORD_SUBMIT_FAIL:
          return {
              ...state,
              lostPasswordSubmitting: false,
              lostPasswordError: action.payload,
          };


      default:
          return state;
  }
};
