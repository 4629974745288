 const server = 'https://api.attraction.dating';
//const server = 'http://127.0.0.1:8000';

export const Api = {
    url: server,
    grantType: 'password',
    socialGrantType: 'social',
    clientId: '2',
    clientSecret: 'bUsuvMb4O1k8Sk4fKEolXFMb2ZGxh6LDieHv9FyH',

    requests: {
        login: `${server}/oauth/token`,
        notificationToken: `${server}/api/notificationtoken`,
        geoLocation: `${server}/api/location`,
        signUp: `${server}/api/register`,
        lostPassword: `${server}/api/forgot-password`,
        admission: `${server}/api/admission`,
        matches: (offset) => { return `${server}/api/matches/${offset}`; },
        matchProfile: (id) => { return `${server}/api/profile/${id}`; },
        invitations: `${server}/api/invitations`,
        profile: `${server}/api/profile`,
        profileUploadPhoto: `${server}/api/profile/photo`,
        uploadDreamPhoto: `${server}/api/dream/photo`,
        profileDeletePhoto: (id) => { return `${server}/api/profile/photo/${id}`; },
        profileReorderPhotos: `${server}/api/profile/sort-photos`,
        uploadVoice: `${server}/api/voice`,
        mentor: `${server}/api/mentor`,
        filter: `${server}/api/filter`,
        favorite: `${server}/api/like`,
        activateChat: `${server}/api/chat/activate`,
        chatHistory: `${server}/api/chat`,
        chat: (id) => { return `${server}/api/chat/${id}`; },
        closeChat: `${server}/api/chat/close`,
        feedback: `${server}/api/feedback`,
        report: `${server}/api/report`,
        subscription: `${server}/api/subscription`,
        subscribe: `${server}/api/subscribe`,
        createIntent: `${server}/api/payment/intent`,
        subscribeSource: `${server}/api/subscribe/source`,
        cancelSubscription: `${server}/api/subscription/cancel`,
    },

    endpoints: {
      broadcastingAuth: `${server}/broadcasting/auth`,
      profilePlaceholder: `${server}/images/placeholder.jpg`
    }
};
