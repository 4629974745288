import Pusher from 'pusher-js';
import { Api } from '../config/api';
import { settings } from '../config/settings';

import { store } from '../reducers/index';
import {
  receivedMessage,
  newActiveChatUser,
  chatClosed,
} from '../actions/ChatActions';
import { newMatch } from '../actions/MatchesActions';
import { newInvitation } from '../actions/InvitationActions';
import { 
  newNotification
} from '../actions/NotificationActions';
import notificationToast from '../components/NotificationToast';
import { MainPaths } from '../config/paths';

Pusher.logToConsole = false;

let pusher = null;
let channelMessage = null;
let channelInvite = null;
let channelMatch = null;
let channelChat = null;

export const NOTIFICATION_TYPES = {
  NEW_MATCH: 'new_match',
  NEW_MESSAGE: 'new_message',
  NEW_INVITE: 'new_invite',
  NEW_ACTIVE_CHAT: 'new_chat',
  CLOSE_CHAT: 'close_chat',
};

export const subscribePusher = (token, userId) => {
  if (pusher) {
    //already subscribbed
    return
  }

  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json'
  }

  pusher = new Pusher(settings.pusher_key, {
    auth: { headers },
    authEndpoint: Api.endpoints.broadcastingAuth,
    logToConsole: false,
    cluster: 'eu',
    encrypted: true
  });

  channelMessage = pusher.subscribe(`private-message.${userId}`);
  channelMessage.bind('pusher:subscription_succeeded', () => {
    channelMessage.bind('new_message', (data) => {
      // console.log('new_message:', data);
      handleNewMessageNotification(data.message);
    });
  });
  channelMessage.bind('pusher:subscription_error', (error) => {
    console.log('channelMessage-subscription_error:', error);
  });

  channelInvite = pusher.subscribe(`private-invite.${userId}`);
  channelInvite.bind('pusher:subscription_succeeded', () => {
    channelInvite.bind('new_invite', (data) => {
      // console.log('new_invite:', data);
      handleNewInviteNotification(data.invite)
    });
  });
  channelInvite.bind('pusher:subscription_error', (error) => {
    console.log('channelInvite-subscription_error:', error);
  });

  channelMatch = pusher.subscribe(`private-match.${userId}`);
  channelMatch.bind('pusher:subscription_succeeded', () => {
    channelMatch.bind('new_match', (data) => {
      // console.log('new_match:', data);
      handleNewMatchNotification(data.match);
    });
  });
  channelMatch.bind('pusher:subscription_error', (error) => {
    console.log('channelMatch-subscription_error:', error);
  });
  
  channelChat = pusher.subscribe(`private-chat.${userId}`);
  channelChat.bind('pusher:subscription_succeeded', () => {
    channelChat.bind('new_chat', (data) => {
      // console.log('new_chat:', data);
      handleNewChatNotification(data.chat)
    });
    channelChat.bind('close_chat', (data) => {
      // console.log('close_chat:', data);
      handleCloseChatNotification(data.chat)
    });
  });
  channelChat.bind('pusher:subscription_error', (error) => {
    console.log('channelChat-subscription_error:', error);
  });
};

export const unsubscribePusher = () => {
  if (channelMessage) {
    channelMessage.unbind();
    channelMessage = null;
  }
  if (channelInvite) {
    channelInvite.unbind();
    channelInvite = null;
  }
  if (channelMatch) {
    channelMatch.unbind();
    channelMatch = null;
  }
  if (channelChat) {
    channelChat.unbind();
    channelChat = null;
  }
  if (pusher) {
    pusher = null;
  }
};

const handleNewMatchNotification = (match) => {        
  store.dispatch(newNotification({ type: NOTIFICATION_TYPES.NEW_MATCH, data: match}));
  const noCamelCaseMatch = {
    invitation_id: match.invitationId,
    user_id: match.userId,
    name: match.name,
    age: match.age,
    profile_picture: match.profilePicture,
    timestamp: match.timestamp
  };
  store.dispatch(newMatch(noCamelCaseMatch));
}

const handleNewMessageNotification = (message) => {        
  store.dispatch(newNotification({ type: NOTIFICATION_TYPES.NEW_MESSAGE, data: message}));
  if (window.location.pathname !== MainPaths.chat) {
    // we are not on the chat screen
    notificationToast('You have a new message')
  }
  const currentChatPartner = store.getState().chat.currentChatUser;              
  if (message.senderId === currentChatPartner.user_id) {
    const noCamelCaseMessage = {
      id: message.id,
      text: message.text,
      photo: message.photo,
      voice: message.voice,
      timestamp: message.timestamp,
      sender_id: message.senderId,
      receiver_id: message.receiverId
    };
    store.dispatch(receivedMessage(noCamelCaseMessage));
  }
}

const handleNewInviteNotification = (invite) => {        
  store.dispatch(newNotification({ type: NOTIFICATION_TYPES.NEW_INVITE, data: invite}));
  const noCamelCaseInvite = {
    invitation_id: invite.invitationId,
    user_id: invite.userId,
    name: invite.name,
    age: invite.age,
    profile_picture: invite.profilePicture,
    timestamp: invite.timestamp
  };
  store.dispatch(newInvitation(noCamelCaseInvite));
}

const handleNewChatNotification = (chat) => {        
  store.dispatch(newNotification({ type: NOTIFICATION_TYPES.NEW_ACTIVE_CHAT, data: chat}));
  const noCamelCaseChat = {
    invitation_id: chat.invitationId,
    user_id: chat.userId,
    name: chat.name,
    age: chat.age,
    profile_picture: chat.profilePicture,
    timestamp: chat.timestamp
  };
  store.dispatch(newActiveChatUser(noCamelCaseChat));
}

const handleCloseChatNotification = (chat) => {        
  store.dispatch(newNotification({ type: NOTIFICATION_TYPES.CLOSE_CHAT, data: chat}));
  store.dispatch(chatClosed());
  notificationToast(`The exclusive chat has been closed by ${chat.name}`);
}