import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';

import Menu from '../../components/Menu';
import OverlayMatchModal from '../../pages/OverlayMatchModal';
import InvitationModal from '../../pages/InvitationModal';
import ActiveChatModal from '../../pages/ActiveChatModal';
import GoPremiumModal from '../../pages/GoPremiumModal';
import { TabRoutes } from '../../config/routes';
import { subscribePusher } from '../../helpers/pusher';
import { setDirectLink } from '../../actions/PersistActions';

class Main extends Component {
  UNSAFE_componentWillMount() {
    const { token } = this.props.persist;
    const { userId } = this.props.profile;
    subscribePusher(token, userId);
  }

  componentDidMount() {
    // handle direct links
    const { directLink } = this.props.persist;
    const { history } = this.props;

    if (directLink) {
      history.replace(directLink);
      this.props.setDirectLink(null);
    }
  }

  render() {
    const { newMatch } = this.props.matches;
    const { newInvitation } = this.props.invitations;
    const { newActiveChatUser } = this.props.chat;
    const { goPremiumModal } = this.props.subscription;
    if (newActiveChatUser || newMatch || newInvitation) {
      window.scrollTo(0, 0);
    }

    return (
      <div>
        <Menu />
        <TabRoutes />
        { newActiveChatUser ? <ActiveChatModal /> 
        : (newMatch ? <OverlayMatchModal /> : null)}
        { newInvitation ? <InvitationModal /> : null}
        { goPremiumModal ? <Elements><GoPremiumModal /></Elements> : null}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      matches: store.matches,
      profile: store.profile,
      invitations: store.invitations,
      chat: store.chat,
      subscription: store.subscription,
  };
};

const mapDispatchToProps = {
  setDirectLink
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Main)
);
