import { backdrop } from 'backdropjs';

import { isSafari } from './utils';

export const blurToElementWithId = (elementId, onBlurComplete) => {
  const element = document.getElementById(elementId);
  // for safari blur is not neaded, as we have backdrop filter css styling
  if (!isSafari() && element !== undefined ) {
    backdrop(document.documentElement.outerHTML, document.getElementById(elementId), 10, 'no');
    // callback for blur complete, when the iframe is laoded
    document.getElementsByTagName("iframe")[0].onload = () => {
      setTimeout(function () {
        onBlurComplete();
      }, 100);
    }
  } else {
    onBlurComplete();
  }
};