import {
  SUBSCRIPTION_FETCH,
  SUBSCRIPTION_FETCH_SUCCESS,
  SUBSCRIPTION_FETCH_FAIL,
  SUBSCRIPTION_SUBSCRIBE,
  SUBSCRIPTION_SUBSCRIBE_SUCCESS,
  SUBSCRIPTION_SUBSCRIBE_FAIL,
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_CANCEL_SUCCESS,
  SUBSCRIPTION_CANCEL_FAIL,
  SUBSCRIPTION_GO_PREMIUM_PRESS,
  SUBSCRIPTION_GO_PREMIUM_CANCEL,
  SUBSCRIPTION_FIRST_NAME_CHANGED,
  SUBSCRIPTION_LAST_NAME_CHANGED,
  SUBSCRIPTION_EMAIL_CHANGED,
  SUBSCRIPTION_PAYMENT_TYPE_CHANGED,
  SUBSCRIPTION_TOKEN_CHANGED,
  SUBSCRIPTION_UPDATER_TIMER_CHANGE,
  SUBSCRIPTION_CREATE_INTENT,
  SUBSCRIPTION_INTENT_CREATED,
  SUBSCRIPTION_PAYMENT_ACTION_PENDING,
  SUBSCRIPTION_PAYMENT_ACTION_SOLVED,
} from '../actions/types';
import { get, post } from '../helpers/api';
import { Api } from '../config/api';
import notificationToast from '../components/NotificationToast';
import { handleCardSetup, handleCardPayment } from '../helpers/stripe';

export const fetchSubscriptionRequest = (token) => {
  return dispatch => {
      dispatch({
          type: SUBSCRIPTION_FETCH,
          payload: 'Loading subscription',
      });

      get(token, Api.requests.subscription).then(response => {
          dispatch({
              type: SUBSCRIPTION_FETCH_SUCCESS,
              payload: response.data,
          });
      }).catch((e) => {
          console.log('ERROR FETCH SUBSCRIPTION', e);
          dispatch({ type: SUBSCRIPTION_FETCH_FAIL });
      });
  };
};

export const paymentAuthenticate = (token, stripeJs, { paymentIntent }) => {
  return dispatch => {
    dispatch({
        type: SUBSCRIPTION_PAYMENT_ACTION_PENDING,
        payload: 'Validating payment',
    });
    handleCardPayment(stripeJs, paymentIntent).then((response) => {
      dispatch({
          type: SUBSCRIPTION_PAYMENT_ACTION_SOLVED,
          payload: response,
      });
      dispatch(fetchSubscriptionRequest(token))
    })
    .catch((e) => {
      console.log(e);
      let payload = 'Paymant failed to process';
      notificationToast(payload, 'error');
      dispatch({
          type: SUBSCRIPTION_PAYMENT_ACTION_SOLVED,
          payload
      });
    })

  }
}


export const subscribeRequest = (token, data, clientSecret, stripeJs) => {
  return dispatch => {
      dispatch({
          type: SUBSCRIPTION_SUBSCRIBE,
          payload: 'Validating payment',
      });

      const { email, name } = data

      handleCardSetup(stripeJs, clientSecret, name, email)
      .then( ({ setupIntent })  => {
        return post(token, Api.requests.subscribe,
          { ...data, intentPaymentMethod: setupIntent.payment_method }
        )
      })
      .then(response => {
        console.log('response', response)
        if (response.data.status === 'action_required') {
          dispatch(paymentAuthenticate(token, stripeJs, response.data))
        } else {
          dispatch({
              type: SUBSCRIPTION_SUBSCRIBE_SUCCESS,
              payload: { success: true },
          });
          dispatch(fetchSubscriptionRequest(token))
        }
      }).catch((e) => {
          console.log(e);
          let payload = 'Paymant failed to process';
          if (e.response && e.response.data.message) {
              payload = e.response.data.message;
          }
          notificationToast(payload, 'error');
          dispatch({
              type: SUBSCRIPTION_SUBSCRIBE_FAIL,
              payload
          });
      });
  };
};

export const createPaymentIntentRequest = (token, data) => {
  return dispatch => {
      dispatch({
          type: SUBSCRIPTION_CREATE_INTENT,
          payload: 'Validating payment',
      });

      post(token, Api.requests.createIntent, data).then(response => {
          if (response.data.success === true) {
            dispatch({
                type: SUBSCRIPTION_INTENT_CREATED,
                payload: response.data.intentToken,
            });
          } else {
              throw new Error('Payment intent could not be created');
          }
      }).catch((e) => {
          let payload = "We can't process your payment at the moment please try again later";
          if (e.response && e.response.data.message) {
              payload = e.response.data.message;
          }
          notificationToast(payload, 'error');
          dispatch({
              type: SUBSCRIPTION_SUBSCRIBE_FAIL,
              payload
          });
      });
  };
};

export const subscribeSourceRequest = (token, authorizePaymentUrl, data) => {
  return dispatch => {
      dispatch({
          type: SUBSCRIPTION_SUBSCRIBE,
          payload: 'Payment',
      });

      post(token, Api.requests.subscribeSource, data).then(response => {
          window.location = authorizePaymentUrl
      }).catch((e) => {
          console.log(e.response.data);
          let payload = 'Paymant failed to process';
          if (e.response.data.message) {
              payload = e.response.data.message;
          }
          notificationToast(payload, 'error');
          dispatch({
              type: SUBSCRIPTION_SUBSCRIBE_FAIL,
              payload
          });
      });
  };
};

export const subscriptionCancel = (token) => {
  return dispatch => {
      dispatch({
          type: SUBSCRIPTION_CANCEL,
          payload: 'Canceling subscription',
      });

      post(token, Api.requests.cancelSubscription).then(response => {
        console.log('response', response)
          dispatch({
              type: SUBSCRIPTION_CANCEL_SUCCESS,
              payload: response.data,
          });
      }).catch((e) => {
          let payload = 'Something went wrong.';
          if (e.response.data) {
              const errors = e.response.data.errors;
              if (errors) {
                  payload = errors[Object.keys(errors)[0]][0];
              }
          }

          dispatch({
              type: SUBSCRIPTION_CANCEL_FAIL,
              payload
          });
      });
  };
};

export const showGoPremium = token => {
  return {
      type: SUBSCRIPTION_GO_PREMIUM_PRESS,
  };
};

export const cancelGoPremium = (latitude, longitude) => {
  return {
      type: SUBSCRIPTION_GO_PREMIUM_CANCEL,
  };
};

export const firstNameChanged = input => {
  return {
      type: SUBSCRIPTION_FIRST_NAME_CHANGED,
      payload: input
  };
};

export const lastNameChanged = input => {
  return {
      type: SUBSCRIPTION_LAST_NAME_CHANGED,
      payload: input
  };
};

export const emailChanged = input => {
  return {
      type: SUBSCRIPTION_EMAIL_CHANGED,
      payload: input
  };
};

export const paymentTypeChanged = input => {
  return {
      type: SUBSCRIPTION_PAYMENT_TYPE_CHANGED,
      payload: input
  };
};

export const tokenChanged = input => {
  return {
      type: SUBSCRIPTION_TOKEN_CHANGED,
      payload: input
  };
};

export const updateTimerChanged = timer => {
  return {
      type: SUBSCRIPTION_UPDATER_TIMER_CHANGE,
      payload: timer
  };
};
