import React, { Component } from 'react';
import { connect } from 'react-redux';
import { lostPasswordEmailChanged, submitLostPassword } from '../../actions/AuthActions';
import { withRouter } from 'react-router-dom';

import './styles.css';
import Title from '../../components/Title';
import LoginInput from '../../components/LoginInput';
import ButtonPurple from '../../components/ButtonPurple';
import ButtonText from '../../components/ButtonText';
import { AuthPaths } from '../../config/paths';
import { showInfoAlert } from '../../components/Alert';

class LostPassword extends Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.auth.lostPasswordSubmitting === false && this.props.auth.lostPasswordSubmitting === true) {
            if (nextProps.auth.lostPasswordError === '') {
                showInfoAlert('Email sent',
                'We have sent you an email with the next steps for resetting your password.',
                'Ok');
                this.loginHandler();
            }
        }
    }

    loginHandler = () => {
      const { history } = this.props;
      history.replace(AuthPaths.login);
    }

    render() {
        const error = this.props.auth.lostPasswordError;
        return (
          <div className="LostPasswordContainer">
            <div className="LostPasswordBackground" />
            <div className="LostPasswordFormContainer">

                <Title title={'Lost Password'} />

                <div className="LostPasswordContentWrapper">
                    { error ? (
                        <p className="LostPasswordError">{error}</p>
                    ) : (
                        false
                    )}

                    <LoginInput
                        placeholder={'Email'}
                        value={this.props.auth.lostPasswordEmail}
                        onChangeText={(text) => {
                            this.props.lostPasswordEmailChanged(text);
                        }}
                    />
                    <div className="LostPasswordButtons">
                      <ButtonPurple
                          label={'Submit'}
                          onPress={() => { 
                              this.props.submitLostPassword(this.props.auth.lostPasswordEmail);
                          }}
                      />
                      <div style={{ marginTop: 20 }}>
                        <ButtonText
                          text={'Login'}
                          onPress={() => { this.loginHandler() }}
                        />
                      </div>
                    </div>
                </div>
              </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({ 
  auth: store.auth,
});

const mapDispatchToProps = {
    lostPasswordEmailChanged,
    submitLostPassword
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LostPassword)
);
