export const Levels = {
    8: 'Courage',
    9: 'Neutrality',
    10: 'Willingness',
    11: 'Acceptance',
    12: 'Reason',
    13: 'Love',
    14: 'Joy',
    15: 'Peace',
    16: 'Enlightenment',
};

export const LevelExplanations = {
    default: 'Assume to see your energy level right? We will provide you soon a whole page with all ' +
    'the information and video channel to upgrade your personal development, to create even a better energy ' +
    'level score and most important an inner transformation of yourself in a good way.',
    16: 'Enlightenment is associated with the emotional state ineffable, beautiful you\'re at this energy level vibrating. Your view on life is; Being. There must be a beautiful soul attracted to you.',
    15: 'Peace is associated with the emotional state bliss, beautiful you\'re at this energy level vibrating. Your view on life is; Perfect. There must be a beautiful soul attracted to you.',
    14: 'Joy is associated with the emotional state serenity, beautiful you\'re at this energy level vibrating. Your view on life is; Complete. There must be a beautiful soul attracted to you.',
    13: 'Love is associated with the emotional state reverence, beautiful you\'re at this energy level vibrating. Your view on life is; Benign. There must be a beautiful soul attracted to you.',
    12: 'Reason is associated with the emotional state understanding, beautiful you\'re at this energy level vibrating. Your view on life is; meaningful. There must be a beautiful soul attracted to you.',
    11: 'Acceptance is associated with the emotional state forgiveness, beautiful you\'re at this energy level vibrating. Your view on life is; Harmonious. There must be a beautiful soul attracted to you.',
    10: 'Willingness is associated with the emotional state optimism, beautiful you\'re at this energy level vibrating. Your view on life is; Hopeful. There must be a beautiful soul attracted to you.',
    9: 'Neutrality is associated with the emotional state trust, beautiful you\'re at this energy level vibrating. Your view on life is; Satisfaction. There must be a beautiful soul attracted to you.',
    8: 'Courage is associated with the emotional state affirmation, beautiful you\'re at this energy level vibrating. Your view on life is; Feasible. There must be a beautiful soul attracted to you.',
};
