import React from 'react';

import LoginInput from '../LoginInput';
import ButtonPurple from '../ButtonPurple';
import ButtonText from '../ButtonText';
import './styles.css';

const LoginForm = ({ error, email, emailChangeText, password, passwordChangeText, submitAction, onLostPasswordPress }) => (
  <div>
    { error ? (
      <p className="LoginFormError">{error}</p>
    ) : (
        false
    )}

    <LoginInput
      value={email}
      onChangeText={emailChangeText}
      placeholder={'Email'}
      onSubmit={() => submitAction()}
    />

    <div className="LoginFormPasswordInput">
      <LoginInput
        value={password}
        onChangeText={passwordChangeText}
        placeholder={'Password'}
        secureTextEntry
        onSubmit={() => submitAction()}
      />
    </div>

    <div className="LoginFormButtons">
      <ButtonText
        text={'Lost password?'}
        onPress={onLostPasswordPress}
      />

      <ButtonPurple
        label={'Login'}
        onPress={() => submitAction()}
      />
    </div>
    
    
  </div>
);

export default LoginForm;