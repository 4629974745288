import {
  RESET_REDUCERS,
  SUBSCRIPTION_FETCH,
  SUBSCRIPTION_FETCH_SUCCESS,
  SUBSCRIPTION_FETCH_FAIL,
  SUBSCRIPTION_SUBSCRIBE,
  SUBSCRIPTION_SUBSCRIBE_SUCCESS,
  SUBSCRIPTION_SUBSCRIBE_FAIL,
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_CANCEL_SUCCESS,
  SUBSCRIPTION_CANCEL_FAIL,
  SUBSCRIPTION_GO_PREMIUM_PRESS,
  SUBSCRIPTION_GO_PREMIUM_CANCEL,
  SUBSCRIPTION_FIRST_NAME_CHANGED,
  SUBSCRIPTION_LAST_NAME_CHANGED,
  SUBSCRIPTION_EMAIL_CHANGED,
  SUBSCRIPTION_PAYMENT_TYPE_CHANGED,
  SUBSCRIPTION_TOKEN_CHANGED,
  SUBSCRIPTION_UPDATER_TIMER_CHANGE,
  SUBSCRIPTION_CREATE_INTENT,
  SUBSCRIPTION_INTENT_CREATED,
  SUBSCRIPTION_PAYMENT_ACTION_PENDING,
  SUBSCRIPTION_PAYMENT_ACTION_SOLVED,
} from '../actions/types';
import subscriptionStatusTypes from '../config/subscriptionStatusTypes';

const INITIAL_STATE = {
  loadingMessage: '',
  goPremiumModal: false,
  firstName: null,
  lastName: null,
  email: null,
  paymentType: null,
  token: null,
  type: null,
  end: null,
  premium: null,
  subscriptionUpdateTimer: null,
  paymentIntent: null,
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
      case RESET_REDUCERS:
          return {
              ...INITIAL_STATE,
          };

      case SUBSCRIPTION_FETCH:
          return {
              ...state,
              loadingMessage: action.payload
          };

      case SUBSCRIPTION_FETCH_FAIL:
          return {
              ...state,
              loadingMessage: ''
          };

      case SUBSCRIPTION_FETCH_SUCCESS:
        return {
            ...state,
            type: action.payload.type,
            end: action.payload.end,
            premium: action.payload.premium,
            loadingMessage: '',
        };

      case SUBSCRIPTION_SUBSCRIBE:
          return {
              ...state,
              loadingMessage: action.payload,
          };

      case SUBSCRIPTION_SUBSCRIBE_SUCCESS:
          return {
              ...state,
              loadingMessage: '',
              premium: action.payload.success === 'true' ? true : false,
              type: action.payload.success === 'true'? subscriptionStatusTypes.processing : null,
          };

      case SUBSCRIPTION_SUBSCRIBE_FAIL:
          return {
              ...state,
              loadingMessage: ''
          };

      case SUBSCRIPTION_CANCEL:
          return {
              ...state,
              loadingMessage: action.payload,
          };

      case SUBSCRIPTION_CANCEL_SUCCESS:
          return {
              ...state,
              loadingMessage: '',
              type: action.payload.success === 'true'? subscriptionStatusTypes.grace : state.type,
          };

      case SUBSCRIPTION_CANCEL_FAIL:
          return {
              ...state,
              loadingMessage: ''
          };

      case SUBSCRIPTION_GO_PREMIUM_PRESS:
          return {
              ...state,
              goPremiumModal: true
          };

      case SUBSCRIPTION_GO_PREMIUM_CANCEL:
          return {
            ...state,
            goPremiumModal: false
        };

      case SUBSCRIPTION_FIRST_NAME_CHANGED:
          return {
              ...state,
              firstName: action.payload
          };

      case SUBSCRIPTION_LAST_NAME_CHANGED:
          return {
              ...state,
              lastName: action.payload
          };

      case SUBSCRIPTION_EMAIL_CHANGED:
          return {
              ...state,
              email: action.payload
          };

      case SUBSCRIPTION_PAYMENT_TYPE_CHANGED:
          return {
              ...state,
              paymentType: action.payload
          };

      case SUBSCRIPTION_TOKEN_CHANGED:
          return {
              ...state,
              token: action.payload
          };

      case SUBSCRIPTION_UPDATER_TIMER_CHANGE:
          return {
              ...state,
              subscriptionUpdateTimer: action.payload
          };

      case SUBSCRIPTION_CREATE_INTENT:
          return {
              ...state,
              loadingMessage: action.payload,
              paymentIntent: null
          };

      case SUBSCRIPTION_INTENT_CREATED:
          return {
              ...state,
              loadingMessage: '',
              paymentIntent: action.payload
          };

      case SUBSCRIPTION_PAYMENT_ACTION_PENDING:
          return {
              ...state,
              loadingMessage: action.payload,
          };

      case SUBSCRIPTION_PAYMENT_ACTION_SOLVED:
          return {
              ...state,
              loadingMessage: '',
              paymentIntent: null,
              paymentActionRequired: false,
              paymentActionIntent: null,
          };

      default:
          return state;
  }
};
