import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchProfileRequest } from '../../actions/ProfileActions';

import './styles.css';
import { Images } from '../../config/images';
import { Colors } from '../../config/colors';
import { MainPaths } from '../../config/paths';
import AudioManager from '../../helpers/audio';

import Header from '../../components/Header';
import ProfileWithLevel from '../../components/ProfileWithLevel';
import Subheading from '../../components/Subheading';
import DreamCircle from '../../components/DreamCircle';
import ProfileMorePhotos from '../../components/ProfileMorePhotos';
import ProfileImage from '../../components/ProfileImage';
import HeadingWithIcon from '../../components/HeadingWithIcon';
import InspiringPeopleList from '../../components/InspiringPeopleList';
import ButtonPurple from '../../components/ButtonPurple';
import SoundBar from '../../components/SoundBar';

class Profile extends Component {
  componentDidMount() {        
    this.props.fetchProfileRequest(this.props.persist.token);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile.updateTimestamp !== this.props.profile.updateTimestamp) {
      this.props.fetchProfileRequest(this.props.persist.token);
    }
  }

  openEditProfile = () => {
    const { history } = this.props;
    history.push(MainPaths.profileEdit);
  }

  renderSoundBar = () => {   
    if ('url' in this.props.profile.voice) {
      let { playing } = this.props.audio.status;
      if (this.props.profile.voice.url !== this.props.audio.playbackSourceUrl) {
        playing = false;
      }
      return (
        <SoundBar
          playing={playing}
          onPress={() => {
              AudioManager.shared().playStopUrl(this.props.profile.voice.url);
          }}
          image={this.props.profile.voice.waveform}
          length={this.props.profile.voice.duration}
        />
      );
    }
  };

  render() {
    const { profile } = this.props;
    const levelColor = { backgroundColor: Colors.level[profile.level] };
    return (
      <div className="MenuPage ProfileContainer">
        <img className="ProfileCorner"
          src={Images.background.cornerCircle}
          alt="cornerCircle"
        />

        <Header shadow/>

        <div className="ProfileProfileImage">
          <ProfileWithLevel 
            levelColor={levelColor}
            level={profile.level}
            profilePicture={profile.profileImage}
          />
        </div>

        <div className="ProfileContent">
          <div className="ProfileContentRow">
            <div className="ProfileBoxLeft">
              <Subheading
                heading={`${profile.name}'s Dream`}
                centered
                large
              />
              <div className="ProfileDreamCircle">
                <DreamCircle 
                  photo={profile.dreamImage}
                  text={profile.dream}
                />
              </div>
              <div className="ProfileMorePictures">
                <ProfileMorePhotos 
                  photos={profile.photos}
                />
              </div>
            </div>

            <div className="ProfileBoxRight">
              <div className="ProfileImage">
                <ProfileImage 
                  profilePicture={profile.profileImage}
                />
              </div>
              
              <div className="ProfileNameAge">
                <Subheading
                  heading={'My Profile'}
                  centered
                />
              </div>

              <div className="ProfileSoundBar">
              { this.renderSoundBar() }
              </div>

              <div className="ProfileHeadingWithIcon">
                <HeadingWithIcon
                  heading={this.props.profile.city !== null ? `${this.props.profile.city},` : ''}
                  icon={Images.icon.housePurple}
                  subtitle={this.props.profile.country || ''}
                />
              </div>

              <div className="ProfileHeadingWithIcon">
                <HeadingWithIcon
                  heading={'They inspire me:'}
                  icon={Images.icon.lampBubblePurple}
                />
              </div>

              <div className="ProfileMentors">
                <InspiringPeopleList
                  persons={this.props.profile.mentors}
                />
              </div>

              <div className="ProfileEditButtonContainer">
                <ButtonPurple
                  label={'Edit profile'}
                  onPress={() => {this.openEditProfile()}}
                  arrowRight
                />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      profile: store.profile,
      audio: store.audio,
  };
};

const mapDispatchToProps = {
  fetchProfileRequest,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Profile)
);
