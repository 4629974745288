import {
  RESET_REDUCERS,
  ADMISSION_FETCH,
  ADMISSION_FETCH_SUCCESS,
  ADMISSION_FETCH_FAIL,
  ADMISSION_ANSWER_CHANGED,
  ADMISSION_STEP_CHANGED,
  ADMISSION_SUBMIT,
  ADMISSION_SUBMIT_SUCCESS,
  ADMISSION_SUBMIT_FAIL,
} from '../actions/types';

const INITIAL_STATE = {
    questions: [],
    answersGiven: [],
    currentStep: 1,
    submitted: false,
    error: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_REDUCERS:
            return {
                ...INITIAL_STATE,
            };

        case ADMISSION_FETCH:
            return {
                ...state,
                error: null,
            };

        case ADMISSION_FETCH_SUCCESS:
            return {
                ...state,
                questions: action.payload,
                answersGiven: [],
                currentStep: 1,
                error: null,
            };

        case ADMISSION_FETCH_FAIL:
            return {
                ...state,
                ...INITIAL_STATE,
                error: 'Failed to get questions for test, please try again.',
            };

        case ADMISSION_ANSWER_CHANGED: {
            const index = state.currentStep - 1;
            const { answersGiven } = state;
            answersGiven[index] = action.payload;
            return {
                ...state,
                answersGiven
            };
        }

        case ADMISSION_STEP_CHANGED:
            return {
                ...state,
                currentStep: state.currentStep + action.payload,
            };

        case ADMISSION_SUBMIT:
            return {
                ...state,
                error: null
            };

        case ADMISSION_SUBMIT_SUCCESS:
            return {
                ...state,
                error: null,
                submitted: true,
            };

        case ADMISSION_SUBMIT_FAIL:
            return {
                ...state,
                error: 'Admission not submitted.',
            };


    default:
        return state;
  }
};
