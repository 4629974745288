import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { 
    getChatHistory, 
    setCurrentChatUser,
    getChatData,
    chatTextChanged,
    sendMessage,
    closeChat 
} from '../../actions/ChatActions';
import { matchProfileClear } from '../../actions/MatchProfileActions';

import './styles.css';
import { Images } from '../../config/images';
import { Colors } from '../../config/colors';
import { showInfoAlert, showAnswerAlert } from '../../components/Alert';
import { MainPaths } from '../../config/paths';
import { createFormDataFromImageFile } from '../../helpers/image';
import { fetchSubscriptionRequest } from '../../actions/SubscriptionActions';

import Header from '../../components/Header';
import TitleBar from '../../components/TitleBar';
import ProfileWithLevel from '../../components/ProfileWithLevel';
import TitleChat from '../../components/TitleChat';
import ChatList from '../../components/ChatList';
import ChatInput from '../../components/ChatInput';
import AudioManager from '../../helpers/audio';
import ModalImage from '../../components/ModalImage';



class Chat extends Component {
  state = {
    fullscreenPhoto: null,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.chat.error !== nextProps.chat.error && nextProps.chat.error !== '') {
        showInfoAlert('Error', nextProps.chat.error, 'Ok');
    } else if (this.props.chat.closingChat === true && nextProps.chat.closingChat === false) {
        this.openChatHistory();
    } else if (this.props.chat.fetchingHistory === true && nextProps.chat.fetchingHistory === false) {
        //History just fetched (from this screen)
        if (this.props.chat.currentChatUser.user_id === undefined) {
            const active = nextProps.chat.history.filter((chat) => {
                return chat.status === 'active';
            });                
            if (active.length === 0) {
                this.openChatHistory();
            } else {
                this.props.setCurrentChatUser(active[0]);
            }
        }
    } else if (this.props.chat.currentChatUser.user_id !== nextProps.chat.currentChatUser.user_id) {
        //Chat partner just changed
        this.props.getChatData(this.props.persist.token, nextProps.chat.currentChatUser.user_id);
    }
    if (nextProps.subscription.premium !== null && nextProps.subscription !== this.props.subscription) {
      this.checkSubscription(nextProps.subscription);
    }
  }

  componentDidMount() {
    const { subscription } = this.props;

    if (this.props.chat.currentChatUser.user_id === undefined) {
      this.props.getChatHistory(this.props.persist.token);
    } else {
      this.props.getChatData(this.props.persist.token, this.props.chat.currentChatUser.user_id);
    }
 
    if (subscription.premium === null) {
      this.props.fetchSubscriptionRequest(this.props.persist.token);
    } else {
      this.checkSubscription(subscription);
    }
  }

  componentWillUnmount() {
    AudioManager.shared().stopRecording();
  }

  openChatHistory = () => {
    const { history } = this.props;
    history.push(MainPaths.chatHistory);
  }

  checkSubscription = (subscription) => {
    const { history } = this.props;
    if (!subscription.premium) {
      showAnswerAlert('Subscription',
        'Please subscribe to Premium for exclusive chats.',
        "View options",
        "Cancel",
        () => { 
          history.push(MainPaths.subscription);
        }
      );
    }
  }

  stopChat = () => {
    showAnswerAlert('Warning',
      'The chat will be permanently closed. Do you wish to continue?',
      "Yes",
      "No",
      () => { 
        const data = { id: this.props.chat.currentChatUser.invitation_id };
        this.props.closeChat(this.props.persist.token, data);
      }
    );
  }

  sendMessage = (content) => {
    const { token } = this.props.persist;
    const userId = this.props.chat.currentChatUser.user_id;
    this.props.sendMessage(token, userId, content);
  }

  startStopRecording = async () => {
    const { recording } = this.props.audio.status;   
    if (recording) {
        const formData = await AudioManager.shared().stopRecording();
        this.sendMessage(formData);
    } else {
        AudioManager.shared().startRecording();
    }
  }

  pickImage = async () => {
    this.refs.imagePicker.click();
  }

  sendPhoto = async (file)  => {
    const formData = await createFormDataFromImageFile(file);
    this.sendMessage(formData);
  };

  openProfile = () => {
    const partner = this.props.chat.currentChatUser;
    const { history } = this.props;
    this.props.matchProfileClear();
    history.push(MainPaths.matchProfile.replace(":id", partner.user_id));
  }

  render() {
    const { profile, subscription } = this.props;
    const profileImage = this.props.profile.profileImage !== null ?
        this.props.profile.profileImage : Images.placeholder.avatar;
    const levelColor = { backgroundColor: Colors.level[profile.level] };
    const partner = this.props.chat.currentChatUser;
    const chatData = this.props.chat.currentChatData;
    const isActiveChat = chatData.status === 'active';
    const { pendingMessage } = this.props.chat;
    const { recording, playing } = this.props.audio.status;
    const playbackSourceUrl = this.props.audio.playbackSourceUrl;
    return (
      <div className="MenuPage ChatContainer">
        <img className="ChatCorner"
          src={Images.background.cornerCircle}
          alt="cornerCircle"
        />

        <Header shadow/>
        <div className="ChatProfileImage">
            <ProfileWithLevel 
              levelColor={levelColor}
              level={profile.level}
              profilePicture={profileImage}
            />
        </div>

        <TitleBar title={'Chat'}>
          <button className="ChatHistoryButton" onClick={() => this.openChatHistory()}>
            <img className="ChatHistoryIcon" src={Images.icon.history} alt="historyIcon"/>
            <p className="ChatHistoryText">Chat History</p>
          </button>
        </TitleBar>

        <TitleChat 
          isActive={isActiveChat}
          profilePicture={partner.profile_picture}
          name={partner.name}
          age={partner.age}
          onStopPress={() => {
              this.stopChat();
          }}
          openProfile={this.openProfile}
        />
        <div className="ChatList">
          <ChatList
            chatData={chatData}
            partner={partner}
            onPressPartnerPP={this.openProfile}
            playing={playing}
            playbackSourceUrl={playbackSourceUrl}
            onVoicePlayStopPress={(voiceUrl) => {
                AudioManager.shared().playStopUrl(voiceUrl);
            }}
            openPhoto={(photo) => this.setState({ fullscreenPhoto: photo })}
          /> 
        </div>
        {
          isActiveChat && subscription.premium ? (
            <div className="ChatInput">
              <ChatInput
                text={pendingMessage}
                isRecording={recording}
                onCameraPress={() => {
                    this.pickImage();
                }}
                onMicrophonePress={() => {
                    this.startStopRecording();
                }}
                onChangeText={(text) => {
                    this.props.chatTextChanged(text);
                }}
                onSend={() => {
                    if (pendingMessage !== '') {
                        this.sendMessage({ text: pendingMessage });
                    }
                }}
              />
            </div>
          ) : (
              <div className="ChatBottomSpace"/>
          )
        }

        <input
          ref="imagePicker"
          type="file" 
          accept="image/jpeg, image/png"
          style={{display: "none"}}
          onChange={(event) => this.sendPhoto(event.target.files[0])}
        />

        {
          this.state.fullscreenPhoto && (
            <ModalImage
              image={this.state.fullscreenPhoto}
              onClose={() => this.setState({ fullscreenPhoto: null })}
            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      profile: store.profile,
      chat: store.chat,
      matchProfile: store.matchProfile,
      audio: store.audio,
      subscription: store.subscription,
  };
};

const mapDispatchToProps = {
  getChatHistory,
  setCurrentChatUser,
  getChatData,
  chatTextChanged,
  sendMessage,
  closeChat,
  matchProfileClear,
  fetchSubscriptionRequest
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Chat)
);
