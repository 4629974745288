import {
  RESET_REDUCERS,
  PERSIST_REGISTRATION,
  PERSIST_SET_REGISTRATIONS,
  PERSIST_FETCH_TOKEN,
  PERSIST_USER_ID,
  AUTH_LOGIN_SUCCESS,
  SIGN_UP_SUCCESS,
  SET_DIRECT_LINK,
} from '../actions/types';

const INITIAL_STATE = {
  token: '',
  directLink: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case RESET_REDUCERS:
          return {
              ...INITIAL_STATE,
          };

      case AUTH_LOGIN_SUCCESS:
          return {
              ...state,
              token: action.payload
          };

      case SIGN_UP_SUCCESS:
          return {
              ...state,
              token: action.payload
          };

      case PERSIST_REGISTRATION:
          return {
              ...state,
              registrations: [...state.registrations, action.payload],
          };

      case PERSIST_SET_REGISTRATIONS:
          return {
              ...state,
              registrations: action.payload
          };

      case PERSIST_FETCH_TOKEN:
          return {
              ...state,
              token: action.payload
          };

      case PERSIST_USER_ID:
          return {
              ...state,
              user_id: action.payload
          };
        
      case SET_DIRECT_LINK:
          return {
              ...state,
              directLink: action.payload
          };

      default:
          return state;
  }
};
