import React from 'react';

import './styles.css';
import { Images } from '../../config/images';

const Quote = ({ title, content, imageSource, style }) => (
	<div className="QuoteContainer">
		<img
			src={imageSource}
      className="QuoteBackgroiundImage"
      alt="background"
		/>
    <div className="QuoteDarkOverlay">
      <p className="QuoteContentTextTitle">{title}</p>
      <img
        src={Images.divider.quoteGreen}
        className="QuoteImage"
        alt="divider"
      />

      <p className="QuoteContentText">{content}</p>
    </div>
	</div>
);

export default Quote;
