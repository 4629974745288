import React, { Component } from 'react';

import './styles.css';
import { Images } from '../../config/images';
import Title from '../Title';
import ButtonPlay from '../ButtonPlay';

class Match extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundCircles: this.backgroundRoulette()
        };
    }

    backgroundRoulette = () => {
        const background = [
            Images.circles.first,
            Images.circles.second,
            Images.circles.third
        ];
    
        return background[Math.floor(Math.random() * background.length)];
    };

    render() {
        const { name, age, profilePicture, playing, voice, onVoicePlayStopPress, onPress } = this.props;
        return (
            <div className="MatchContainer">
              <button className="MatchButton" onClick={onPress}>
                <div className="MatchAspect">
                  <div className="MatchImageContainer">
                    <img
                      src={this.state.backgroundCircles}
                      className="MatchBackground"
                      alt="background"
                    />
                    <img src={profilePicture} alt="profile" className="MatchProfileImg"/>
                  </div>
                </div>
                
                <Title style={{ fontSize: 30, marginTop: 20 }}title={`${name} · ${age}`} />
              </button>
                { voice && voice !== '' ? (
                  <div className="MatchPlay">
                    <ButtonPlay 
                      playing={playing}
                      onPress={onVoicePlayStopPress}
                    />
                  </div>
                ) : (
                    false
                )}
            </div>
        );
    }
}

export default Match;
