/* eslint-disable global-require */
export const Images = {
  icon: {
      arrowRightWhite: require('../assets/images/icon-arrow-right-white.svg'),
      arrowLeftPurpleDark: require('../assets/images/icon-arrow-left-purple-dark.svg'),
      flagPurple: require('../assets/images/icon-flag-purple.svg'),
      checkCircleGreen: require('../assets/images/icon-check-circle-green.svg'),
      circleGrey: require('../assets/images/icon-circle-grey.svg'),
      menuWhite: require('../assets/images/icon-menu.svg'),
      crossGreen: require('../assets/images/icon-cross-green.svg'),
      crossWhite: require('../assets/images/icon-close-white.svg'),
      plusWhite: require('../assets/images/icon-plus-white.svg'),
      plusPurple: require('../assets/images/icon-plus-purple.svg'),
      pencilWhite: require('../assets/images/icon-pencil-white.svg'),
      trashBinPurple: require('../assets/images/icon-bin-purple.svg'),
      calendarGreen: require('../assets/images/icon-calendar-green.svg'),
      housePurple: require('../assets/images/icon-house-purple.svg'),
      lampBubblePurple: require('../assets/images/icon-lamp-bubble-purple.svg'),
      personIdeaWhite: require('../assets/images/icon-person-idea-white.svg'),
      stopWhite: require('../assets/images/icon-stop-white.svg'),
      playWhite: require('../assets/images/icon-play-white.svg'),
      microphoneWhite: require('../assets/images/icon-microphone-white.svg'),
      hearthWhite: require('../assets/images/icon-hearth.svg'),
      heartPurple: require('../assets/images/icon-heart-purple.png'),
      crossBlack: require('../assets/images/icon-cross-black.svg'),
      history: require('../assets/images/icon-history.svg'),
      stopChat: require('../assets/images/icon-stop.svg'),
      cameraGreen: require('../assets/images/icon-camera-green.svg'),
      microphoneGreen: require('../assets/images/icon-microphone-green.svg'),
      stopGreen: require('../assets/images/icon-stop-green.png')
  },
  menu: {
    matches: require('../assets/images/menu-matches.svg'),
    matchesSelected: require('../assets/images/menu-matches-selected.svg'),
    chat: require('../assets/images/menu-chat.svg'),
    chatSelected: require('../assets/images/menu-chat-selected.svg'),
    profile: require('../assets/images/menu-profile.svg'),
    profileSelected: require('../assets/images/menu-profile-selected.svg'),
    invites: require('../assets/images/menu-invites.svg'),
    invitesSelected: require('../assets/images/menu-invites-selected.svg'),
    news: require('../assets/images/menu-news.svg'),
    newsSelected: require('../assets/images/menu-news-selected.svg'),
    settings: require('../assets/images/menu-settings.svg'),
    settingsSelected: require('../assets/images/menu-settings-selected.svg'),
    subscription: require('../assets/images/menu-subscription.svg'),
    subscriptionSelected: require('../assets/images/menu-subscription-selected.svg'),
    logout: require('../assets/images/menu-logout.svg')
  },
  logo: {
    headerLogo: require('../assets/images/logo.svg')
  },
  divider: {
    quoteGreen: require('../assets/images/divider-quote-green.png'),
  },
  background: {
    cornerCircle: require('../assets/images/corner-cirle-bg.svg'),
    quoteBackground: require('../assets/images/quote-bg.png')
  },
  circles: {
    profileBackground: require('../assets/images/background-circle-photo-profile.svg'),
    matches1: require('../assets/images/matches-bg-1.svg'),
    first: require('../assets/images/match-circle-1.svg'),
    second: require('../assets/images/match-circle-2.svg'),
    third: require('../assets/images/match-circle-3.svg'),
  },
  gender: {
    malePurple: require('../assets/images/icon-male-purple.svg'),
    femalePurple: require('../assets/images/icon-female-purple.svg'),
  },
  placeholder: {
    avatar: require('../assets/images/placeholder-avatar.jpg'),
    default: require('../assets/images/placeholder.png'),
  }
};
