import React from 'react';

import './styles.css';

const HeadingWithIcon = ({ icon, heading, subtitle}) => (
    <div className="HeadingWithIconContainer">
        <div className="HeadingWithIconLeftColumn">
            <img className="HeadingWithIconImage" src={icon} alt="icon"/>
        </div>

        <div className="HeadingWithIconTitles">
            <p className="HeadingWithIconHeading">{heading}</p>
            <p className="HeadingWithIconSubtitle">{subtitle}</p>
        </div>
    </div>
);

export default HeadingWithIcon;
