import React, { Component } from 'react';

import './styles.css';

export default class AnimatedProgressBar extends Component {
  state = {
    width: 0
  };

  componentDidMount() {
    this.startAnimation(() => {
      this.setState({ width: '100%' });
      this.props.onLoad()
    });
  }

  startAnimation = (callback) => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        callback();
      });
    });
  }

  render() {
    return (
      <div className="AnimatedProgressBarContainer">
        <div 
          className="AnimatedProgressBarBar"
          style={{ 
            width: this.state.width,
            transitionDuration: `${this.props.duration}ms`
          }}
        >
        </div>
      </div>
    );
  }
}
