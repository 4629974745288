import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import './styles.css';
import Subheading from '../Subheading';
import ProfileInput from '../ProfileInput';
import ProfileSelectInput from '../ProfileSelectInput';
import { Images } from '../../config/images';
import { dateStringToDate, newDateBySubstractYear, stringToDate } from '../../helpers/date';

const PersonalInformationEditor = ({ profile, onNameChanged, onCityChanged, onCountryChanged, onBirthdateChanged, onGenderChanged }) => (
  <div className="PersonalInformationEditorContainer">
    <div className="PersonalInformationEditorHeading">
      <Subheading
        heading={'Personal information'}
      />
    </div>

    <div className="PersonalInformationEditorInput">
      <ProfileInput
        value={profile.name}
        onChangeText={onNameChanged}
        placeholder={'Name'}
        required
      />

      <ProfileInput
        value={profile.city}
        onChangeText={onCityChanged}
        placeholder={'City'}
        required
      />

      <ProfileInput
        value={profile.country}
        onChangeText={onCountryChanged}
        placeholder={'Country'}
        required
      />

      <div className="PersonalInformationEditorDatePickerContainer">
        <img
          className="PersonalInformationEditorDateIcon"
          src={Images.icon.calendarGreen}
          alt="Calendar"
        />
        <DatePicker
          className="PersonalInformationEditorDatePicker"
          selected={profile.birthday !== null ? dateStringToDate(profile.birthday) : null}
          onChange={onBirthdateChanged}
          onChangeRaw={(event) => {
            const dateString = event.target.value
            const date = stringToDate(dateString, 'dd-MM-yyyy', '-')
            if (date) {
             onBirthdateChanged(date)
            }
           }}
          dateFormat={'dd-MM-yyyy'}
          placeholderText={'Birthdate'}
          maxDate={newDateBySubstractYear(18)}
          dateFormatCalendar="MMMM"
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100}
        />
      </div>

      <ProfileSelectInput
        value={profile.gender}
        values={['male', 'female']}
        onChangeText={onGenderChanged}
        placeholder={'Gender'}
        safariPlaceholderIndent={66}
        safariOptionsIndent={[120, 106]}
        required
      />
    </div>
  </div>
);

export default PersonalInformationEditor;
