import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './styles.css';
import Title from '../../components/Title';
import ButtonPurple from '../../components/ButtonPurple';
import { AdmissionPaths } from '../../config/paths';

class About extends Component {
    testHandler = () => {
      const { history } = this.props;
      history.replace(AdmissionPaths.admissionStart);
    }

    render() {
        const explanation = 'Attraction Dating is for people with amazing energy vibration, which is the base of the law of attraction.\n\n' +
            'The journey to attract my love partner has also been through energy and attraction. It is my dream to bring you and your soulmate together.\n\n' +
            'Enjoy the experience and don’t hesitate to contact me with feedback or questions.\n\n' +
            'With love created!\n\n' +
            'High five Aura Kuipers\n' +
            'Creator of Attraction Dating';

        return (
          <div className="AboutContainer">
            <div className="AboutBackground" />
              <div className="AboutFormContainer">
                <Title title={'Hi beautiful soul'}/>

                <p className="AboutText">{explanation}</p>

                <ButtonPurple
                  label={'Back to test'}
                  onPress={() => { 
                      this.testHandler();
                  }}
                />
            </div>
          </div>
        );
    }
}

export default withRouter(About);
