import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchProfileRequest } from '../../actions/ProfileActions';
import { setUserGeoLocation } from '../../actions/UserActions';
import { AuthPaths, MainPaths } from '../../config/paths';
import { setDirectLink } from '../../actions/PersistActions';

import Auth from '../Auth';
import Admission from '../Admission';
import Main from '../Main';

class Start extends Component {
  componentDidMount() {
    this.validateToken();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile.fetchTimestamp === 0) {
      this.props.fetchProfileRequest(nextProps.persist.token);
    } 
  }

  validateToken = () => {
    if (this.props.persist.token && this.props.persist.token.length) {
      this.props.fetchProfileRequest(this.props.persist.token, true);
    } else {
      this.resetCurrentPathIfNeeded();
    }
  };

  handleDirectLinks = (pathname) => {
    // handle direct links, links that need to be opened after login
    if (pathname === MainPaths.subscription) {
      this.props.setDirectLink(MainPaths.subscription)
    }
  }

  resetCurrentPathIfNeeded = () => {
    const { pathname } = this.props.location;
    const { history } = this.props;
    
    var exists = Object.keys(AuthPaths)
      .some((key) => AuthPaths[key] === pathname);
    if (!exists) {
      this.handleDirectLinks(pathname);
      history.replace(AuthPaths.login);
    }
  }

  render() {
    const { profile } = this.props;
    let loggedIn = profile.fetchTimestamp !== 0;

    if (loggedIn) {
      if (profile.admissionRequired === true) {
        return <Admission/>
      } else {
        return <Main/>
      }
    } else {
      return <Auth/>
    }
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      profile: store.profile,
  };
};

const mapDispatchToProps = {
  setUserGeoLocation,
  fetchProfileRequest,
  setDirectLink
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Start)
);
