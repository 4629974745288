import React from 'react';

import './styles.css';
import { Images } from '../../config/images';

const ProfileImage = ({ profilePicture }) => (
      <div className="ProfileImageContainer">
        <img
          className="ProfileImageBackground"
          src={Images.circles.matches1}
          alt="circles"
        />
        <img
          className="ProfileImageProfile"
          src={profilePicture}
          alt="profile"
        />
      </div>
);

export default ProfileImage;
