import React, { Component } from 'react';

import './styles.css';
import Subheading from '../Subheading';
import ButtonCirclePurple from '../ButtonCirclePurple';
import AnimatedProgressBar from '../AnimatedProgressBar';
import ButtonPrimary from '../ButtonPrimary';

import { Images } from '../../config/images';

export default class VoiceMessageRecorder extends Component {
  duration = 80000
  timer = null

  startCounter = () => {
    this.timer = setTimeout(() => {
      this.props.onRecordPressed();
    }, this.duration)
  }

  render() {
    const { isPlaying, isRecording, voice } = this.props;
    return (
      <div className="VoiceMessageRecorderContainer">
        <Subheading
          heading={'Voice message'}
          required
        />
        <div className="VoiceMessageRecorderButtonContainer">
          <ButtonCirclePurple
            icon={isRecording ? Images.icon.stopWhite : Images.icon.microphoneWhite}
            small={isRecording ? true : false}
            onPress={() => {
                clearTimeout(this.timer);
                this.props.onRecordPressed();
            }}
          />
        </div>
         
        {
          isRecording ? (
            <div className="VoiceMessageRecorderAnimation">
              <AnimatedProgressBar 
              duration={this.duration}
              onLoad={() => {
                this.startCounter();
              }}
            />
            </div>
          ) : (
            <div className="VoiceMessageRecorderPreview">
              { voice ?
                <ButtonPrimary
                label={isPlaying ? 'Stop' : 'Listen'}
                onPress={() => {
                    this.props.onPlayPressed();
                }}
                />
                :
                <p className="VoiceMessageRecorderText">You have no voice message</p>
              }
            </div>
          )
        }

      </div>
    );
  }
}
