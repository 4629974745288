import React from 'react';

import ButtonPurple from '../ButtonPurple';
import subscriptionStatusTypes from '../../config/subscriptionStatusTypes';
import './styles.css';

const SubscriptionCard = ({ title, price, interval, descriptions, status, onGoPremium }) => {

  const renderActionStatus = () => {
    switch(status) {
      case subscriptionStatusTypes.inactive:
      case subscriptionStatusTypes.trial:
        return (
          <ButtonPurple
            label={'Go premium'}
            onPress={onGoPremium}
            large
            fill
          />
        );
      case subscriptionStatusTypes.premium_trial:
      case subscriptionStatusTypes.premium_single:
      case subscriptionStatusTypes.premium:
      case subscriptionStatusTypes.grace:
        return (
          <p className="SubscriptionCardStatusDescription">{'Currently active'}</p>
        );
      case subscriptionStatusTypes.pending:
        return (
          <p className="SubscriptionCardStatusDescription">{'Purchase pending'}</p>
        );
      default:
        return (
          <p className="SubscriptionCardStatusDescription">{status}</p>
        );
    }

  }

  const active = status === subscriptionStatusTypes.premium_trial ||
                 status === subscriptionStatusTypes.premium ||
                 status === subscriptionStatusTypes.premium_single ||
                 status === subscriptionStatusTypes.grace

  return (
    <div className={`SubscriptionCard ${active ? 'SubscriptionCardActive' : ''}`}>
      <p className="SubscriptionCardTitle">{title}</p>
      <p className="SubscriptionCardPrice">{`€ ${price},-`}</p>
      <p className="SubscriptionCardInterval">{`/${interval}`}</p>

      <div className="SubscriptionCardSeparator"/>

      {descriptions.map((description, index) => <p key={index} className="SubscriptionCardDescription">{description}</p>)}

      <div className="SubscriptionCardStatus">
        {renderActionStatus()}
      </div>

    </div>
  );
}

export default SubscriptionCard;
