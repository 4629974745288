import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './styles.css';
import styles from './styles';
import { Images } from '../../config/images';

import Header from '../../components/Header';
import TitleBar from '../../components/TitleBar';
import Quote from '../../components/Quote';
import ButtonPurple from '../../components/ButtonPurple';

class Settings extends Component {
  render() {
    const quoteImage = Images.background.quoteBackground;
    const quoteText = 'Everything is Energy';
    const quoteContent = 'Be the energy you want to attract!';
    
    return (
      <div className="MenuPage SettingsContainer">
        <img className="SettingsCorner"
          src={Images.background.cornerCircle}
          alt="cornerCircle"
        />

        <Header shadow/>

        <TitleBar title={'Settings'}/>

        <div className="SettingsContent">
          <div className="SettingsButtonsContainer">
            <div className="SettingsButtonLeft">
              <ButtonPurple
                label={'Privacy Policy'}
                onPress={() =>
                  window.open('https://attraction.dating/privacy/', '_blank')
                }
                style={styles.buttonPurple}
                fill
              />
            </div>
            
            <div className="SettingsButtonRight">
              <ButtonPurple
                label={'Disclaimer'}
                onPress={() =>
                  window.open('https://attraction.dating/disclaimer/', '_blank')
                }
                style={styles.buttonPurple}
                fill
              />
            </div>
          </div>

          <div className="SettingsAroundQuote">
            <Quote
              title={quoteText}
              content={quoteContent}
              imageSource={quoteImage}
            />
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(Settings)
);
