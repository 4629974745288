import React from 'react';
import './styles.css';

const LoginInput = (
  {
      value, onChangeText, placeholder, secureTextEntry, onSubmit, style
  }) => (

        <div style={style} className="InputContainer">
          <input
            className="InputTextInput"
            type={secureTextEntry ? 'password' : 'text'}
            value={value}
            onChange={(event) => onChangeText(event.target.value)}
            placeholder={placeholder}
            onKeyPress={event => {
              if (event.key === 'Enter' && onSubmit && value !== '') {
                onSubmit()
              }
            }}
          />
        </div>
      
  );

export default LoginInput;