import {
  RESET_REDUCERS,
  MATCH_PROFILE_FETCH,
  MATCH_PROFILE_FETCH_SUCCESS,
  MATCH_PROFILE_FETCH_FAIL, MATCH_PROFILE_FAVORITE_SUCCESS,
  MATCH_PROFILE_CLEAR,
} from '../actions/types';

const INITIAL_STATE = {
  id: null,
  name: null,
  profileImage: null,
  dream: null,
  dreamImage: null,
  voice: {},
  city: null,
  country: null,
  age: null,
  mentors: [],
  photos: [],
  level: 0,
  liked: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case RESET_REDUCERS:
          return {
              ...INITIAL_STATE,
          };

      case MATCH_PROFILE_FETCH:
          return {
              ...state,
              id: null,
          };

      case MATCH_PROFILE_FETCH_SUCCESS:
          return {
              ...state,
              id: action.payload.id,
              name: action.payload.name,
              profileImage: action.payload.profile_image,
              dream: action.payload.dream.text,
              dreamImage: action.payload.dream.image,
              voice: action.payload.voice,
              city: action.payload.city,
              country: action.payload.country,
              age: action.payload.age,
              mentors: action.payload.mentors,
              photos: action.payload.photos,
              level: action.payload.level,
              liked: action.payload.liked
          };

      case MATCH_PROFILE_FETCH_FAIL:
          return {
              ...state,
              ...INITIAL_STATE,
          };

      case MATCH_PROFILE_FAVORITE_SUCCESS:
          return {
              ...state,
              liked: true
          };
      case MATCH_PROFILE_CLEAR:
        return {
          ...INITIAL_STATE,
        };
          

      default:
          return state;
  }
};
