import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  nextStep,
  previousStep,
  changeAnswerForCurrentStep,
  submitAdmission
} from '../../actions/AdmissionActions';

import './styles.css'
import { Images } from '../../config/images';
import { AdmissionPaths } from '../../config/paths';
import Header from '../../components/Header';
import Pagination from '../../components/Pagination';
import Question from '../../components/Question';
import Answers from '../../components/Answers';
import ButtonPurple from '../../components/ButtonPurple';
import Spinner from '../../components/Spinner';

class AdmissionQuestion extends Component {
  UNSAFE_componentWillMount() {
    if (!this.questionsExists()) {
      const { history } = this.props;
      history.replace(AdmissionPaths.admissionStart);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.admission.error !== null) {
      console.log('Error', props.admission.error);
    } else if (props.admission.submitted === true) {
      this.admissionResultHandler();
    }
  }

  submitAdmission = () => {
    const { questions, answersGiven } = this.props.admission;
    const admission = [];

    for (let index = 0; index < questions.length; index++) {
      admission.push({
        question_id: questions[index].id,
        answer_id: answersGiven[index]
      });
    }

    this.props.submitAdmission(this.props.persist.token, { admission });
  }

  goToNextStepIfPossible = (givenAnswer, isLastQuestion) => {        
    if (givenAnswer === undefined) {
      return;
    }

    if (isLastQuestion) {
      this.submitAdmission();
    } else {
      this.props.nextStep();
    }
  }

  goToPreviousStep = (currentStep) => {
    const isFirstQuestion = currentStep === 1;

    if (isFirstQuestion) {
      const { history } = this.props;
      history.goBack();
    } else {
      this.props.previousStep();
    }
}

  admissionResultHandler = () => {
    const { history } = this.props;
    history.push(AdmissionPaths.admissionResult);
  }

  questionsExists = () => {
    const { questions } = this.props.admission;
    if (questions === undefined || questions.length === 0) {
      return false;
    }
    return true;
  }

  render() {
    if (!this.questionsExists()) {
      return <Spinner/>;
    }
    const { currentStep, questions, answersGiven } = this.props.admission;
    const question = questions[currentStep - 1];
    const givenAnswer = answersGiven[currentStep - 1];
    const isLastQuestion = (currentStep === questions.length);

    return (
      <div className="AdmissionQuestionContainer">
        <img className="AdmissionQuestionCorner"
          src={Images.background.cornerCircle}
          alt="cornerCircle"
        />
        <Header text={'Consciousness level check'}/>
        <Pagination
          current={currentStep}
          total={questions.length}
        />
        <div className="AdmissionQuestionBody">
          <Question
            text={question.question}
            image={question.image}
          />
          <div className="AdmissionQuestionAnswersContainer">

            <Answers
              givenAnswer={givenAnswer}
              answers={question.answer}
              onPress={(id) => { 
              this.props.changeAnswerForCurrentStep(id);
              }}
            />

            <div className="AdmissionQuestionButton">
              <ButtonPurple
                label={isLastQuestion ? 'Submit' : 'Next question'}
                onPress={() => { 
                  this.goToNextStepIfPossible(givenAnswer, isLastQuestion);
                }}
                arrowRight
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      admission: store.admission,
  };
};

const mapDispatchToProps = {
  nextStep,
  previousStep,
  changeAnswerForCurrentStep,
  submitAdmission
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdmissionQuestion)
);

