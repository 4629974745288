import React from 'react';

import './styles.css';
import styles from './styles';
import { dateToReadableDateTime } from '../../helpers/date';

const ChatBoxText = ({ text, timestamp, profileImage, isFromSelf, style, boxStyle }) => {
  const textStyle = (isFromSelf) ? styles.textSelf : styles.textOther;
  const textDate = timestamp ? dateToReadableDateTime(new Date(timestamp * 1000)) : 'Sending message...';
  return (
    <div className="ChatBoxTextContainer" style={style}>
      {profileImage}
      <div className={`ChatBoxTextMessageContainer ${!isFromSelf ? 'ChatBoxTextMessageContainerPartner' : ''}`} style={boxStyle}>
          <p className="ChatBoxTextText" style={textStyle}>{text}</p>
          <p className="ChatBoxTextDateText">{textDate}</p>
      </div>
    </div>
  );
};

export default ChatBoxText;
