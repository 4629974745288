import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { usernameChanged, passwordChanged, loginUser } from '../../actions/AuthActions';

import './styles.css';
import Title from '../../components/Title';
import LoginForm from '../../components/LoginForm';
import FooterPreAuth from '../../components/FooterPreAuth';
import Spinner from '../../components/Spinner';
import { AuthPaths } from '../../config/paths';

class Login extends Component {

  signUpHandler = () => {
    const { history } = this.props;
    history.push(AuthPaths.signUp);
  }

  lostPasswordHandler = () => {
    const { history } = this.props;
    history.push(AuthPaths.lostPassword);
  }

  renderSpinner = () => {
    if (this.props.auth.loadingMessage.length > 0) {
        return <Spinner status={this.props.auth.loadingMessage} />;
    }
  };

  render() {
    //render initial loading if needed
    const { profile, persist } = this.props;
    if (persist.token && persist.token.length) {
      let waitForProfileFetch = profile.fetchTimestamp === 0;
      if (waitForProfileFetch) {
        return <Spinner/>;
      }
    }

    return (
      <div className="LoginContainer">
        <div className="LoginBackground" />
        <div className="LoginFormContainer">
          <Title title={'Sign in'} />
          <LoginForm
            error={this.props.auth.error}
            email={this.props.auth.username}
            emailChangeText={(value) => { this.props.usernameChanged(value); }}
            password={this.props.auth.password}
            passwordChangeText={(value) => { this.props.passwordChanged(value); }}
            submitAction={() => {
              if (this.props.auth.username !== '' && this.props.auth.password !== '')
              this.props.loginUser(this.props.auth.username, this.props.auth.password);
            }}
            onLostPasswordPress={() => { this.lostPasswordHandler()}}
          />
          <FooterPreAuth
            text={'Need an account?'}
            buttonText={'Sign up!'}
            onPress={this.signUpHandler}
          />
          {this.renderSpinner()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      auth: store.auth,
      profile: store.profile
  };
};

const mapDispatchToProps = {
  usernameChanged,
  passwordChanged,
  loginUser,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Login)
);
