import React from 'react';

import './styles.css';
import { Images } from '../../config/images';
import { isSafari } from '../../helpers/utils';

const SelectInput = (
    {
        value, values, onChangeText, placeholder, required, onDelete = undefined, safariPlaceholderIndent, safariOptionsIndent
    }) => (
    <div className="SelectInputContainer">
        <div className="SelectInputInnerContainer">
            <select
              value ={value ? value: ''}
              // fix for safari option center
              style={isSafari() ? ( value ? { textIndent: safariOptionsIndent[values.indexOf(value)] } : { textIndent: safariPlaceholderIndent }) : null}
              className={`SelectInputSelector ${!value ? 'SelectInputPlaceholder' : ''}`}
              onChange={(event) => {
                const value = event.target.value;
                if (value !== placeholder) {
                onChangeText(event.target.value)
                }
              }}
            >
              <option value={null} key={placeholder}>{placeholder}</option>
              {values.map((value, index) => <option value={value} key={index}>{value}</option>)}
            </select>
            <div className="SelectInputTriangle"></div>
            {
                onDelete !== undefined ? (
                    <button onClick={onDelete} className="SelectInputDeleteButton">
                        <img src={Images.icon.trashBinPurple} className="SelectInputDeleteIcon" alt="Delete" />
                    </button>
                ) : (
                    null
                )
            }
        </div>
        {required ? <p className="SelectInputRequired">*</p> : null}
    </div>
);

export default SelectInput;
