import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchProfileRequest } from '../../actions/ProfileActions';
import {
  genderChanged,
  nameChanged,
  usernameChanged,
  passwordChanged,
  birthdateChanged,
  acceptedTermsChanged,
  signUpRequest
} from '../../actions/SignUpActions';

import './styles.css';
import styles from './styles';
import Spinner from '../../components/Spinner';
import Title from '../../components/Title';
import SignUpForm from '../../components/SignUpForm';
import FooterPreAuth from '../../components/FooterPreAuth';
import { AuthPaths } from '../../config/paths';
import { dateToDateString } from '../../helpers/date';

class SignUp extends Component {
  signUp = () => {
    const { username, password, name, gender, birthdate } = this.props.signUp;
    const signUpData = { username, password, name, gender, dob: birthdate };
    this.props.signUpRequest(signUpData);
  };

  loginHandler = () => {
    const { history } = this.props;
    history.push(AuthPaths.login);
  };

  renderSpinner = () => {
    if (this.props.signUp.loadingMessage.length > 0 ) {
        return <Spinner status={this.props.signUp.loadingMessage} />;
    }
  };

  render() {
    return (
      <div className="SignupContainer">
        <div className="SignupBackground" />
        <div className="SignupFormContainer">
          <Title title={'Sign up'} style={styles.titleStyle}/>
          <SignUpForm
            error={this.props.signUp.error}
            gender={this.props.signUp.gender}
            genderChanged={(gender)=> { this.props.genderChanged(gender); }}
            name={this.props.signUp.name}
            nameChangeText={(value) => { this.props.nameChanged(value); }}
            birthdate={this.props.signUp.birthdate}
            onBirthdateChanged={(date) => { this.props.birthdateChanged(date ? dateToDateString(date) : null); }}
            email={this.props.signUp.username}
            emailChangeText={(value) => { this.props.usernameChanged(value); }}
            password={this.props.signUp.password}
            passwordChangeText={(value) => { this.props.passwordChanged(value); }}
            acceptedTerms={this.props.signUp.acceptedTerms}
            acceptedTermsChanged={(value) => { this.props.acceptedTermsChanged(value); }}
            onPress={this.signUp}
          />
          <FooterPreAuth
            text={'Already have an account?'}
            buttonText={'Login'}
            onPress={this.loginHandler}
          />
          {this.renderSpinner()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      signUp: store.signUp,
      profile: store.profile
  };
};

const mapDispatchToProps = {
  genderChanged,
  nameChanged,
  usernameChanged,
  passwordChanged,
  birthdateChanged,
  acceptedTermsChanged,
  signUpRequest,
  fetchProfileRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUp);
