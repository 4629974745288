import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getChatHistory, setCurrentChatUser } from '../../actions/ChatActions';
import { Images } from '../../config/images';
import { Colors } from '../../config/colors';
import { showInfoAlert } from '../../components/Alert';

import './styles.css';
import Header from '../../components/Header';
import ProfileWithLevel from '../../components/ProfileWithLevel';
import TitleBar from '../../components/TitleBar';
import ChatHistoryList from '../../components/ChatHistoryList';
import { MainPaths } from '../../config/paths';

class ChatHistory extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.chat.fetchingHistory === true && nextProps.chat.fetchingHistory === false) {
      //History just fetched
      if (nextProps.chat.error !== '') {
          //Retry?
          showInfoAlert('Error', nextProps.chat.error, 'Ok');
      }
    } else if (this.props.chat.currentChatUser.user_id !== nextProps.chat.currentChatUser.user_id) {
      this.openChat();
    }
  }

  componentDidMount() {
    this.props.getChatHistory(this.props.persist.token);
  }

  openChat = () => {
    const { history } = this.props;
    history.replace(MainPaths.chat);
  }

  removeDuplicateHistory = (history) => {
    // will use latest object in case of more invitation_id
    const filteredHistory = history.reduce((prev, current) => {
      const found = prev.find(item => item.invitation_id === current.invitation_id);
      if (!found) {
        return prev.concat([current]);
      } else {
        var foundIndex = prev.findIndex(item => item.invitation_id === found.invitation_id);
        prev[foundIndex] = current;
        return prev;
      }
    }, []);
    
    return filteredHistory
  }

  render() {
    const { profile } = this.props;
    const profileImage = this.props.profile.profileImage !== null ?
        this.props.profile.profileImage : Images.placeholder.avatar;
    const levelColor = { backgroundColor: Colors.level[profile.level] };
    const { history } = this.props.chat;
    const filteredHistory = this.removeDuplicateHistory(history);

    return (
      <div className="MenuPage ChatHistoryContainer">
        <img className="ChatHistoryCorner"
          src={Images.background.cornerCircle}
          alt="cornerCircle"
        />

        <Header shadow/>
        <div className="ChatHistoryProfileImage">
            <ProfileWithLevel 
              levelColor={levelColor}
              level={profile.level}
              profilePicture={profileImage}
            />
        </div>

        <TitleBar title={'Chat history'}/>

        <ChatHistoryList
          history={filteredHistory}
          onUserPress={(user) => {
            if (this.props.chat.currentChatUser.user_id !== user.user_id) {            
              this.props.setCurrentChatUser(user);
            } else {
              this.openChat();
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
      persist: store.persist,
      profile: store.profile,
      chat: store.chat,
  };
};

const mapDispatchToProps = {
  getChatHistory,
  setCurrentChatUser,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ChatHistory)
);
