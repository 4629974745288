import {
  MATCH_PROFILE_FETCH,
  MATCH_PROFILE_FETCH_SUCCESS,
  MATCH_PROFILE_FETCH_FAIL,
  MATCH_PROFILE_FAVORITE,
  MATCH_PROFILE_FAVORITE_SUCCESS,
  MATCH_PROFILE_FAVORITE_FAIL,
  MATCH_PROFILE_CLEAR,
} from './types';
import { get, post } from '../helpers/api';
import { Api } from '../config/api';

export const matchProfileRequest = (token, id) => {
  return dispatch => {
      dispatch({
          type: MATCH_PROFILE_FETCH,
          payload: 'Loading profile',
      });

      get(token, Api.requests.matchProfile(id)).then(response => {
          dispatch({
              type: MATCH_PROFILE_FETCH_SUCCESS,
              payload: {
                  ...response.data,
                  id,
              },
          });
      }).catch((e) => {
          console.log(e);
          dispatch({ type: MATCH_PROFILE_FETCH_FAIL });
      });
  };
};

export const sendFavorite = (token, id) => {
  return dispatch => {
      dispatch({
          type: MATCH_PROFILE_FAVORITE,
          payload: 'Loading profile',
      });

      const body = { id };

      post(token, Api.requests.favorite, body).then(() => {
          dispatch({ 
              type: MATCH_PROFILE_FAVORITE_SUCCESS
          });
      }).catch((e) => {
          console.log(e);
          dispatch({ type: MATCH_PROFILE_FAVORITE_FAIL });
      });
  };
};

export const matchProfileClear = (token, id) => {
  return {
    type: MATCH_PROFILE_CLEAR,
  };
}
