import {
  PROFILE_FETCH,
  PROFILE_FETCH_SUCCESS,
  PROFILE_FETCH_FAIL,
  PROFILE_UPDATE,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_CANCEL,
  PROFILE_UPLOAD_PHOTO,
  PROFILE_UPLOAD_PHOTO_SUCCESS,
  PROFILE_UPLOAD_PHOTO_FAIL,
  PROFILE_UPLOAD_DREAM_PHOTO,
  PROFILE_UPLOAD_DREAM_PHOTO_SUCCESS,
  PROFILE_UPLOAD_DREAM_PHOTO_FAIL,
  PROFILE_DELETE_PHOTO,
  PROFILE_DELETE_PHOTO_SUCCESS,
  PROFILE_DELETE_PHOTO_FAIL,
  PROFILE_UPLOAD_VOICE,
  PROFILE_UPLOAD_VOICE_SUCCESS,
  PROFILE_UPLOAD_VOICE_FAIL,
  PROFILE_DREAM_CHANGED,
  PROFILE_NAME_CHANGED,
  PROFILE_CITY_CHANGED,
  PROFILE_COUNTRY_CHANGED,
  PROFILE_BIRTHDAY_CHANGED,
  PROFILE_GENDER_CHANGED,
  PROFILE_ADD_MENTOR,
  PROFILE_MENTOR_CHANGED,
  PROFILE_MENTOR_REMOVED,
  PROFILE_MENTOR_SUBMIT,
  PROFILE_MENTOR_SUBMIT_SUCCESS,
  PROFILE_MENTOR_SUBMIT_FAIL,
  PROFILE_REORDERING_PHOTOS,
  PROFILE_PHOTOS_ORDER_CHANGED,
  PROFILE_ADMISSION_COMPLETED,
  RESET_REDUCERS
} from './types';
import { get, post, deletePost } from '../helpers/api';
import { Api } from '../config/api';
import notificationToast from '../components/NotificationToast';

export const fetchProfileRequest = (token, forceReset = false) => {
  return dispatch => {
      dispatch({
          type: PROFILE_FETCH,
          payload: 'Loading profile',
      });

      get(token, Api.requests.profile).then(response => {
          dispatch({
              type: PROFILE_FETCH_SUCCESS,
              payload: response.data,
          });
      }).catch((e) => {
          console.log('ERROR FETCH PROFILE', e);
          dispatch({ type: PROFILE_FETCH_FAIL });
          if (forceReset) {
            dispatch({ type: RESET_REDUCERS });
          }
      });
  };
};

export const updateProfileRequest = (token, data) => {
    return dispatch => {
        dispatch({
            type: PROFILE_UPDATE,
            payload: 'Updating profile',
        });

        post(token, Api.requests.profile, data).then(response => {
            notificationToast('Profile updated!');
            dispatch({
                type: PROFILE_UPDATE_SUCCESS,
                payload: response.data,
            });
        }).catch((e) => {
            console.log(e);

            let payload = 'Something went wrong.';
            if (e.response.data) {
                const errors = e.response.data.errors;
                if (errors) {
                    payload = errors[Object.keys(errors)[0]][0];
                }
            }

            dispatch({
                type: PROFILE_UPDATE_FAIL,
                payload
            });
        });
    };
};

export const updateProfileCancel = () => {
  return {
      type: PROFILE_UPDATE_CANCEL
  };
};

export const updateMentorsRequest = (token, data) => {
  return dispatch => {
      dispatch({
          type: PROFILE_MENTOR_SUBMIT,
          payload: 'Updating mentors',
      });

      post(token, Api.requests.mentor, data).then(response => {
          notificationToast('Inspiring people updated!');
          dispatch({
              type: PROFILE_MENTOR_SUBMIT_SUCCESS,
              payload: response.data,
          });
      }).catch((e) => {
          console.log(e);
          dispatch({ type: PROFILE_MENTOR_SUBMIT_FAIL });
      });
  };
};

export const uploadProfilePhoto = (token, data) => {
  return dispatch => {
      dispatch({
          type: PROFILE_UPLOAD_PHOTO,
          payload: 'Uploading photo',
      });

      post(token, Api.requests.profileUploadPhoto, data).then(response => {
          notificationToast('Profile photo uploaded!');
          dispatch({
              type: PROFILE_UPLOAD_PHOTO_SUCCESS,
              payload: response.data,
          });
      }).catch((e) => {
          console.log(e);
          dispatch({ type: PROFILE_UPLOAD_PHOTO_FAIL });
      });
  };
};

export const uploadProfilePhotoChunks = (token, chunks) => {
  return async dispatch => {
      dispatch({
          type: PROFILE_UPLOAD_PHOTO,
          payload: 'Uploading photo',
      });

      await chunks.forEach((chunk, index) => {
        if (index < chunks.length - 1) {
          post(token, Api.requests.profileUploadPhoto, chunk).catch((e) => {
              console.log(e);
              dispatch({ type: PROFILE_UPLOAD_PHOTO_FAIL });
          });
        }
      })
      // send last chunk last
      post(token, Api.requests.profileUploadPhoto, chunks[chunks.length - 1]).then(response => {
        notificationToast('Profile photo uploaded!');
        dispatch({
            type: PROFILE_UPLOAD_PHOTO_SUCCESS,
            payload: response.data,
        });
      }).catch((e) => {
          console.log(e);
          dispatch({ type: PROFILE_UPLOAD_PHOTO_FAIL });
      });

  };
};

export const deleteProfilePhoto = (token, id) => {
  return dispatch => {
      dispatch({
          type: PROFILE_DELETE_PHOTO,
          payload: 'Deleting photo',
      });

      deletePost(token, Api.requests.profileDeletePhoto(id)).then(response => {
          if (response.data[0] === true) {
              notificationToast('Profile photo deleted!');
              dispatch({
                  type: PROFILE_DELETE_PHOTO_SUCCESS,
                  payload: id,
              });
          }
      }).catch((e) => {
          console.log(e);
          dispatch({ type: PROFILE_DELETE_PHOTO_FAIL });
      });
  };
};

export const changeProfilePhotosOrder = (token, order, photos) => {
  return dispatch => {
      // photos.sort((a, b) => {
      //     return order.photos.indexOf(a.id) - order.photos.indexOf(b.id);
      //  });

      dispatch({
          type: PROFILE_PHOTOS_ORDER_CHANGED,
          payload: photos,
      });

      post(token, Api.requests.profileReorderPhotos, order).then(response => {
        if (response.data) {
          notificationToast('Profile photos order changed!');
        }
      })
      .catch((e) => {
          console.log(e);
      });
  };
};

export const uploadDreamPhoto = (token, data) => {
  return dispatch => {
      dispatch({
          type: PROFILE_UPLOAD_DREAM_PHOTO,
          payload: 'Uploading dream photo',
      });

      post(token, Api.requests.uploadDreamPhoto, data).then(response => {
          notificationToast('Dream photo uploaded!');
          dispatch({
              type: PROFILE_UPLOAD_DREAM_PHOTO_SUCCESS,
              payload: response.data.url
          });
      }).catch((e) => {
          console.log(e);
          dispatch({ type: PROFILE_UPLOAD_DREAM_PHOTO_FAIL });
      });
  };
};

export const uploadDreamPhotoChunks = (token, chunks) => {
  return async dispatch => {
      dispatch({
          type: PROFILE_UPLOAD_DREAM_PHOTO,
          payload: 'Uploading dream photo',
      });

      await chunks.forEach((chunk, index) => {
        if (index < chunks.length - 1) {
          post(token, Api.requests.uploadDreamPhoto, chunk).catch((e) => {
              console.log(e);
              dispatch({ type: PROFILE_UPLOAD_DREAM_PHOTO_FAIL });
          });
        }
      })

      // send last chunk last
      post(token, Api.requests.uploadDreamPhoto, chunks[chunks.length - 1]).then(response => {
        notificationToast('Dream photo uploaded!');
        dispatch({
            type: PROFILE_UPLOAD_DREAM_PHOTO_SUCCESS,
            payload: response.data.url
        });
      }).catch((e) => {
          console.log(e);
          dispatch({ type: PROFILE_UPLOAD_DREAM_PHOTO_FAIL });
      });
  };
};

export const uploadVoice = (token, data) => {
  return dispatch => {
      dispatch({
          type: PROFILE_UPLOAD_VOICE,
          payload: 'Uploading voice message',
      });
      post(token, Api.requests.uploadVoice, data).then(response => {
          notificationToast('Voice uploaded!');
          dispatch({
              type: PROFILE_UPLOAD_VOICE_SUCCESS,
              payload: response.data,
          });
      }).catch((e) => {
          console.log(e);
          dispatch({ type: PROFILE_UPLOAD_VOICE_FAIL });
      });
  };
};

export const dreamChanged = input => {
  return {
      type: PROFILE_DREAM_CHANGED,
      payload: input
  };
};

export const nameChanged = input => {
  return {
      type: PROFILE_NAME_CHANGED,
      payload: input
  };
};

export const cityChanged = input => {
  return {
      type: PROFILE_CITY_CHANGED,
      payload: input
  };
};

export const countryChanged = input => {
  return {
      type: PROFILE_COUNTRY_CHANGED,
      payload: input
  };
};

export const birthDayChanged = input => {
  return {
      type: PROFILE_BIRTHDAY_CHANGED,
      payload: input
  };
};

export const genderChanged = input => {
  return {
      type: PROFILE_GENDER_CHANGED,
      payload: input
  };
};

export const addMentor = () => {
  return {
      type: PROFILE_ADD_MENTOR
  };
};

export const mentorChanged = (mentors, key, input) => {
  const newMentors = [...mentors];
  newMentors[key] = input;

  return {
      type: PROFILE_MENTOR_CHANGED,
      payload: newMentors,
  };
};

export const removeMentor = (mentors, key) => {
  const newMentors = [...mentors];
  newMentors.splice(key, 1);

  return {
      type: PROFILE_MENTOR_REMOVED,
      payload: newMentors,
  };
};

export const reorderingPhotos = (reordering) => {
  return {
      type: PROFILE_REORDERING_PHOTOS,
      payload: reordering
  };
};

export const admissionCompleted = () => {
  return {
      type: PROFILE_ADMISSION_COMPLETED
  };
};
