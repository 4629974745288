import React from 'react';
import './styles.css'
import { Images } from '../../config/images';

const ButtonRed = ({ label, onPress, arrowRight, large, small, fill, disabled = false, style }) => (
  <button 
    style={style} 
    className={
      `ButtonRed
       ${large ? 'ButtonLargeRed' : ''}
       ${small ? 'ButtonSmallRed' : ''}
       ${fill ? 'ButtonRedFill' : ''}`
    } 
    onClick={onPress} 
    disabled={disabled}>
    {label}
    {arrowRight ? <img src={Images.icon.arrowRightWhite} className="ButtonRedIcon" alt="arrowRight"/> : false}
  </button>
);

export default ButtonRed;