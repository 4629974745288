import React from 'react';

import './styles.css';
import { Images } from '../../config/images';

const InspiringPeopleListItem = ({ name }) => (
    <div className="InspiringPeopleListItemContainer">
        <div className="InspiringPeopleListItemAroundIcon">
            <img src={Images.icon.personIdeaWhite} alt="icon"/>
        </div>

        <p className="InspiringPeopleListItemName">{name}</p>
    </div>
);

export default InspiringPeopleListItem;
