import { Colors } from '../../config/colors';

const styles = {
  ColorSelf: {
      backgroundColor: Colors.secondary.alternative,
  },

  ColorOther: {
      backgroundColor: Colors.white.absolute,
  }
};

export default styles;