import React from 'react';
import './styles.css';

const QuestionImage = ({ image, style }) => (
    <div className="QuestionImageContainer" style={style}>
        <img
          className="QuestionImage"
          src={image}
          alt="QuestionImage"
        />
    </div>
);

export default QuestionImage;
