import {
  RESET_REDUCERS,
  SET_DIRECT_LINK
} from './types';

export const resetPersist = () => {
  return {
      type: RESET_REDUCERS
  };
};

export const setDirectLink = (path) => {
  return {
      type: SET_DIRECT_LINK,
      payload: path
  };
};
