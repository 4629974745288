import {
  REPORT_USER_CHANGED,
  REPORT_EDITING_CHANGED,
  REPORT_TEXT_CHANGED,
  REPORT_ABUSING_CONTENT_CHANGED,
  REPORT_INAPPROPRIATE_CHAT_CHANGED,
  REPORT_SUBMIT,
  REPORT_SUBMIT_SUCCESS,
  REPORT_SUBMIT_FAIL,
} from './types';
import { Api } from '../config/api';
import { post } from '../helpers/api';

export const reportUserChanged = (userId) => {
  return {
    type: REPORT_USER_CHANGED,
    payload: userId
  };
};

export const reportEditingChanged = (started) => {
  return {
    type: REPORT_EDITING_CHANGED,
    payload: started
  };
};

export const reportTextChanged = (text) => {
  return {
    type: REPORT_TEXT_CHANGED,
    payload: text
  };
};

export const abusiveContentFlagChanged = (newValue) => {
  return {
    type: REPORT_ABUSING_CONTENT_CHANGED,
    payload: newValue
  };
};

export const inappropriateFlagChanged = (newValue) => {
  return {
    type: REPORT_INAPPROPRIATE_CHAT_CHANGED,
    payload: newValue
  };
};

export const submitReport = (token, data) => {
  return dispatch => {
    dispatch({
      type: REPORT_SUBMIT,
      payload: 'Submitting your feedback...',
    });

    post(token, Api.requests.report, data)
    .then(response => {
      dispatch({
        type: REPORT_SUBMIT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ 
        type: REPORT_SUBMIT_FAIL,
        payload: 'Something went wrong'
      });
    });
  };
};
