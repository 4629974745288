import React, { Component } from 'react';

import './styles.css';
import Match from '../Match';
import ButtonPurple from '../ButtonPurple';

export default class MatchesList extends Component {
  renderMatch = (item) => {
    const { onVoicePlayStopPress, playbackSourceUrl } = this.props;
    let { playing } = this.props;
    return (
      <Match
        name={item.name}
        age={item.age}
        profilePicture={item.photo}
        voice={item.voice}
        playing={playing && item.voice === playbackSourceUrl}
        onVoicePlayStopPress={() => {
          onVoicePlayStopPress(item.voice);
        }}
        onPress={() => {
            this.props.matchPressed(item.id);
        }}
        key={`row-${item.id}`}
      />
    );
  }

  render() {    
    if (this.props.matches.length !== 0) {
      return (
        <div className="MatchesListList">
        <p className="MatchesText">{`We found ${this.props.matches.length} new matches for you!`}</p>
          <div className="MatchesListContentContainer">
            {this.props.matches.map(item => this.renderMatch(item))}
          </div>
        </div>
      );
    }

    return (
      <div className="MatchesListButtonContainer">
        <ButtonPurple
          label={'Reload matches'}
          onPress={this.props.reloadMatchesPressed}
          large
        />
      </div>
    );
  }
}

