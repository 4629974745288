import React from 'react';
import './styles.css';

const TitleBar = ({ title, smallLeftMargin, children }) => (
  <div className={`TitleBar ${smallLeftMargin ? 'TitleBarSmallLeftMargin' : ''}`}>
    <p className="TitleBarText" >{title}</p>
    <div className="TitleBarLine" />
    {children}
  </div>
);

export default TitleBar;
