import React from 'react';

import './styles.css';
import { Images } from '../../config/images';

const ButtonPlay = ({ playing, onPress, alternative }) => {
    const icon = playing ? Images.icon.stopWhite : Images.icon.playWhite;
    return (
      <button className="ButtonPlayButton" onClick={onPress}>
        <div className={`ButtonPlayContainer ${alternative ? 'ButtonPlayContainerAlternative' : ''}`}>
            <img src={icon} className="ButtonPlayIcon" alt="icon"/>
        </div>
      </button>
    );
};

export default ButtonPlay;
