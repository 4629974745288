import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from '../pages/Login';
import LostPassword from '../pages/LostPassword';
import SignUp from '../pages/SignUp';
import AdmissionStart from '../pages/AdmissionStart';
import AdmissionQuestion from '../pages/AdmissionQuestion';
import AdmissionResult from '../pages/AdmissionResult';
import About from '../pages/About';
import Matches from '../pages/Matches';
import MatcheProfile from '../pages/MatchProfile';
import Profile from '../pages/Profile';
import ProfileEdit from '../pages/ProfileEdit';
import Chat from '../pages/Chat';
import ChatHistory from '../pages/ChatHistory';
import Settings from '../pages/Settings';
import Invitations from '../pages/Invitations';
import Subsription from '../pages/Subscription';

import { AuthPaths, AdmissionPaths, MainPaths } from '../config/paths';

export const AuthRoutes = () => (
  <Switch>
    <Route exact path={AuthPaths.login} component={Login} />
    <Route exact path={AuthPaths.lostPassword} component={LostPassword} />
    <Route exact path={AuthPaths.signUp} component={SignUp} />
  </Switch>
);

export const AdmissionRoutes = () => (
  <Switch>
    <Route exact path={AdmissionPaths.admissionStart} component={AdmissionStart} />
    <Route exact path={AdmissionPaths.admissionAbout} component={About} />
    <Route exact path={AdmissionPaths.admissionQuestion} component={AdmissionQuestion} />
    <Route exact path={AdmissionPaths.admissionResult} component={AdmissionResult} />
  </Switch>
);

export const TabRoutes = () => (
  <Switch>

    {/* match tab */}
    <Route exact path={MainPaths.matches} component={Matches} />

    {/* profile tab */}
    <Route exact path={MainPaths.profile} component={Profile} />
    <Route exact path={MainPaths.profileEdit} component={ProfileEdit} />    

    {/* chat tab */}
    <Route exact path={MainPaths.chat} component={Chat} />
    <Route exact path={MainPaths.chatHistory} component={ChatHistory} />

    {/* Invitations tab */}
    <Route exact path={MainPaths.invitations} component={Invitations} />

    {/* Settings tab */}
    <Route exact path={MainPaths.settings} component={Settings} />
    
    {/* Subscription tab */}
    <Route exact path={MainPaths.subscription} component={Subsription} />

    {/* Match profile */}
    <Route exact path={MainPaths.matchProfile} component={MatcheProfile} />
  </Switch>
);