import axios from 'axios';

export const post = async (token, url, body = {}) => {
    return axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });
};

export const get = async (token, url) => {
    return axios.get(url,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }
    );
};

export const guestPost = async (url, body = {}) => {
    return axios.post(url, body,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        }
    );
};

export const deletePost = async (token, url) => {
    return axios.delete(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};
