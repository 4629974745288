import {
  INVITATIONS_FETCH,
  INVITATIONS_FETCH_SUCCESS,
  INVITATIONS_FETCH_FAIL,
  NEW_INVITATION,
  INVITATIONS_TAB_CHANGED,
  CLOSE_INVITATION
} from './types';

import { Api } from '../config/api';
import { get } from '../helpers/api';

export const fetchInvitations = (token) => {
  return dispatch => {
    dispatch({ type: INVITATIONS_FETCH });

    get(token, Api.requests.invitations)
    .then(response => {          
      dispatch({
        type: INVITATIONS_FETCH_SUCCESS,
        payload: response.data,
      });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: INVITATIONS_FETCH_FAIL });
    });
  };
};

export const newInvitation = (invitation) => {
  return {
    type: NEW_INVITATION,
    payload: invitation,
  };
};

export const closeInvitation = () => {
  return {
    type: CLOSE_INVITATION
  }
}

export const changeInvitationTab = (tabIndex) => {
  return {
    type: INVITATIONS_TAB_CHANGED,
    payload: tabIndex
  }
}
