import {
  RESET_REDUCERS,
  AUDIO_STATUS_CHANGED,
  AUDIO_PLAYBACK_OBJECT_CHANGED,
  AUDIO_RECORDING_OBJECT_CHANGED,
} from '../actions/types';

const INITIAL_STATE = {
  status: {
    loading: false,
    playing: false,
    recording: false,
  },
  playbackSourceUrl: '',
  playbackSoundObject: null,
  recordingSoundObject: null,
};

export default (state = INITIAL_STATE, action) => {  
  switch (action.type) {
    case RESET_REDUCERS:
      return {
          ...INITIAL_STATE,
      };

    case AUDIO_STATUS_CHANGED: {
      const { status } = state;
      const newStatus = { ...status, ...action.payload };
      return {
        ...state,
        status: newStatus
      };
    }

    case AUDIO_PLAYBACK_OBJECT_CHANGED: {
      const status = { ...state.status, playing: false, loading: false };
      const { playbackSoundObject, playbackSourceUrl } = action.payload;
      return {
        ...state,
        status,
        playbackSourceUrl,
        playbackSoundObject,
      };
    }

  case AUDIO_RECORDING_OBJECT_CHANGED: {
    const status = { ...state.status, recording: false };
    return {
      ...state,
      status,
      recordingSoundObject: action.payload,
    }
  }

  default:
      return state;
  }
};
