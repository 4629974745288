import React from 'react';

import './styles.css';
import { Images } from '../../config/images';

const ProfileInput = (
    {
        value, onChangeText, placeholder, secureTextEntry, required, onDelete = undefined
    }) => (
    <div className="ProfileInputContainer">
        <div className="ProfileInputInnerContainer">
            <input
                type={secureTextEntry ? 'password' : 'text'}
                className="ProfileInputTextInput"
                value={value ? value: ''}
                onChange={(event) => onChangeText(event.target.value)}
                placeholder={placeholder}
            />
            { 
                onDelete !== undefined ? (
                    <button onClick={onDelete} className="ProfileInputDeleteButton">
                        <img src={Images.icon.trashBinPurple} className="ProfileInputDeleteIcon" alt="Delete" />
                    </button>
                ) : (
                    null
                )
            }
        </div>
        {required ? <p className="ProfileInputRequired">*</p> : null}
    </div>
);

export default ProfileInput;
