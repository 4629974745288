import React from 'react';
import './styles.css';

const Spinner = ({ status }) => (
  <div className="SpinnerContainer">
    <div className="Spinner">
      <p className="SpinnerText">Loading...</p>
      <p className="SpinnerText">{status}</p>
    </div>
  </div>
);

export default Spinner;
